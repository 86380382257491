import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Drawer, Button, Tag } from 'antd';
import { LeftOutlined, ArrowLeftOutlined, SendOutlined } from '@ant-design/icons';
import { useRecoilValue, useRecoilState } from 'recoil';
import { userState, messagesState, channelsState, connectsState } from 'global/states';
import Api from 'global/api';
import moment from 'moment';
import _ from 'lodash';
import { message } from 'vfile-message';


const BookingConnects = (props) => {
  // console.log('BookingConnects::props', props)
  const { id, bookings, properties, selectUser, connects: allConnects } = props;
  const user = useRecoilValue(userState);
  const booking = bookings.find( item => item.id === id );
  const property = properties.find( item => item.id === booking.propertyId );
  const connects = allConnects.filter( item => item.propertyId === property.id );
  let filteredConnects = [];
  if ( user ) {
    filteredConnects = connects.filter( item => {
      const bookingCheckIn = moment(booking.dates.checkIn);
      const bookingCheckOut = moment(booking.dates.checkOut);

      const currentCheckIn = moment(item.checkIn);
      const currentCheckOut = moment(item.checkOut);
      const isCheckInBetweenDatesSelected = bookingCheckIn.isBetween(currentCheckIn, currentCheckOut);
      const isCheckOutBetweenDatesSelected = bookingCheckOut.isBetween(currentCheckIn, currentCheckOut);
      const isCheckInBetweenDatesConnect = currentCheckIn.isBetween(bookingCheckIn, bookingCheckOut);
      const isCheckOutBetweenDatesConnect = currentCheckOut.isBetween(bookingCheckIn, bookingCheckOut);

      if ( isCheckInBetweenDatesSelected || isCheckOutBetweenDatesSelected || isCheckInBetweenDatesConnect || isCheckOutBetweenDatesConnect ) {
        return true;
      }
    });
  }

  console.group();
  console.log('booking', booking);
  console.log('connects', connects);
  console.log('filteredConnects', filteredConnects);
  console.groupEnd();

  return (
    <ConnectsContainer>

      <div className="booking-message">Viewing all Connects for <strong>{ property.name }</strong></div>

      { filteredConnects && filteredConnects.map( user => {
        return (
          <UserContainer key={user.userId} onClick={ () => { selectUser(user) } }>
            <div><Image src={ user.avatar ? `/avatars/${user.avatar}.png` : `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`} /></div>
            <div className="user-data">
              <h3>{ user.firstName } { user.lastName }</h3>
              <p>{ user.country }</p>
            </div>
            {/* <div className="last-seen">{ user.last_seen || 'Never' }</div> */}
          </UserContainer>
        )
      } ) }

      { filteredConnects && filteredConnects.length === 0 &&
        <EmptyList>
          <p>No connects found for this property having intersecting check-in and check-out dates as yours.</p>
        </EmptyList>
      }

    </ConnectsContainer>
  )
}

const EmptyList = styled.div`
  padding: 20px;
  background: #F2AF1F;
  border-radius: 5px;
  border: 1px solid #d8980e;
  p {
    font-size: 16px;
  }
`;

// chat messages component/ui
export const ChatUI = (props) => {
  const connects = useRecoilValue(connectsState);
  const { user, messages } = props;
  const filteredMessages = _.sortBy(messages, [ 'timestamp.seconds' ])

  const ChatContainerLeft = (props) => {
    const { message } = props;
    return (
      <ChatContainer className="author">
        <div className="avatar"><img src={ user.avatar ? `/avatars/${user.avatar}.png` : `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`} /></div>
        <div>
          <div className="message">{ message.message }</div>
          <div className="timestamp">{ moment( new Date(message.timestamp.seconds * 1000) ).fromNow() }</div>
        </div>
      </ChatContainer>
    )
  }
  const ChatContainerRight = (props) => {
    const { message } = props;
    const fromUser = connects.find( usr => usr.userId === message.from );
    return (
      <ChatContainer className="to_user">
        <div>
          <div className="message">{ message.message }</div>
          <div className="timestamp">{ moment( new Date(message.timestamp.seconds * 1000) ).fromNow() }</div>
        </div>
        { fromUser && <div className="avatar"><img src={ fromUser.avatar ? `/avatars/${fromUser.avatar}.png` : `https://ui-avatars.com/api/?name=${fromUser.firstName}+${fromUser.lastName}`} /></div> }
      </ChatContainer>
    )
  }


  return (
    <ChatMessages>
      { filteredMessages && filteredMessages.map( (message, index) => {
        if ( message.from === user.uid ) {
          return <ChatContainerLeft key={message.id} message={message} />
        } else {
          return <ChatContainerRight key={message.id} message={message} />
        }
      } ) }
    </ChatMessages>
  )
}



const ChatMessages = styled.div`

`;

const ChatContainer = styled.div`
  display: flex;
  margin-bottom: 30px;



  .avatar {
    img {
      display: block;
      border-radius: 50%;
      max-height: 32px;
    }

  }


  .message {
    border: 1px solid #e8e8e8;
    padding: 10px 20px;
    margin-left: 10px;
    max-width: 300px;
    border-radius: 0 20px 20px 20px;
  }

  .timestamp {
    margin-left: auto;
    font-size: 12px;
    color: #999;
    align-self: center;
  }


  &.author {
    // .message {
    //   margin-left: 0;
    //   background: #F1F5F8;
    //   border-color: transparent;
    //   border-radius: 20px 20px 0 20px;
    // }
    .timestamp {
      margin-top: 10px;
      margin-left: 10px;
    }
  }
  &.to_user {
    justify-content: flex-end;
    .message {
      margin-left: 10px;
      margin-right: 10px;
    }
    .timestamp {
      margin-top: 6px;
      text-align: end;
      margin-right: 10px;
    }
  }

`;



export const MessageInput = (props) => {
  const [ message, setMessage ] = useState('');
  const { sendMessage } = props;

  return (
    <MessageInputContainer>

        <input
          placeholder="Type a message here"
          value={message}
          onChange={ e => setMessage(e.target.value) }
          onKeyDown={ (e) => {
            if (e.key === 'Enter') {
              sendMessage(message);
              setMessage('');
            }
          }}
        />

       <Button
         className="btn-orange"
         type="primary"
         shape="circle"
         onClick={ () => { sendMessage(message); setMessage(''); } }
         icon={<SendOutlined />}
         size="default"
       />
    </MessageInputContainer>
  )
}

const MessageInputContainer = styled.div`
  background: #F1F5F8;
  padding: 10px;
  border-radius: 25px;
  display: flex;

  input {
    border: none;
    width: 100%;
    font-size: 15px;
    line-height: 100%;
    background: transparent;
    margin: 0 10px;
    outline: none;
  }

`;











const ConnectsUI = (props) => {
  const { visible, hideDrawer, connects } = props;
  const [ selected, setSelected ] = useState(null);
  const [ selectedBooking, setSelectedBooking ] = useState(null);
  const messages = useRecoilValue(messagesState);
  const user = useRecoilValue(userState);
  const channels = useRecoilValue(channelsState);
  const selectedUSer = selected ? connects.find( u => u.userId === selected ) : null;

  // let's find the current channel user is communicating on
  // let channel = selectedUSer ? channels.find( item => (item.fromBookingId === props.id || item.toBookingId === props.id) && ( selectedUSer.bookingId === item.fromBookingId || selectedUSer.bookingId === item.toBookingId ) ) : null;
  let channel = selectedUSer ? channels.find( item => ( selectedBooking === item.fromBookingId || selectedBooking === item.toBookingId ) ) : null;

  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    return messagesEndRef.current && messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }


  const sendMessage = async (message, channelId=null) => {
    if ( ! message ) { return; }
    // const selectedUSer = connects.find( u => u.userId === selected );

    // console.group();
    // console.log('props', props);
    // console.log('channel', channel);
    // console.log('message', message);
    // console.log('channelId', channelId);
    // console.log('selectedUSer', selectedUSer);
    // console.groupEnd();

    // const channel = channels.find( item => { return item.bookingId == props.id && ( item.userId == user.uid || item.toUser == selected ) } );
    const res = await Api.sendMessage( message, user, {
      channelId: channel && channel.id || null,
      fromBookingId: props.id,
      toBookingId: selectedUSer.bookingId,
      to: selected,
    });
    // do something else here.
    scrollToBottom();
  }

  const selectUser = (user) => {
    // console.log('selectUser - user', user)
    setSelected(user.userId);
    setSelectedBooking(user.bookingId);
  }
  const allUsers = () => { setSelected(null); }

  if ( ! selected ) {
    return (
      <Drawer
        closable={ true }
        destroyOnClose={ true }
        maskClosable={ false }
        // width={500}
        className="property-details-drawer"
        title="Connect"
        placement="right"
        onClose={ hideDrawer }
        visible={ visible }
      >
        <BookingConnects {...props} selectUser={selectUser} allUsers={allUsers} />
      </Drawer>
    )
  } else {
    const { id, bookings, properties } = props;
    const booking = bookings.find( item => item.id === id );
    const property = properties.find( item => item.id === booking.propertyId );
    // const users = connects;
    const currUser = connects.find( u => u.userId === selected );

    const ChatHeader = () => (
      <Header>
        <div className="back"><ArrowLeftOutlined onClick={ allUsers } /></div>
        { currUser.avatar && <div className="avatar"><Image src={`/avatars/${currUser.avatar}.png`} /></div> }
        { ! currUser.avatar && <div className="avatar"><Image src={`https://ui-avatars.com/api/?name=${currUser.firstName}+${currUser.lastName}`} /></div> }
        <div className="details">
          <h3>{ currUser.firstName } { currUser.lastName }</h3>
          <p>{ currUser.country }</p>
        </div>
      </Header>
    )

    let filteredMessages = [];
    console.log('filteredMessages', filteredMessages)
    console.log('filteredMessages channel', channel)
    console.log('selectedUSer', selectedUSer)
    if ( channel && channel.id ) {
      filteredMessages = messages.filter( item => item.channelId === channel.id );
    } else { filteredMessages = []; }
    console.log('filteredMessages FINAL', filteredMessages)


    return (
      <Drawer
        closable={ true }
        destroyOnClose={ true }
        maskClosable={ false }
        className="property-details-drawer"
        title={<ChatHeader />}
        placement="right"
        onClose={ hideDrawer }
        visible={ visible }
        footer={<MessageInput sendMessage={sendMessage} />}
      >
        <InfoContainer>
          <div className="item">
            <div className="key">Gender:</div>
            <div className="value">{ currUser.gender }</div>
          </div>
          <div className="item">
            <div className="key">Interests:</div>
            <div className="value">{ currUser.interests && currUser.interests.map( (item, index) => <Tag key={index} color="#F2AF1F">{item}</Tag> ) }</div>
          </div>
          <div className="item">
            <div className="key">Languages:</div>
            <div className="value">{ currUser.language && currUser.language.map( (item, index) => <Tag key={index} color="#F2AF1F">{item}</Tag> ) }</div>
          </div>
        </InfoContainer>

        <ChatUI {...props} user={user} messages={filteredMessages} />
        <div ref={messagesEndRef} />
      </Drawer>
    )
  }









  // if ( ! selected ) {
  //   return <BookingConnects {...props} selectUser={selectUser} allUsers={allUsers} />
  // } else {
  //   return <ChatUI {...props} />
  // }

}






export default ConnectsUI;



// ChatUI styles

const Header = styled.div`
  display: flex;
  align-items: center;

  .back {
    cursor: pointer;
  }
  .avatar {
    margin-left: 30px;
  }
  .details {
    margin-left: 10px;
    h3 { font-weight: 700; font-size: 16px; line-height: 100%; }
    p { font-size: 14px; color: #999; }
  }

`;



const InfoContainer = styled.div`
  margin-left: 0;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 10px;
  margin-bottom: 20px;

  .item {
    display: flex;
    margin-bottom: 10px;

    .key {
      color: #999;
      font-size: 15px;
      width: 120px;
    }

    .value {
      flex: 1;
      font-size: 15px;
      text-transform: capitalize;
    }

  }
`;








// BookingConnects styles
const ConnectsContainer = styled.div`
  .booking-message {
    font-size; 14px;
    color: #999;
    margin-bottom: 10px;
    strong { font-weight: 700; }
  }
`;

const UserContainer = styled.div`
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 0;
  // margin-bottom: 10px;
  display: flex;
  justify-content: start;
  cursor: pointer;
  transition: all .3s ease;

  &:hover { background-color: #f3fcff; }

  .user-data {
    margin-left: 20px;
    align-self: center;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      // margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      color: #999;
    }
  }

  .last-seen {
    color: #ccc;
    font-size: 14px;
    margin-left: auto;
  }
`;

const Image = styled.img`
  display: block;
  border-radius: 50%;
  max-height: 48px;
`;







/*
const ConnectsUI = (props) => {
  const [ selected, setSelected ] = useState(null);

  const selectUser = (id) => { setSelected(id); }
  const allUsers = () => { setSelected(null); }

  if ( ! selected ) {
    return <BookingConnects {...props} selectUser={selectUser} allUsers={allUsers} />
  } else {
    return <ChatUI {...props} />
  }

}

*/
