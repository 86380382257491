import React, { useState, useEffect } from 'react';
import SidebarLayout from 'global/layouts/Sidebar';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Button, Alert, message } from 'antd';

import { useRecoilState, useRecoilValue } from 'recoil';
import { propertyFilterState, userState, bookingsState } from 'global/states';
import { format, differenceInDays, eachWeekendOfInterval, isWeekend } from 'date-fns';
import Api from 'global/api';

const TEST_KEY_ID = 'rzp_live_PnwruouaeczYrr';
const PRODUCTION_KEY_ID = 'rzp_live_PnwruouaeczYrr';


const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 2,
});


const BookingSummary = (props) => {
  const [loading, setLoading] = useState(false);
  const [propertyFilter, setPropertyFilter] = useRecoilState(propertyFilterState);
  const { property, selected, bookingId, dates, order } = props;
  const rooms = property.rooms;
  const user = useRecoilValue(userState);
  const history = useHistory();
  const [bookings, setBookings] = useRecoilState(bookingsState);

  console.log("user",user);
  console.log("order",order);


  const checkIfWeekend = () => {
    const totalDays = differenceInDays(new Date(dates.checkOut), new Date(dates.checkIn));
    if ( totalDays >= 7 ) { return false; }
    const isWknd = isWeekend( new Date(dates.checkIn) );
    return isWknd;
    // const weekendDates = eachWeekendOfInterval({
    //   start: new Date(dates.checkIn),
    //   end: new Date(dates.checkOut)
    // });
    // if ( weekendDates.length > 0 ) { return true; }
    // return false;
  }


  const totalNights = differenceInDays(new Date(dates.checkOut), new Date(dates.checkIn));

  let basePrice = 0;
  let serviceFees = 0;
  let gstAmount = 0;
  let convenienceFee = 0;


  let costPerDay = 0;
  console.log('selected', selected)
  Object.keys(selected).map( key => {
    const room = rooms.find( item => item.id == key );
    const beds = selected[key];
    console.log('beds', beds);
    costPerDay = costPerDay + parseInt(selected[key]) * parseInt( checkIfWeekend() ? room.prices.weekend_final : room.prices.weekday_final );

    if ( checkIfWeekend() ) {
      basePrice = basePrice + parseFloat(room.prices.weekend_price) * parseFloat(totalNights) * parseFloat(beds);
      serviceFees = serviceFees + parseFloat(room.price_weekend) * parseFloat(totalNights) * parseFloat(beds) * (15/100);
      convenienceFee = convenienceFee + parseFloat(room.prices.weekend_fees) * parseFloat(totalNights) * parseFloat(beds);
    } else {
      basePrice = basePrice + parseFloat(room.prices.weekday_price) * parseFloat(totalNights) * parseFloat(beds);
      serviceFees = serviceFees + parseFloat(room.price) * parseFloat(totalNights) * parseFloat(beds) * 0.15; // 15%
      convenienceFee = convenienceFee + parseFloat(room.prices.weekday_fees) * parseFloat(totalNights) * parseFloat(beds);
    }

  });
  const totalCost = parseInt(totalNights) * costPerDay;
  gstAmount = parseFloat(serviceFees) * 0.09; // 9%

  const { checkIn, checkOut } = dates;





  useEffect( () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  let description = ``;
  Object.keys(selected).map( (key) => {
    const room = rooms.find( item => item.id == key );
    description = `${room.title} - ${ selected[key] } Bed(s)\n`;
  });


  let RAZORPAY_KEY_ID = PRODUCTION_KEY_ID; //TEST_KEY_ID
  if ( property.id === "Z1Adxx5TSqR6OO" || property.id === "eDlFqPQietmubg" ) {
    RAZORPAY_KEY_ID = PRODUCTION_KEY_ID;
  }

  const options = {
    key: RAZORPAY_KEY_ID,
    amount: parseInt(totalCost) * 100, // in paisa
    currency: 'INR',
    name: `Booking for ${property.name}`,
    description: description,
    // order_id: order.receipt,
    order_id: order.id,

    handler: async (response) => {
      // payment was successful.
      message.success('Congratulations. Your payment was successful.');
      console.log("response",response);

      // const bookings = await Api.findAllRecords('bookings', {
      //   orderBy: { field: 'createdAt', order: 'desc' }
      // });
      // setBookings(bookings);

      history.push('/account/bookings');
    },
    prefill: {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      contact: '',
    },
    notes: {
      booking: bookingId,
      uid: user.uid,
      checkInDate: checkIn,
      checkOutDate: checkOut,
    },
    theme: {
      color: '#F2AF1F',
    },
  };


  const handlePaymentClick = async () => {
    // message.info('Functionality is not yet implemented!');
    console.log("options",options);
    Api.analytics.logEvent('booking_payment', { user: (Api.uid || null), data: JSON.stringify(options) });

    /*global Razorpay*/
    const rzp1 = new Razorpay(options);

    rzp1.on('payment.failed', (response) => {
      console.log("response", response)
      message.error(`Payment failed: ${response.error.reason}`);
    });

    rzp1.open();

    // const res = await fetch();
  }



  return (
    <ConnectContainer>
      <Title className="extra">Booking Summary:</Title>

      <Alert style={{marginTop: 10}}
        message={`Booking ${bookingId} for ${property.name}`}
        type="warning"
        closable
      />

      <Wrapper>
        <div>
          <div className="details">
            <Title>Accommodation Details: </Title>
            <Text>{ property.name }</Text>
          </div>
          <div className="details">
            <Title>Check-in Date: </Title>
            <Text>{ format( new Date(checkIn), 'do MMM, yyyy' ) }</Text>
          </div>
          <div className="details">
            <Title>Check-out Date: </Title>
            <Text>{ format( new Date(checkOut), 'do MMM, yyyy' ) }</Text>
          </div>
          <div className="details">
            <Title>Total Nights: </Title>
            <Text>{ totalNights } Night(s)</Text>
          </div>
          <div className="details">
            <Title>Rooms Selected: </Title>
            <div>
              { Object.keys(selected).map( (key) => {
                const room = rooms.find( item => item.id == key );
                return (
                  <Text key={key}>{ room.title } - {selected[key]} Bed(s)</Text>
                )
              }) }
            </div>
          </div>



          <div className="details">
            <Title>Base Price: </Title>
            <Text style={{ fontWeight: 600 }}>{ formatter.format(basePrice) }</Text>
          </div>
          <div className="details">
            <Title>Service Fees: </Title>
            <Text style={{ fontWeight: 600 }}>{ formatter.format(serviceFees) }</Text>
          </div>
          <div className="details">
            <Title>CGST (9%): </Title>
            <Text style={{ fontWeight: 600 }}>{ formatter.format(gstAmount) }</Text>
          </div>
          <div className="details">
            <Title>SGST (9%): </Title>
            <Text style={{ fontWeight: 600 }}>{ formatter.format(gstAmount) }</Text>
          </div>
          <div className="details">
            <Title>Convenience Fee: </Title>
            <Text style={{ fontWeight: 600 }}>{ formatter.format(convenienceFee) }</Text>
          </div>






          <div className="details">
            <Title>Total Cost: </Title>
            <Text style={{ fontWeight: 600 }}>{ formatter.format(totalCost) } ( { formatter.format(costPerDay) } * {totalNights} )</Text>
          </div>
        </div>

      </Wrapper>

      <Button type="primary" id="rzp-button" style={{ marginTop: 10, height: 40, background: '#f2af1f', borderColor: '#f2af1f' }} onClick={ handlePaymentClick } block>Proceed To Pay</Button>

    </ConnectContainer>

  )

}




export default BookingSummary;


const ConnectContainer = styled.div`
  max-width: 800px;
  margin: 0px auto;

  .icon{
    font-size: 20px;
  }

  .bookingid {
    border-radius: 5px;
    padding: 10px;
    background-color: #F2AF1F;
    margin-top: 15px;
  }
`;

const Title = styled.h3`
  /* margin-top: 15px; */
  // font-size: 20px;
  font-weight: 600;
  a {
    color: #333;
  }

  &.extra {
    font-weight: bold;
    font-size: 25px;
  }
`;
const Text = styled.div`
  /* margin-top: 20px; */
`;

const Wrapper = styled.div`
  border-radius: 5px;
  border: 0.1px solid #bfbdbd;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;

  .details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

`;

