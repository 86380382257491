import React from 'react';
import styled from 'styled-components';
import { Carousel, Rate } from 'antd';


const PropertyCarousel = (props) => {
  const { property } = props;

  return (
    <CarouselHeader>

      <Carousel dotPosition="right" autoplay>
        { property.images && property.images.map( (image, index) => {
          return (
            <div key={index}>
              <Image src={ image } />
            </div>
          )
        }) }
      </Carousel>

      <DetailsContainer>
        <PropertyDetails>
          <h2>{ property.name }</h2>
          <Rating disabled allowHalf defaultValue={ property.rating } />
        </PropertyDetails>
      </DetailsContainer>

    </CarouselHeader>
  )

}

export default PropertyCarousel;


const CarouselHeader = styled.div`
  position: relative;

  @media only screen and (max-width: 768px) {
    // margin-top: 49px;
    min-height: 360px;
    img { min-height: 360px !important; }
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  max-height: 600px;
  object-fit: cover;

`;

const DetailsContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  position: relative;
`;

const PropertyDetails = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;

  h2 {
    font-size: 32px;
    color: #fff;
    max-width: 500px;
    @media only screen and (max-width: 768px) {
      margin-left: 30px
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }

`;

const Rating = styled(Rate)`
  margin-top: 8px;
  font-size: 16px;
  color: #f2af1f;
  @media only screen and (max-width: 768px) {
    margin-left: 30px
  }
`;


