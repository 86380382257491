import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { userState, bookingsState, messagesState, connectsState, channelsState } from 'global/states';
import { Button, message, Menu, Dropdown, Avatar } from 'antd';
import { DownOutlined, UserOutlined, NotificationOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

import Api from 'global/api';
import VoyobeeLogo from 'assets/logo.png';
import _ from 'lodash';


// const notifications = [
//   'Sample 1',
//   'Sample 2',
//   'Sample 3',
// ];

// { notifications && notifications.map( item, index ) => <NotificationItem key={index}>{item}</NotificationItem> }


const Header = (props) => {
  const sidebar = props.sidebar;
  const history = useHistory();

  const [ user, setUser ] = useRecoilState(userState);
  const [ bookings, setBookings ] = useRecoilState(bookingsState);
  const [ messages, setMessages ] = useRecoilState(messagesState);
  const [ connects, setConnects ] = useRecoilState(connectsState);
  const [ channels, setChannels ] = useRecoilState(channelsState);

  useEffect( () => {
    return document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  })


  const handleUserLogout = () => {
    console.log('handleUserLogout')
    // logout the user
    Api.logout();

    // reset the entire loggedIn state
    // setUser({});
    setBookings([]);
    setMessages([]);
    setConnects([]);
    setChannels([]);

    history.push('/');
  }

  const userMenu = (
    <Menu style={{ marginTop: 10 }}>
      <Menu.Item key="profile"><NavLink to="/account/profile">Manage Profile</NavLink></Menu.Item>
      <Menu.Item key="bookings"><NavLink to="/account/bookings">My Bookings</NavLink></Menu.Item>
      <Menu.Item key="connects"><NavLink to="/account/connects">My Connects</NavLink></Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={ handleUserLogout }>Logout</Menu.Item>
    </Menu>
  );


  const userMessages = messages.filter( msg => msg.to === user.id );
  const sortedUserMessages = _.sortBy(userMessages, [ 'timestamp.seconds' ]);

  let last5Messages = sortedUserMessages;
  if ( sortedUserMessages.length > 5 ) {
    last5Messages = sortedUserMessages.slice(Math.max(sortedUserMessages.length - 5, 0))
  }
  const filteredUserMessages = _.orderBy(last5Messages, [ 'timestamp.seconds' ], ['desc'])
  const notification = (
    <UserNotifications>
      { filteredUserMessages && filteredUserMessages.length > 0 && filteredUserMessages.map( msg => {
        return (<NotificationItem key={msg.id}>{ msg.message }</NotificationItem>)
      }) }
    </UserNotifications>
  )



  return (
    <HeaderWrapper className={ sidebar ? 'has-sidebar' : '' }>
      <HeaderInner>
        <div className="app-name">
          <Link to="/"><Logo src={VoyobeeLogo} title="Voyobee Logo" /></Link>
        </div>

        { ! user &&
        <nav className="links-right">
          <Link to="/auth/login"><Button>Login</Button></Link>
          <Link to="/auth/register"><Button type="primary" className="btn-orange">Register</Button></Link>
        </nav>
        }

        { user &&
          <nav className="links-right">
            { user.avatar &&
            <Dropdown overlay={ userMenu } trigger={['click']} placement="bottomRight">
              <Link to="/" className="ant-dropdown-link">
                <Avatar src={<img src={`/avatars/${user.avatar}.png`} />} style={{ backgroundColor: 'rgb(242 175 31)' }} size={36} /><span className="full-name" style={{ marginLeft: 10, color: '#f2af1f' }}>{ `${user.firstName} ${user.lastName}` || 'John Doe' }</span> <DownOutlined style={{ color: '#f2af1f' }} />
              </Link>
            </Dropdown>
            }

            { ! user.avatar &&
            <Dropdown overlay={ userMenu } trigger={['click']} placement="bottomRight">
              <Link to="/" className="ant-dropdown-link">
                <Avatar style={{ backgroundColor: 'rgb(242 175 31)' }} size={36} icon={<UserOutlined />} /><span className="full-name" style={{ marginLeft: 10, color: '#f2af1f' }}>{ `${user.firstName} ${user.lastName}` || 'John Doe' }</span> <DownOutlined style={{ color: '#f2af1f' }} />
              </Link>
            </Dropdown>
            }

            <div style={{ marginLeft: 10, alignItems: 'center', display: 'flex' }}>
              <Dropdown overlay={notification} trigger={['click']}>
                <NotificationOutlined style={{ color: '#f2af1f' }} />
              </Dropdown>
            </div>


          </nav>
        }


      </HeaderInner>
    </HeaderWrapper>
  )

}


export default Header;


const HeaderWrapper = styled.div`
  position: fixed;
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  padding: 0 10px;
  z-index: 1000;
  transition: all .3s ease;
  background: #FFF;

  &:after {
    content: "";
    background: radial-gradient(farthest-side at 50% 0%, rgba(36, 18, 77, 0.3), rgba(36, 18, 77, 0.05)) 0px 0px;
    height: 9px;
    left: 0px;
    position: absolute;
    right: 0px;
    transition: opacity 250ms cubic-bezier(0.2, 0.45, 0, 1) 0s;
    width: 100%;
  }

  .app-name {
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    padding: 13px 0;
    overflow: hidden;
    flex: 1 1 0%;
    a {
      color: #FFF;
      text-decoration: none;
      display: inline-block;
    }
    &.active { color: #FFF;  }
  }

  nav {
    align-self: center;
    // padding: 0 15px;
    flex: 1;
    display: flex;

    &.fl-links-left {
      justify-content: flex-start;
    }
    &.links-right {
      justify-content: flex-end;
      overflow: hidden;
      a {
        margin-left: 10px;
        &:first-child { margin-left: 0; }
      }

      @media only screen and (max-width : 480px) {
        .ant-avatar {
          width: 32px !important;
          height: 32px !important;
        }
        a.ant-dropdown-link {
          justify-content: center;
          align-items: center;
          display: flex;
        }
        .full-name {
          display: inline-block;
          max-width: 60px;
          overflow: hidden;
          margin-right: 10px;
        }
      }
    }
  }

  &.has-sidebar {
    margin-left: 260px;;
  }




`;

const Logo = styled.img`
  display: block;
  max-width: 160px;

  @media only screen and (max-width : 480px) {
    width: 130px;
  }
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  max-width: 1200px;

  @media only screen and (max-width : 480px) {
    height: 70px;
  }

`;



const UserNotifications = styled.div`
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 25px;
`;

const NotificationItem = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
`;








