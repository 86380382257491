import {
  atom,
  selector,
} from 'recoil';
import Api from 'global/api';




// user state
export const userState = atom({
  key: 'user',
  default: null,
});


// proeprties state
const propertiesQuery = selector({
  key: 'propertiesQuery',
  get: async () => {
    const groups = await Api.findAllRecords('properties', {
      max: 100,
      orderBy: { field: 'rating', order: 'desc' }
    });
    return groups;
  },
});
export const propertiesState = atom({
  key: 'properties',
  default: propertiesQuery,
});



export const propertyFilterState = atom({
  key: 'propertyFilter',
  default: {
    query: {},
    properties: [],
  },
});



// bookings state
const bookingsQuery = selector({
  key: 'bookingsQuery',
  get: async () => {
    const bookings = await Api.findAllRecords('bookings', {
      max: 50,
      orderBy: { field: 'createdAt', order: 'desc' },
      currentUserOnly: true,
    });
    return bookings;
  },
});
export const bookingsState = atom({
  key: 'bookings',
  default: [],
  // default: bookingsQuery,
});




// connects state
const connectsQuery = selector({
  key: 'connectsQuery',
  get: async () => {
    const connects = await Api.findAllConnects();
    return connects;
  },
});
export const connectsState = atom({
  key: 'connects',
  default: [],
  // default: connectsQuery,
});




export const channelsState = atom({
  key: 'channels',
  default: [],
});
export const messagesState = atom({
  key: 'messages',
  default: [],
});


export const currentChannelState = atom({
  key: 'current_channel',
  default: undefined,
});


/*

// channels state
const channelsQuery = selector({
  key: 'channelsQuery',
  get: async () => {
    const channels = await Api.findAllChannels();
    return channels;
  },
});
export const channelsState = atom({
  key: 'channels',
  default: channelsQuery,
});



// messages state
const messagesQuery = selector({
  key: 'messagesQuery',
  get: async () => {
    const messages = await Api.findAllMessages();
    return messages;
  },
});
export const messagesState = atom({
  key: 'messages',
  default: connectsQuery,
});

*/
