import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const CityCard = (props) => {
  const { data } = props;

  return (
    <Wrapper>
      <Link to={`/properties/cities/${ data.url }`}>
        <Image src={ data.image } />
      </Link>
      <Title><Link to={`/properties/cities/${ data.url }`}>{ data.title }</Link></Title>
    </Wrapper>
  )

}


export default CityCard;


const Title = styled.h3`
  margin-top: 15px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  a {
    color: #333;
  }
`;

const Wrapper = styled.div`
  border-radius: 4px;
  background: #ffffff;
  padding: 20px;
  border: 1px solid #CCC;
  // box-shadow: 12px -10px 0px rgba(138,107,118,0.08);
  // box-shadow: 18px 20px 12px rgb(142 142 142 / 25%)
`;


const Text = styled.div`
`;

const Image = styled.img`
  // display: block;
  // border-radius: 10px;
  // width: 100%;
  // height: 200px;

  display: block;
  border-radius: 4px;
  width: 100%;
  height: 180px;
  // border-radius: 50%;
  object-fit: cover;
`;

const ImgIcon = styled.img`
  width: 120px;
  margin-top: 10px;
  margin-left: 20px;
`;


