import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import 'global/styles/style.css';


import HomePage from 'pages/common/Home';
import AuthLogin from 'pages/auth/Login';
import AuthRegister from 'pages/auth/Register';
import AuthForgotPassword from 'pages/auth/ForgotPassword';

import PropertyDetailsPage from 'pages/property/Details';
import PropertySearchPage from 'pages/property/Search';
import PropertyCityPage from 'pages/property/City';
import PropertyConnectPage from 'pages/property/Connect';
import PropertyBookingSummaryPage from 'pages/property/BookingSummary';
import PropertyRoomSelectionPage from 'pages/property/RoomSelection';

import UserBookings from 'pages/authenticated/bookings/All';
// import BookingConnects from 'pages/authenticated/bookings/Connects';
import UserProfile from 'pages/authenticated/account/Profile';
import UserConnects from 'pages/authenticated/account/Connects';

import CancellationPolicy from 'pages/common/CancellationPolicy';
import TermsAndConditions from 'pages/common/TermsAndConditions';
import PrivacyPolicy from 'pages/common/PrivacyPolicy';
import AboutUs from 'pages/common/AboutUs';
import ContactUs from 'pages/common/ContactUs';


import RealtimeCommunication from 'global/components/RealtimeCommunication';
import AnalyticsEvent from 'global/components/AnalyticsEvent';


import { useSetRecoilState } from 'recoil';
import { userState } from 'global/states';
import Api from 'global/api';

import NotFound from 'pages/common/NotFound';

// utility components
import ScrollToTop from 'global/components/ScrollToTop';


const App = (props) => {
  const setUserState = useSetRecoilState(userState);

  useEffect( () => {
    const unsubscribe = Api.getCurrentUser(setUserState);

    return function cleanup() { unsubscribe(); };
  }, []);


  return (
    <RealtimeCommunication>
    <Router>
      <ScrollToTop />
      <AnalyticsEvent />
      <Switch>
        <Route exact path="/" component={ HomePage } />
        <Route path="/auth/login" component={ AuthLogin } />
        <Route path="/auth/register" component={ AuthRegister } />
        <Route path="/auth/forgot-password" component={ AuthForgotPassword } />

        <Route path="/properties/search" component={ PropertySearchPage } />
        <Route path="/properties/connect" component={ PropertyConnectPage } />
        <Route path="/properties/BookingSummary" component={ PropertyBookingSummaryPage } />
        <Route path="/properties/RoomSelection" component={ PropertyRoomSelectionPage } />

        <Route path="/properties/cities/:city" component={ PropertyCityPage } />

        <Route path="/properties/:url" component={ PropertyDetailsPage } />

        <Route path="/account/bookings" component={ UserBookings } />
        <Route path="/account/profile" component={ UserProfile } />
        <Route path="/account/connects" component={ UserConnects } />


        <Route path="/cancellation-policy" component={ CancellationPolicy } />
        <Route path="/terms-and-conditions" component={ TermsAndConditions } />
        <Route path="/privacy-policy" component={ PrivacyPolicy } />
        <Route path="/about-us" component={ AboutUs } />
        <Route path="/contact-us" component={ ContactUs } />


        <Route component={ NotFound } />
      </Switch>
    </Router>
    </RealtimeCommunication>
  )
}

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
