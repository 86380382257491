import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Header from 'global/components/Header';
import Sidebar from 'global/components/Sidebar';

import { useRecoilValue } from 'recoil';
import { userState } from 'global/states';
import Footer from 'global/components/Footer';


const PageLayout = (props) => {
  const sidebar = false;

  return (
    <div>

      <Header sidebar={sidebar} />

      { props.extra && <div>{ props.extra }</div> }

      <ContentContainer className={ sidebar ? 'has-sidebar' : '' }>
        <ContentContainerInner>

          { props.heading && <Heading>{props.heading}</Heading> }
          <PageContent>{ props.children }</PageContent>

        </ContentContainerInner>
      </ContentContainer>

      <Footer sidebar={sidebar} />
    </div>
  )
}


export default PageLayout;




const PageContent = styled.div`
  padding: 0 20px;

  h2 {
    font-size: 20px;
    line-height: 100%;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  strong { font-weight: 600; }

  ul {
    list-style: square outside none;
    margin-bottom: 10px;
  }
  ol { list-style: decimal; }
  li {
    margin-left: 30px;
    margin-bottom: 5px;
  }

`;


const Heading = styled.h2`
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #CCC;
  margin-bottom: 40px;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
`;


const ContentContainer = styled.div`
  padding-top: 90px;
  min-height: calc(100vh - 246px);

  &.has-sidebar {
    margin-left: 260px;
    width: calc( 100% - 260px );
  }
`;

const ContentContainerInner = styled.div`
  max-width: 1000px;
  padding: 50px;
  margin: 0 auto;

  @media (max-width: 480px) {
    padding: 20px;
  }


`;



