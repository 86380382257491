import React, { useState } from 'react';
import styled from 'styled-components';
import PageLayout from 'global/layouts/Page';
import { PushpinOutlined, PhoneOutlined, MailOutlined, HourglassOutlined } from '@ant-design/icons';
import GoogleMap from 'global/components/Utils/GoogleMap';


const ContactUs = (props) => {

  const geo = {
    u_: 12.998736392644572,
    h_: 77.57324254199474,
  }
  const company = {
    name: 'Voyobee'
  }


  return (
    <PageLayout>
      <ContentContainer>

        <h2>Contact Us</h2>

        <p style={{ margin: 0 }}>If you have any queries, do reach out.</p>
        <p style={{ margin: 0 }}>Please fill up the form with your details and send us a message to partner with us</p>

        <div className="flex">

          <div className="info">
            <div className="card">
              <div className="icon"><PushpinOutlined /></div>
              <h4>ADDRESS</h4>
              <p>
                143/1, 1st Floor <br />
                Coconut Ave Rd <br />
                Malleswaram, Bengaluru <br />
                Karnataka 560003
              </p>
            </div>
            <div className="card">
              <div className="icon"><PhoneOutlined /></div>
              <h4>CALL US</h4>
              <p>+91 7259108985</p>
            </div>
            <div className="card">
              <div className="icon"><MailOutlined /></div>
              <h4>EMAIL US</h4>
              <p>support@voyobee.com</p>
            </div>
            <div className="card">
              <div className="icon"><HourglassOutlined /></div>
              <h4>WORKING HOURS</h4>
              <p>Mon - Fri: 9AM to 5PM</p>
            </div>
          </div>

          <div className="map">
            <GoogleMap geo={geo} property={company} />
            <a target="_blank" href="https://www.voyobee.com/#contact">Click Here To Get In Touch With Us</a>
          </div>

        </div>

      </ContentContainer>
    </PageLayout>
  )

}


export default ContactUs;


const ContentContainer = styled.div`
  h2 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #F2AF1F;
    font-family: "Poppins", sans-serif;
  }

  h3 {
    font-size: 32px;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  p {
    font-family: "Open Sans", sans-serif;
    color: #585a61;
    margin: 20px 0;
    word-break: break-all;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .map {
    flex: 1.2;
    a {
      margin-left: 30px;
      margin-top: 15px;
      display: block;
    }
  }
  .info {
    flex: 1.8;
    display: flex;
    flex-wrap: wrap;

    .card {
      border: 1px solid #f1f3f6;
      flex: 50%;
      text-align: center;
      background: #fff;
      padding: 20px 40px;

      .icon {
        color: #9fb2d8;
        font-size: 48px;
        margin-bottom: 15px;
      }

      h4 {
        padding: 0;
        margin: 0 0 10px 0;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: "Poppins", sans-serif;
      }

      p {
        font-size: 15px;
        color: #47536e;
        text-align: center;
      }

    }
  }

  @media (max-width: 480px) {
    .flex {
      flex-direction: column;
    }

    .info {
      flex-direction: column;
    }
  }




`;


