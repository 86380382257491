import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Api from 'global/api';

const AnalyticsEvent = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log('path changed to : ', pathname)
    Api.analytics.logEvent('page_load', { url: pathname, user: (Api.uid || null) });
  }, [pathname]);

  return null;
}

export default AnalyticsEvent;
