import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Api from 'global/api';
import { useRecoilState } from 'recoil';
import { userState, bookingsState, messagesState, connectsState, channelsState } from 'global/states';
import { useHistory } from "react-router-dom";

import InstagramIcon from 'assets/sm/instagram.png';
import FacebookIcon from 'assets/sm/facebook.png';
import TwitterIcon from 'assets/sm/twitter.png';


const Footer = (props) => {
  const history = useHistory();
  const [ user, setUser ] = useRecoilState(userState);
  const [ bookings, setBookings ] = useRecoilState(bookingsState);
  const [ messages, setMessages ] = useRecoilState(messagesState);
  const [ connects, setConnects ] = useRecoilState(connectsState);
  const [ channels, setChannels ] = useRecoilState(channelsState);


  const handleUserLogout = () => {
    console.log('handleUserLogout')
    // logout the user
    Api.logout();

    // reset the entire loggedIn state
    // setUser({});
    setBookings([]);
    setMessages([]);
    setConnects([]);
    setChannels([]);

    history.push('/');
  }


  return (
    <FotoerWrapper className={ props.sidebar ? 'has-sidebar' : '' }>
      <FooterInner>

        <FooterGrid>
          <div>
            <nav>
              <Link to="/about-us">About Us</Link>
              <Link to="/contact-us">Contact Us</Link>

              { user &&
              <>
                <Link to="/account/profile">Manage Profile</Link>
                <Link to="/account/bookings">My Bookings</Link>
                <Link to="/account/connects">My Connects</Link>
                <a onClick={ handleUserLogout }>Logout</a>
              </>
              }
              { ! user &&
              <>
                <Link to="/auth/login">Login</Link>
                <Link to="/auth/register">Register</Link>
              </>
              }
            </nav>
          </div>

          <div className="social-media-icons">
            <p>Follow Us</p>
            <div className="social-nav">
              <a href="https://www.instagram.com/voyobee/"><img src={InstagramIcon} alt="Instagram"/></a>
              <a href="https://www.facebook.com/voyobee"><img src={FacebookIcon} alt="Facebook"/></a>
              <a href="https://twitter.com/voyobee"><img src={TwitterIcon} alt="Twitter"/></a>
            </div>
          </div>

          <div>
            <nav>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
              <Link to="/cancellation-policy">Cancellation Policy</Link>
            </nav>
          </div>

        </FooterGrid>

        <p>Copyright 2021. Voyobee Pvt Ltd. All Rights Reserved.</p>

      </FooterInner>
    </FotoerWrapper>
  )
}


export default Footer;


const FotoerWrapper = styled.footer`
  margin-top: 30px;
  background: #f2af1f;
  padding: 40px 0;

  &.has-sidebar {
    margin-left: 260px;
    width: calc( 100% - 260px );
  }


`;

const FooterInner = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 50px;

  p { margin-top: 30px; text-align: center; }
`;

const FooterGrid = styled.div`
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 30px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }


  .social-media-icons {
    // margin-top: 30px;
  }
  .social-nav {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    a {
      margin-left: 10px;
      &:first-child { margin-left: 0; }
    }
    img {
      display: block;
      width: 36px;
    }
  }


  p {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  nav {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    a {
      color: #463002;
      margin-bottom: 5px;
      &:first-child { margin-left: 0; }
      &:last-child { margin-bottom: 0; }
      &:hover { color: #000000; }


      @media only screen and (max-width: 768px) {
        margin-left: 0px;
      }
    }

  }
`;



