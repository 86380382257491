import React, { useState } from 'react';
import styled from 'styled-components';
import SidebarLayout from 'global/layouts/Sidebar';
import { InputNumber, Button, Form, DatePicker, Spin, Input, Select, Row, Col, message, Avatar, Modal } from 'antd';
import { useRecoilState } from 'recoil';
import { userState } from 'global/states';
import SuspenseLoading from 'global/components/Loading/SuspenseLoading';
import Api from 'global/api';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import countries from 'global/data/countries';
import interests from 'global/data/interests';
import languages from 'global/data/languages';

import { avatars } from 'global/data/avatar';
import { UserOutlined } from '@ant-design/icons';

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}




const DeleteUserContentContainer = (props) => {
  const { user } = props;
  const [ loading, setLoading ] = useState(false);
  const [ response, setResponse ] = useState({});
  console.log('useruseruseruser', user)

  if ( ! user ) {
    return <div>Invalid User, Please refresh the page and try again.</div>
  }

  const alreadyRequested = user?.delete?.id ? true : false;

  const handleSubmit = async (data) => {
    const deletionId = uuid();
    setLoading(true);
    const updateData = {
      delete: {
        id: deletionId,
        message: data.message,
      }
    }
    const res = await Api.updateRecord('users', user.id, updateData);
    setLoading(false);
    setResponse(res);
  }

  return (
    <CostomModalInputContainer>
      { alreadyRequested &&
        <div>
          <p>We have already received your account deletion request with reference number <strong>{ user.delete.id }</strong> and will contact you soon.</p>
        </div>
      }
      { !alreadyRequested && response?.delete?.id &&
        <div>
          <p>Thank you. We have received your account deletion request with reference number <strong>{ response.delete.id }</strong> and will contact you soon.</p>
        </div>
      }
      { !alreadyRequested && !response?.delete?.id &&
        <Spin tip="Processing..." spinning={ loading }>
        <p>Are you sure you want to delete your account?</p>
        <Form
          layout="vertical"
          onFinish={ handleSubmit }
        >
          <Form.Item
            label="Enter your message"
            name="message"
            rules={[{ required: true, message: 'Please enter your message here' }]}
          >
            <Input.TextArea placeholder="Enter your message" rows={2} />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" className="btn-orange" style={{ width: '100%' }}>Yes</Button>
          </Form.Item>
        </Form>
        </Spin>
      }
    </CostomModalInputContainer>
  )
}





const Profile = (props) => {
  const [ loading, setLoading ] = useState(false);
  const [ user, setUser ] = useRecoilState(userState);

  if ( ! user ) {
    return <SuspenseLoading message="Loading user details..." />
  }

  const handleSubmit = async (values) => {
    setLoading(true);

    let data = { ...values }
    data.dob = data.dob.toString();

    setUser({ ...user, ...data});

    const res = await Api.updateUserProfile(data);
    if ( ! res ) {
      setLoading(false);
      message.error('There was an issue updating the profile, Please try again after some time.');
    } else {
      setLoading(false);
      message.info('Your profile has been successfully updated.');
    }
  }


  const handleDeleteAccount = () => {
    Modal.error({
      title: 'Delete Account',
      icon: <ExclamationCircleOutlined />,
      content: <DeleteUserContentContainer user={user} />,
      // okText: 'No',
      okText: user?.delete?.id ? 'Ok' : 'No',
      cancelText: 'Cancel',
      okType: 'default',
      onOk() {},
      onCancel() {},
      className: 'booking-modal-form',
    });
  }



  const avatarSelector = (data) => {

    const handleClick = (val) => {
      data.props.onSelect(val);
      data.props.onToggleOpen();
    }

    const avatarSet = data.props.values;
    const count = avatarSet.size;
    let current = null;
    if ( count > 0 ) {
      current = avatarSet.values().next().value;
    }

    return (
      <AvatarContainer>
        { avatars && avatars.map( (img, index) => {
          return (
            <AvatarImage key={img} className={ current == img ? 'selected' : '' }>
              <img src={`/avatars/${img}.png`} onClick={ () => { handleClick(img) } } />
            </AvatarImage>
          )
        }) }
      </AvatarContainer>
    )
  }

  return (
    <SidebarLayout>

      <Heading>Manage Profile</Heading>

        <FormContainer>

          <div className="user-avatar-wrapper">
            { user.avatar && <Avatar style={{ boxShadow: '2px 2px 2px #ccc' }} src={<img src={`/avatars/${user.avatar}.png`} />} size={84} /> }
            { ! user.avatar && <Avatar style={{ backgroundColor: 'rgb(242 175 31)', boxShadow: '2px 2px 2px #ccc' }} size={84} icon={<UserOutlined />} /> }
          </div>

          <Spin tip="Updating profile..." spinning={ loading }>
          <Form
            layout="vertical"
            name="updateProfile"
            initialValues={{ ...user, dob: moment(user.dob), currency: 'INR' }}
            onFinish={ handleSubmit }
          >

            <Row gutter={[16, 16]}>
              <Col md={12} xs={24} >
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[{ required: true, message: 'Please enter your first name' }]}
                  style={{ marginBottom: 14 }}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>

              <Col md={12} xs={24} >
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[{ required: true, message: 'Please enter your last name' }]}
                  style={{ marginBottom: 14 }}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>


              <Col md={12} xs={24} >
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[{ required: true, message: 'Please enter your gender' }]}
                  style={{ marginBottom: 14 }}
                >
                  <Select style={{ width: '100%' }} placeholder="Select your Gender">
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                    <Select.Option value="other">Other</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={12} xs={24} >
                <Form.Item
                  label="Date of Birth"
                  name="dob"
                  rules={[
                    { required: true, message: 'Please enter/select your date of birth' },
                    ({ getFieldValue }) => ({
                      validator( _, value ) {
                        if ( value ) {
                          const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);
                          const age = getAge( value.toString() );
                          if ( age >= 18 ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('You must be 18+ years old.');
                          }
                        }
                        return Promise.reject();
                      },
                    }),
                 ]}
                 style={{ marginBottom: 14 }}
                >
                  <DatePicker format="DD/MM/YYYY" placeholder="DD/MM/YYYY" allowClear={true} style={{ width: '100%' }} />
                </Form.Item>
              </Col>


            </Row>


            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Please enter your country' }]}
            >
              <Select showSearch allowClear style={{ width: '100%' }} placeholder="Country">
                { countries.map( value => <Select.Option key={value} value={value}>{value}</Select.Option> ) }
              </Select>
            </Form.Item>



            <Form.Item
              label="Contact Number"
              name="phone"
              rules={[{ required: true, message: 'Please enter your contact number' }]}
            >
              <Input placeholder="Ex: +91 123 4567 890" />
            </Form.Item>


            <Form.Item
              label="Email Address"
              name="email"
              rules={[{ required: true, message: 'Please enter your email address' }]}
            >
              <Input placeholder="Email Address" disabled />
            </Form.Item>


            <Form.Item
              label="Please select your interests"
              name="interests"
              rules={[{ required: true, message: 'Please select your interests' }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                // defaultValue={[]}
              >
                { interests.map( value => <Select.Option key={value} value={value}>{value}</Select.Option> ) }
              </Select>
            </Form.Item>
            <Form.Item
              label="Spoken Language(s)"
              name="language"
              rules={[{ required: true, message: 'Please select your spoken languages' }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
              >
                { languages.map( value => <Select.Option key={value} value={value}>{value}</Select.Option> ) }
              </Select>
            </Form.Item>

            <Form.Item
              label="Currency"
              name="currency"
              rules={[{ required: true, message: 'Please select your currency' }]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                disabled
              >
                <Select.Option key="USD">USD</Select.Option>
                <Select.Option key="INR">INR</Select.Option>
                <Select.Option key="EUR">EUR</Select.Option>
                <Select.Option key="JPY">JPY</Select.Option>
              </Select>
            </Form.Item>


           <Form.Item
              label="Avatar"
              name="avatar"
              rules={[{ required: true, message: 'Please select your avatar' }]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select avatar"
                dropdownRender={ avatarSelector }
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: 0, textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" className="btn-orange" block style={{ height: 40 }}>Update Profile</Button>
            </Form.Item>
          </Form>
          </Spin>

          <Button type="danger" block style={{ marginTop: 20, height: 40 }} onClick={handleDeleteAccount}>Delete Account</Button>

        </FormContainer>


    </SidebarLayout>
  )

}


export default Profile;



const Heading = styled.h2`
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #CCC;
  margin-bottom: 40px;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
`;



const FormContainer = styled.div`
  margin: 0 auto;
  margin-top: 70px;
  max-width: 700px;

  background: #fff;
  padding: 30px;

  input, .ant-input { font-size: 14px; }
  .ant-form-item-label > label { font-weight: bold; }


  .user-avatar-wrapper {
    text-align: center;
    margin-top: -72px;
    margin-bottom: 30px;
  }


`;



const AvatarContainer = styled.div`
  max-width: 100%;
  padding: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: spance-between;
`;

const AvatarImage = styled.div`
  max-width: 48px;
  display: flex;

  img {
    max-width: 100%;
    cursor: pointer;
  }

  &.selected {
    border-radius: 50%;
    border: 3px solid #ff0000;
  }

`;


const CostomModalInputContainer = styled.div`
  p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  strong { font-weight: 700; }
  textarea { font-size: 14px; }
  .ant-form-item { margin-bottom: 10px; }
  .ant-modal-confirm-btns { display: none; }
`;

