
const languages = [
  "Algerian Arabic",
  "Assamese",
  "Bengali",
  "Bhojpuri",
  "Burmese",
  "Czech",
  "Dogri",
  "Dutch",
  "Egyptian Arabic",
  "English",
  "French",
  "Garhwali",
  "German",
  "Greek",
  "Gujarati",
  "Himachali ",
  "Hindi",
  "Indonesian",
  "Iranian Persian",
  "Italian",
  "Japanese",
  "Kannada",
  "Korean",
  "kumaoni",
  "Malayalam",
  "Malaysian Cebuano",
  "Marathi",
  "Moroccan Arabic",
  "Nepali",
  "Northern Uzbek",
  "Odia",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Rajasthani",
  "Romanian",
  "Russian",
  "Saʽidi Arabic",
  "Sindhi",
  "Sinhalese",
  "Spanish",
  "Tamil",
  "Telugu",
  "Thai",
  "Tunisian Arabic",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Vietnamese",
  "Xiang Chinese",
];


export default languages;
