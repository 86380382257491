import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Tag, Slider, Checkbox, Spin } from 'antd';
import _ from 'lodash';
import { useRecoilValue, useRecoilState } from 'recoil';
import { propertiesState, propertyFilterState } from 'global/states';

import {
  // SortAscendingOutlined, SortDescendingOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';


const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 2,
});


const SearchFilter = (props) => {
  const { properties, strict, cityProperties=[] } = props;
  const allProperties = useRecoilValue(propertiesState);
  const [ propertyFilter, setPropertyFilter ] = useRecoilState(propertyFilterState);

  const [ minRange, setMinRange ] = useState(200)
  const [ maxRange, setMaxRange ] = useState(5000)
  const [ sortField, setSortField ] = useState({ field: null, order: null })
  const [ types, setTypes ] = useState([])
  const [ comfort, setComfort ] = useState([])
  const [ other, setOther ] = useState([])
  const [ loading, setLoading ] = useState(false)



  const filterData = () => {
    setLoading(true);

    // get all properties to apply filters to
    const _properties = strict ? properties : allProperties;
    let filtered = [];

    // filter property by price
    filtered = _properties.filter( property => { return property.price >= minRange & property.price <= maxRange; });

    // filter by accommodation type
    if ( types.length > 0 ) {
      filtered = filtered.filter( property => { return types.includes(property.accommodation_type[0]) });
    }

    // filter proeprty based on comfort
    if ( comfort.length ) {
      filtered = filtered.filter( property => {
        let results = [];
        comfort.map( item => {
          if ( property.facilities_general.includes(item) ) { results.push(true); }
          else { results.push(false); }
        });
        if ( results.includes(false) ) { return false; }
        else { return true; }
      });
    }

    // filter by pet friendly option
    if ( other && other.length > 0 ) {
      filtered = filtered.filter( property => {
        if ( other.includes('Pet Friendly') ) { return property.rules && property.rules.toLowerCase().includes('pet friendly'); }
        if ( other.includes('Parking') ) { return property.rules && property.rules.toLowerCase().includes('parking'); }
        if ( other.includes('Free Parking') ) { return property.facilities_free && property.facilities_free.includes('Free Parking Space'); }
      });
    }

    // let's update filtered properties.
    console.log('filtered properties', filtered)
    setPropertyFilter({ query: propertyFilter.query, properties: filtered });
    setLoading(false);
  }


  // reset all the filters
  const resetFilters = (update = true) => {
    const _properties = strict ? cityProperties : allProperties;
    console.log('resetFilters::_properties', properties)
    setMinRange(200);
    setMaxRange(5000);
    setSortField({ field: null, order: null });
    setTypes([]);
    setComfort([]);
    setOther([]);

    if ( update ) { setPropertyFilter({ query: propertyFilter.query, properties: _properties }); }
  }


  useEffect( () => {
    console.log('useEffect', propertyFilter.query.state)
    resetFilters(false);
  }, [propertyFilter.query.state] )



  const handlePriceRangeChange = ([ min, max ]) => {
    setMinRange(min)
    setMaxRange(max)
  }

  const handleSortChange = (field) => {
    if ( sortField.field && sortField.field == field ) {
      const order = sortField.order && sortField.order === 'asc' ? 'desc' : 'asc'
      setSortField({ field, order })
    } else {
      setSortField({ field, order: 'asc' })
    }

    let sorted = properties;
    let fieldName = null;
    if ( field == 'Price' ) { fieldName = 'price'; }
    else  { fieldName = 'rating'; }
    sorted = _.orderBy(sorted, [fieldName], [sortField.order]);
    setPropertyFilter({ query: propertyFilter.query, properties: sorted });

  }

  const getSortIcon = (field) => {
    if ( sortField.field == field ) {
      if ( sortField.order == 'asc' ) { return <ArrowDownOutlined /> }
      else { return <ArrowUpOutlined /> }
    }
    return <div />;
  }

  const handleTypeChange = (e) => {
    const value = e.target.value;
    let _types = [...types]
    if ( _types.includes(value) ) {
      _types = _types.filter(item => item !== value)
    }
    else { _types.push(e.target.value); }
    setTypes(_types)
  }

  const handleComfortChange = (e) => {
    const value = e.target.value;
    let _comfort = [...comfort]
    if ( _comfort.includes(value) ) {
      _comfort = _comfort.filter(item => item !== value)
    }
    else { _comfort.push(e.target.value); }
    setComfort(_comfort)
  }

  const handleOtherChange = (e) => {
    const value = e.target.value;
    let _other = [...other]
    if ( _other.includes(value) ) {
      _other = _other.filter(item => item !== value)
    }
    else { _other.push(e.target.value); }
    setOther(_other)
  }


  return (
    <SearchFilterContainer>
    <Spin tip="Filtering..." size="large" spinning={ loading }>

      <Section>
        <Heading>Sort</Heading>
        <Content>
          <SortTag icon={ getSortIcon('Price') } onClick={ () => { handleSortChange('Price') } }>Price</SortTag>
          <SortTag icon={ getSortIcon('Ratings') } onClick={ () => { handleSortChange('Ratings') } }>Ratings</SortTag>
        </Content>
      </Section>

      <Section>
        <Heading>Price Range</Heading>
        <Content>
           { formatter.format(minRange) } - { formatter.format(maxRange) }
          <Slider range defaultValue={[minRange, maxRange]} step={100} min={100} max={20000} onChange={ handlePriceRangeChange } />
        </Content>
      </Section>

      <Section>
        <Heading>Types</Heading>
        <ContentFlex>
          <CustomCheckbox onChange={ handleTypeChange } checked={ types.includes('Guest House') } value="Guest House">Guest House</CustomCheckbox>
          <CustomCheckbox onChange={ handleTypeChange } checked={ types.includes('Homestay') } value="Homestay">Homestay</CustomCheckbox>
          <CustomCheckbox onChange={ handleTypeChange } checked={ types.includes('Hostel') } value="Hostel">Hostel</CustomCheckbox>
        </ContentFlex>
      </Section>

      <Section>
        <Heading>Comfort</Heading>
        <ContentFlex>
          <CustomCheckbox onChange={ handleComfortChange } checked={ comfort.includes('TV') } value="TV">TV</CustomCheckbox>
          <CustomCheckbox onChange={ handleComfortChange } checked={ comfort.includes('Wifi') } value="Wifi">Wifi</CustomCheckbox>
          <CustomCheckbox onChange={ handleComfortChange } checked={ comfort.includes('Common Fridge') } value="Common Fridge">Common Fridge</CustomCheckbox>
          <CustomCheckbox onChange={ handleComfortChange } checked={ comfort.includes('CCTV Surveillance') } value="CCTV Surveillance">CCTV Surveillance</CustomCheckbox>
          <CustomCheckbox onChange={ handleComfortChange } checked={ comfort.includes('AC') } value="AC">AC</CustomCheckbox>
        </ContentFlex>
      </Section>

      <Section>
        <Heading>Other</Heading>
        <ContentFlex>
          <CustomCheckbox onChange={ handleOtherChange } checked={ other.includes('Pet Friendly') } value="Pet Friendly">Pet Friendly</CustomCheckbox>
          <CustomCheckbox onChange={ handleOtherChange } checked={ other.includes('Parking') } value="Parking">Parking</CustomCheckbox>
          <CustomCheckbox onChange={ handleOtherChange } checked={ other.includes('Free Parking') } value="Free Parking">Free Parking</CustomCheckbox>
        </ContentFlex>
      </Section>

      <ButtonSection>
        <Button onClick={ filterData } type="primary" block htmlType="submit" className="btn-orange" style={{ height: 40 }}>Apply</Button>
        <Button onClick={ resetFilters } type="primary" block htmlType="submit" style={{ height: 30, marginTop: 10 }}>Reset</Button>
      </ButtonSection>

    </Spin>
    </SearchFilterContainer>
  )

}

export default SearchFilter;


const SortTag = styled(Tag)`
  border-radius: 20px;
  background: #fff;
  font-size: 15px;
  padding: 5px 10px;
  border-color: #e6a416;
  margin: 3px;
  cursor: pointer;
`;


const SearchFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFF;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  margin-right: 50px;

  @media (max-width: 360px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-right: 0px;
  }


`;

const Section = styled.div`
`;

const ButtonSection = styled.div`
  border-top: 1px solid #EEE;
  padding: 20px;
  padding-bottom: 20px;
`;

const Heading = styled.div`
  background: #dcdcdc; /*#f1f5f8;*/
  font-size: 14px;
  padding: 20px;
  text-transform: uppercase;
  color: #777;
`;

const Content = styled.div`
  padding: 20px;
  background: #fff;
`;

const ContentFlex = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #fff;
`;

const CustomCheckbox = styled(Checkbox)`
  margin: 0;
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 0 !important;
`;






/*
          <SortTag icon={ getSortIcon('Popular') } onClick={ () => { handleSortChange('Popular') } }>Popular</SortTag>
          <SortTag icon={ getSortIcon('Recommended') } onClick={ () => { handleSortChange('Recommended') } }>Recommended</SortTag>

          <SortTag icon={ getSortIcon('Recently Added') } onClick={ () => { handleSortChange('Recently Added') } }>Recently Added</SortTag>

*/
