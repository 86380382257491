import React, { Suspense } from 'react';
import styled from 'styled-components';

import Header from 'global/components/Header';
import Footer from 'global/components/Footer';

import HomepageHeader from 'global/components/Header/Homepage';
import PropertyCard from 'global/components/Cards/Property';
import ArticleCard from 'global/components/Cards/Article';
import CityCard from 'global/components/Cards/City';

import { useRecoilValue } from 'recoil';
import { propertiesState } from 'global/states';
import Loading from 'global/components/Loading';

import AlappuzhaImage from 'assets/cities/alappuzha.jpg';
import CoorgImage from 'assets/cities/coorg.jpg';
import MysoreImage from 'assets/cities/hampi.jpg';
import HampiImage from 'assets/cities/mysore.jpg';


import Image1 from 'assets/articles/1.png';
import Image2 from 'assets/articles/2.png';
import Image3 from 'assets/articles/3.png';
import Image4 from 'assets/articles/4.png';
import Image5 from 'assets/articles/5.png';
import Image6 from 'assets/articles/6.png';
import Image7 from 'assets/articles/7.png';
import Image8 from 'assets/articles/8.png';


const articles = [
  {
    id: 'article-101',
    title: 'Places to Visit in Mumbai',
    url: 'https://www.google.com/travel/things-to-do/see-all?highlight_id=ec17347ae15ba6e0&dest_mid=%2Fm%2F04vmp&dest_state_type=sattd&hl=en-IN&gl=in&tcfs=EhIKCC9tLzA0dm1wEgZNdW1iYWlSAmAB&g2lb=2502548%2C2503780%2C2503806%2C4258168%2C4270442%2C4306835%2C4308226%2C4317915%2C4328159%2C4371334%2C4401769%2C4419364%2C4463263%2C4463666%2C4464738%2C4482194%2C4482438%2C4485851%2C4491350%2C4495816%2C4496891%2C4497652%2C4270859%2C4284970%2C4291517&un=1#ttdm=18.999067_72.870289_10&ttdmf=%252Fm%252F042y1w',
    image: Image1,
  },
  {
    id: 'article-102',
    title: 'Top Himalayan Treks in Uttarakhand',
    url: 'https://www.google.com/travel/things-to-do/see-all?highlight_id=ec17347ae15ba6e0&dest_mid=%2Fm%2F050tt8&dest_state_type=sattd&hl=en-IN&gl=in&tcfs=EhgKCS9tLzA1MHR0OBILVXR0YXJha2hhbmRSAmAB&g2lb=2502548%2C2503780%2C2503806%2C4258168%2C4270442%2C4306835%2C4308226%2C4317915%2C4328159%2C4371334%2C4401769%2C4419364%2C4463263%2C4463666%2C4464738%2C4482194%2C4482438%2C4485851%2C4491350%2C4495816%2C4496891%2C4497652%2C4270859%2C4284970%2C4291517&un=1#ttdm=30.157748_79.289444_8&ttdmf=%252Fm%252F0bkmln ',
    image: Image2,
  },
  {
    id: 'article-103',
    title: 'Top Destinations in Himachal Pradesh',
    url: 'https://www.google.com/travel/things-to-do/see-all?highlight_id=ec17347ae15ba6e0&dest_mid=%2Fm%2F03p85&dest_state_type=sattd&hl=en-IN&gl=in&tcfs=EhwKCC9tLzAzcDg1EhBIaW1hY2hhbCBQcmFkZXNoUgJgAQ&g2lb=2502548%2C2503780%2C2503806%2C4258168%2C4270442%2C4306835%2C4308226%2C4317915%2C4328159%2C4371334%2C4401769%2C4419364%2C4463263%2C4463666%2C4464738%2C4482194%2C4482438%2C4485851%2C4491350%2C4495816%2C4496891%2C4497652%2C4270859%2C4284970%2C4291517&un=1#ttdm=32.045079_77.177443_8&ttdmf=%252Fm%252F03l_bh',
    image: Image3,
  },
  {
    id: 'article-104',
    title: 'Places to Visit in Kerala',
    url: 'https://www.google.com/travel/things-to-do/see-all?g2lb=2502548%2C2503780%2C2503806%2C4258168%2C4270442%2C4306835%2C4308226%2C4317915%2C4328159%2C4371334%2C4401769%2C4419364%2C4463263%2C4463666%2C4464738%2C4482194%2C4482438%2C4485851%2C4491350%2C4495816%2C4496891%2C4497652%2C4270859%2C4284970%2C4291517&hl=en-IN&gl=in&un=1&dest_mid=%2Fm%2F0byh8j&dest_state_type=sattd&dest_src=ts&sa=X&ved=2ahUKEwi90O_aldzuAhWewjgGHRwGAg0QuL0BMAB6BAgBECY&tcfs=EhMKCS9tLzBieWg4ahIGS2VyYWxh#ttdm=9.883862_76.642312_8&ttdmf=%252Fm%252F08_vz3',
    image: Image4,
  },
  {
    id: 'article-105',
    title: 'Places to Visit in Mysore',
    url: 'https://www.google.com/travel/things-to-do?g2lb=2502548%2C2503780%2C2503806%2C4258168%2C4270442%2C4306835%2C4308226%2C4317915%2C4328159%2C4371334%2C4401769%2C4419364%2C4463263%2C4463666%2C4464738%2C4482194%2C4482438%2C4485851%2C4491350%2C4495816%2C4496891%2C4497652%2C4270859%2C4284970%2C4291517&hl=en-IN&gl=in&un=1&dest_mid=%2Fm%2F0j603&dest_state_type=main&dest_src=ts&sa=X&ved=2ahUKEwi90O_aldzuAhWewjgGHRwGAg0QuL0BMAB6BAgBECY&tcfs=EhIKCC9tLzBqNjAzEgZNeXN1cnU#ttdm=12.314880_76.623199_11&ttdmf=%252Fm%252F0gz666',
    image: Image5,
  },
  {
    id: 'article-106',
    title: 'Places to Visit in Coorg',
    url: 'https://www.google.com/travel/things-to-do/places?g2lb=2502548%2C2503780%2C2503806%2C4258168%2C4270442%2C4306835%2C4308226%2C4317915%2C4328159%2C4371334%2C4401769%2C4419364%2C4463263%2C4463666%2C4464738%2C4482194%2C4482438%2C4485851%2C4491350%2C4495816%2C4496891%2C4497652%2C4270859%2C4284970%2C4291517&hl=en-IN&gl=in&un=1&dest_mid=%2Fm%2F01rbh9&dest_state_type=sap&dest_src=ts&sa=X&ved=2ahUKEwi90O_aldzuAhWewjgGHRwGAg0QuL0BMAB6BAgBECY&tcfs=EhMKCS9tLzAxcmJoORIGS29kYWd1#ttdm=12.409382_75.734581_10&ttdmf=%252Fm%252F0b6dj81',
    image: Image6,
  },
  {
    id: 'article-107',
    title: 'Places to visit in Hampi',
    url: 'https://www.google.com/travel/things-to-do?g2lb=2502548%2C2503780%2C2503806%2C4258168%2C4270442%2C4306835%2C4308226%2C4317915%2C4328159%2C4371334%2C4401769%2C4419364%2C4463263%2C4463666%2C4464738%2C4482194%2C4482438%2C4485851%2C4491350%2C4495816%2C4496891%2C4497652%2C4270859%2C4284970%2C4291517&hl=en-IN&gl=in&un=1&dest_mid=%2Fm%2F02476y&dest_state_type=main&dest_src=ts&sa=X&ved=2ahUKEwi90O_aldzuAhWewjgGHRwGAg0QuL0BMAB6BAgBECY#ttdm=15.327159_76.467681_14&ttdmf=%252Fm%252F0gzm_t    ',
    image: Image7,
  },
  {
    id: 'article-108',
    title: 'Places to visit in Jaipur',
    url: 'https://www.google.com/travel/things-to-do/see-all?highlight_id=ec17347ae15ba6e0&dest_mid=%2Fm%2F016722&dest_state_type=sattd&hl=en-IN&gl=in&tcfs=EhMKCS9tLzAxNjcyMhIGSmFpcHVyUgJgAQ&g2lb=2502548%2C2503780%2C2503806%2C4258168%2C4270442%2C4306835%2C4308226%2C4317915%2C4328159%2C4371334%2C4401769%2C4419364%2C4463263%2C4463666%2C4464738%2C4482194%2C4482438%2C4485851%2C4491350%2C4495816%2C4496891%2C4497652%2C4270859%2C4284970%2C4291517&un=1#ttdm=26.951282_75.838996_12&ttdmf=%25252Fm%25252F0c3xt3y',
    image: Image8,
  },


];



// const articles = [
//   {
//     id: 'article-sdfsdr234',
//     image: 'https://images.pexels.com/photos/241558/pexels-photo-241558.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
//     title: 'Top 10 Must Visit Resorts In Coorg For Solo Travellers',
//     url: 'https://pickyourtrail.com/blog/9-best-resorts-in-coorg/',
//   },
//   {
//     id: 'article-dfdfg234',
//     image: 'https://images.pexels.com/photos/1008155/pexels-photo-1008155.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
//     title: 'Top 5 Must Visit Places For Solo Travellers In Karnataka',
//     url: 'https://www.wanderwomaniya.com/post/10-places-in-karnataka-which-are-perfect-for-a-solo-female-traveller',
//   },
//   {
//     id: 'article-5t65wer',
//     image: 'https://images.pexels.com/photos/1851481/pexels-photo-1851481.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
//     title: 'Top 10 Most Famous Tourist Destinations In India',
//     url: 'https://www.treebo.com/blog/tourist-places-in-india/',
//   },
//   {
//     id: 'article-ery4',
//     image: 'https://images.pexels.com/photos/1851481/pexels-photo-1851481.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
//     title: 'Most Popular Solo Teavel Destinations In South India',
//     url: 'https://www.news18.com/news/indiwo/travel-indiwo-5-exquisite-places-for-your-next-solo-trip-to-south-india-1677007.html',
//   },
// ];


const cities = [
  {
    id: 'alappuzha',
    title: 'Alappuzha',
    image: AlappuzhaImage,
    url: 'alappuzha',
  },
  {
    id: 'coorg',
    title: 'Coorg',
    image: CoorgImage,
    url: 'coorg',
  },
  {
    id: 'hampi',
    title: 'Hampi',
    image: MysoreImage,
    url: 'hampi',
  },
  {
    id: 'mysore',
    title: 'Mysore',
    image: HampiImage,
    url: 'mysore',
  },

];




const HomePage = (props) => {
  const properties = useRecoilValue(propertiesState);

  return (
    <div>

      <Header />
      <HomepageHeader />


      <ContentContainer>

        <ListContainer style={{ paddingTop: 60 }}>
          <Title>Accommodations</Title>
          <ListItems>
          { properties && properties.map( property => {
            return (
              <PropertyCard key={property.id} data={property} />
            )
          }) }
          </ListItems>
        </ListContainer>


        <ListContainer>
          <Title>Trending Accommodations</Title>
          <ListItems>
          { properties && properties.slice(0,4).map( property => {
            return (
              <PropertyCard key={property.id} data={property} />
            )
          }) }
          </ListItems>
        </ListContainer>


        <ListContainer>
          <Title>Top Cities</Title>
          <ListItems>
          { cities && cities.map( city => {
            return (
              <CityCard key={city.id} data={city} />
            )
          }) }
          </ListItems>
        </ListContainer>


        <ListContainer>
          <Title>Trending Articles</Title>
          <ListItems>
          { articles && articles.map( article => {
            return (
              <ArticleCard key={article.id} data={article} />
            )
          }) }
          </ListItems>
        </ListContainer>

      </ContentContainer>

      <Footer />

    </div>
  )
}



const HomePageSuspense = (props) => {

  return (
    <Suspense fallback={<HomepageHeader><Loading message="Loading Properties..." /></HomepageHeader>}>
      <HomePage {...props} />
    </Suspense>
  )

}




export default HomePageSuspense;




const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  // padding: 0 50px;

  @media (max-width: 1240px) {
    margin: 20px;
  }

`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
`;

const ListContainer = styled.div`
  // padding: 60px 0;
  padding: 40px 0;
`;

const ListItems = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 100%);
  }
`;



/*
import Slider from 'global/components/Cards/Slider';
        <ListContainer>
          <Title>Sliding Accomodations</Title>
          <Slider properties={properties} />
        </ListContainer>

*/
