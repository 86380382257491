
export const avatars = [
  'Alena', 'Andrew', 'Arya', 'Bethany', 'Bond', 'Brad', 'Cherry', 'ChocolateBoy', 'CoolGuy', 'Darcy',
  'Detective', 'Erica', 'Fernando', 'Gabriel', 'Gilbert', 'GirlPower', 'Halle', 'Harold', 'HarryPotter', 'Henry',
  'Kevin', 'Kylie', 'Lana', 'Nicolas', 'Onella', 'Robin', 'Superhero', 'Suzie',
];


// export const avatars = [
//   'Aang', 'Admiral Zao', 'Amy', 'Andrew','Antman 1', 'Antman', 'Araña', 'Archer', 'Ares', 'Avatar',
//   'Batman', 'Bond', 'Cat woman', 'Chief', 'Ching', 'Chocolate Boy', 'Constanble', 'Deadpool', 'Deepak', 'Detective',
//   'Devil mind', 'Ding Dong', 'Dodo', 'Fly Girl', 'Flycathcher', 'Freezer', 'Harry', 'Hawaldar', 'Hommie', 'Hulk 1', 'Hulk',
//   'I am Batman', 'IT Head', 'Joker 1', 'Joker', 'Julia', 'June', 'Kareem', 'Kathara', 'Kwa', 'Lilly', 'Magician', 'Mai row',
//   'Nany', 'Noor', 'Noriko', 'Officer', 'Ozai', 'Parvati', 'Peggy', 'Prince', 'Professor', 'Queen', 'Red Bull', 'Robyn', 'Sergant',
//   'Spidy', 'Superman 1', 'Superman 2', 'Superman', 'Superwoman', 'Suzie', 'Tank Girl', 'Teacher', 'Totoro', 'Wonder Women',
// ];
