import React, { useState } from 'react';
import SidebarLayout from 'global/layouts/Sidebar';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Form, Button, AutoComplete, Card, InputNumber, message, Spin, Row, Col, Tooltip, Input } from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';

import { useRecoilState } from 'recoil';
import { propertyFilterState } from 'global/states';
import Api from 'global/api';


const { Search } = Input;

const Connect = (props) => {
    const [loading, setLoading] = useState(false);
    const [propertyFilter, setPropertyFilter] = useRecoilState(propertyFilterState);


    return (
        <SidebarLayout>

            <ConnectContainer>
                <Input
                    placeholder="search people"
                    suffix={
                        <Tooltip title="Extra information">
                            <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} className="icon" />
                        </Tooltip>
                    }
                />

                <Wrapper>
                    <div className="details">
                        <UserOutlined style={{ fontSize: 50 }} />
                        <div>
                            <Title>Heather</Title>
                            <Text>Hotel Madelle Inn</Text>

                        </div>
                    </div>
                </Wrapper>
                <Wrapper>
                    <div className="details">
                        <UserOutlined style={{ fontSize: 50 }} />
                        <div>
                            <Title>Heather</Title>
                            <Text>Hotel Madelle Inn</Text>

                        </div>
                    </div>
                </Wrapper>


            </ConnectContainer>

        </SidebarLayout>
    )


}




export default Connect;


const ConnectContainer = styled.div` 
    max-width: 700px;
    margin: 0px auto;
    padding-top: 40px;
    
    .icon{
    font-size: 20px;
  }
`;

const Title = styled.h3`
    margin-top: 15px;
    font-size: 20px;
    font-weight: 600;
    a {
        color: #333;
    }
`;
const Text = styled.div`
    margin-top: 20px;
`;

const Wrapper = styled.div`
    border-radius: 10px;
    border: 1px solid black;
    background-color: #fff;
    margin-top: 40px;
    padding: 20px;

    .details {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 30px;
        padding: 15px 30px;
    }
   
    
    

 
`;

