import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { Form, Button, AutoComplete, DatePicker, Select, InputNumber , message, Spin, Menu, Dropdown, Avatar } from 'antd';

import BackgroundImage from 'assets/backgrounds/bg3.jpg'; // bg5.jpg

import { useHistory } from "react-router-dom";
import { DownOutlined, UserOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { useRecoilValue, useRecoilState } from 'recoil';
import { userState, propertyFilterState } from 'global/states';
import Api from 'global/api';
import moment from 'moment';
import VoyobeeLogo from 'assets/logo.png';

// not using this anymore
// import PredictiveInput from 'global/components/Utils/PredictiveInput';
import { uniqueLocationsList } from 'global/components/Utils/PredictiveInput';

// options for the autocomplete
const autoCompleteOptions = uniqueLocationsList.map( item => { return { label: item, value: item } } );


const Header = (props) => {
  const [ loading, setLoading ] = useState(false);
  const user = useRecoilValue(userState);
  const history = useHistory();
  const [ propertyFilter, setPropertyFilter ] = useRecoilState(propertyFilterState);
  const query = propertyFilter.query;


  const handleSearch = async (fields) => {
    console.log("handleSearch::fields",fields);
    setLoading(true);
    const result = await Api.queryCollection('properties', {
      state: fields.state.toLowerCase()
    });
    setLoading(false);

    if ( result.length > 0 ) {
      setPropertyFilter({
        query: {...fields},
        properties: result
      })
      history.push(`/properties/search`);
    } else {
      message.info('No properties found!');
    }
  }

  const handleDateSelection = ( data ) => {
    if ( data && Array.isArray(data) ) {
      const [ checkIn, checkOut ] = data;
      const checkInDate = checkIn.toDate();
      const checkOutDate = checkOut.toDate();
      let filter = {...propertyFilter};
      if ( ! filter['query'] ) { filter['query'] = {}; }
      filter['query'] = { ...filter['query'], date: data }
      setPropertyFilter(filter);
    } else {
      let filter = {...propertyFilter};
      if ( ! filter['query'] ) { filter['query'] = {}; }
      filter['query'] = { ...filter['query'], date: data }
      setPropertyFilter(filter);
    }
  }


  return (
    <Fragment>
      <BigHeaderContainer>
        <BigHeaderInner>
        <ContentWrapper>

          <Heading>Unknown to Known</Heading>
          <SubHeading>Book your stay and connect with guests</SubHeading>

          <FormContainer>
          <Spin tip="Searching..." size="large" spinning={ false }>
            <Form onFinish={handleSearch} initialValues={{...query}}>

              <div className="form-input-container">

                <Form.Item name="state" rules={[{ required: true, message: 'Please enter your travel destination' }]}>
                  {/*<PredictiveInput />*/}
                  <AutoComplete
                    options={autoCompleteOptions}
                    allowClear
                    placeholder="Where do you want to travel?"
                    autoFocus
                    filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 }
                  />
                </Form.Item>

                <Form.Item
                  name="date"
                  rules={[{ required: true, message: 'Please enter your check-in and check-out dates' }]}
                >
                  <DatePicker.RangePicker
                    disabledDate={ (curr) => curr && curr < moment().subtract(1,'days').endOf('day') }
                    format="DD/MM/YYYY"
                    separator={<ArrowRightOutlined />}
                    placeholder={['Check-In', 'Check-Out']}
                    onChange={handleDateSelection}
                  />
                </Form.Item>

                <Form.Item
                  name="guests"
                  rules={[{ required: true, message: 'Please select total number of guests' }]}
                >
                  <InputNumber min={1} max={8} placeholder="No. Of Guests" />
                  {/*
                  <Select style={{ width: '100%' }} placeholder="Total Guests">
                    { Array.from({length: 20}, (_, i) => i + 1).map( value => <Select.Option key={value} value={value}>{value}</Select.Option> ) }
                  </Select>
                  */}
                </Form.Item>

              <Button loading={loading} type="primary" htmlType="submit" className="btn-orange">Search</Button>

              </div>

            </Form>
           </Spin>
           </FormContainer>

        </ContentWrapper>
        </BigHeaderInner>
      </BigHeaderContainer>

      { props.children && <div style={{ marginTop: 50 }}>{ props.children }</div> }

    </Fragment>
  )

}


export default Header;



const BigHeaderContainer = styled.div`
  background: url(${BackgroundImage}) no-repeat center center;
  background-size: cover;
  position: relative;
  margin-top: 70px;
  min-height: 400px;

  // background-color: #330000;
  // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23D18'/%3E%3Cstop offset='1' stop-color='%23330000'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%23FA3' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23FA3' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.4'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
  // background-attachment: fixed;
  // background-size: cover;

  &:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 80%);
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

`;


const BigHeaderInner = styled.div`
  padding-top: 150px;
  padding-bottom: 100px;

  @media only screen and (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 50px;
  }

`;



const Heading = styled.div`
  font-size: 76px; /* 36px; 50px;*/
  font-weight: 600;
  color: #ecab2e; /* #fff */
  margin-top: 60px;
  text-shadow: 1px 1px #000;
  text-align: center;
  font-family:  'edoregular', Arial, sans-serif;

  @media only screen and (max-width: 768px) {
    font-size: 46px;
  }

`;
const SubHeading = styled.div`
  font-size: 32px; /* 22px; */
  font-weight: 400;
  color: #FFF;
  margin-top: 10px;
  text-align: center;
  font-family:  'edoregular', Arial, sans-serif;

  @media only screen and (max-width: 768px) {
    font-size: 32px;
  }

`;


const FormContainer = styled.div`
  margin-top: 50px;
`;




const ContentWrapper = styled.div`
  z-index: 100;
  position: relative;
  max-width: 1200px;
  border-radius: 5px;
  margin: 0 auto;
  padding: 50px;

  @media only screen and (max-width: 768px) {
    margin: 10px;
    padding: 10px;

  }

  .form-input-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .ant-form-item:first-child { flex: 1.5; }
    .ant-form-item:nth-child(2) { flex: 1; margin-left: 10px; }
    .ant-form-item:nth-child(3) { flex: 0.5; margin-left: 10px; }

    .ant-form-item-explain.ant-form-item-explain-error { color: #f2af1f; }
    .ant-form-item { margin-bottom: 0; }

    .ant-picker { width: 100%; }
    .ant-input-number {
      width: 100%;
    }

  }
  @media only screen and (max-width: 768px) {

    .form-input-container {
      display: flex;
      flex-direction: column;

      .ant-form-item:nth-child(2) {
        margin-left: 0px;
      }
      .ant-form-item:nth-child(3) {
        margin-left: 0px;
      }
      button{
        margin-left: 0px;
      }
    }

    .form-input-container .ant-form-item {
      margin-bottom: 10px;
    }


  }


  button {
    margin-left: 10px;
    height: 40px;
    min-width: 200px;
  }

  .ant-select { font-size: 15px; line-height: 40px; }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input { cursor: pointer; }
  .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder { font-size: 15px; line-height: 40px; }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector { height: 40px; }
  .ant-input-number, .ant-picker { font-size: 15px; line-height: 40px; height: 40px; }
  .ant-picker-input > input, .ant-input-number-input { font-size: 15px; }
`;

const Logo = styled.img`
  display: block;
  max-width: 160px;
`;


/*
const Logo = styled.img`
  display: block;
  border-radius: 10px;
  width: 100%;
  min-height: 100px;
  max-width: 80px;
`;
import VoyobeeLogo from 'assets/logo.svg';

<Logo src={VoyobeeLogo} title="Voyobee Logo" />
*/

/*
  background: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 100%);

*/
