import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const ArticleCard = (props) => {
  const { data } = props;

  return (
    <Wrapper>
      <a target="_blank" href={data.url}>
        <Image src={ data.image } />
      </a>
      <Title><a target="_blank" href={data.url}>{ data.title }</a></Title>
    </Wrapper>
  )

}


export default ArticleCard;


const Title = styled.h3`
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  padding: 20px;
  padding-top: 0;
  a {
    color: #333;
  }
`;

const Wrapper = styled.div`
  border-radius: 10px;
  box-shadow: 20px 20px 6px #d0cdcd;
`;


const Container = styled.div`
  padding: 100px;
  flex-direction:row;
  display: flex;
  justify-content: space-evenly;
`;


const Image = styled.img`
  display: block;
  border-radius: 10px;
  width: 100%;
  min-height: 160px;
`;
