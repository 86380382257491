import React, { useState } from 'react';
import styled from 'styled-components';
import { DownOutlined, UpOutlined } from '@ant-design/icons';


const ExpandCollapse = (props) => {
  const [ collapsed, setCollapsed ] = useState(true);
  const { text } = props;

  if ( text && text.length < 700 ) {
    return (
      <Container>
        <div className="expanded">
          { props.children }
        </div>
      </Container>
    )
  }

  return (
    <Container>
      { collapsed &&
        <div className="collapsed">
          <div className="inner">
            { props.children }
          </div>
          <ReadMore onClick={ () => { setCollapsed(false) } }>Show More <DownOutlined style={{ color: '#333', fontSize: '12px', marginLeft: '5px' }} /></ReadMore>
        </div>
      }
      { ! collapsed &&
        <div className="expanded">
          { props.children }
          <ReadMore onClick={ () => { setCollapsed(true) } }>Show Less <UpOutlined style={{ color: '#333', fontSize: '12px', marginLeft: '5px' }} /></ReadMore>
        </div>
      }

    </Container>
  )
}

export default ExpandCollapse;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .collapsed {
    .inner {
      max-height: 170px;
      overflow: hidden;
    }
    // box-shadow: 0px 14px 6px -6px #f2af1f;
    // border-bottom: 20px solid #f7f8fa;
  }


`;

const ReadMore = styled.button`
  color: #000;
  font-weight: bold;

  border: none;
  cursor: pointer;
  background: transparent;
  text-align: left;
  padding: 0;

  width: calc(100% - 30px);
  margin-top: 20px;
  margin-left: 30px;
}
`;


