import React from 'react';
import styled from 'styled-components';
import { Rate, Button } from 'antd';
import { Link } from 'react-router-dom';
import { CheckCircleFilled, EnvironmentOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";


const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 2,
});


const Recommended = () => {
  return (
    <RecommendedContainer>
      <CheckCircleFilled />
      <div className="text">Recommended</div>
    </RecommendedContainer>
  )
}


const PropertyCard = (props) => {
  const { data } = props;
  const history = useHistory();

  // const allRoomsPrices = data.rooms && data.rooms.map( room => {
  //   if ( room.price ) { return room.price; }
  // } );
  // let minPrice = 0;
  // if ( allRoomsPrices ) { minPrice = Math.min(...allRoomsPrices); }
  // else { minPrice = data.price; }

  const minPrice = data.price;

  const handleClick = () => {
    history.push(`/properties/${data.slug}-${data.id}`);
  }


  return (
    <Wrapper onClick={ handleClick }>
      <div style={{ position: 'relative' }}>
        <Image src={data.images[0]} />
        {data.recommended && <Recommended />}
      </div>
      <Title>{data.name}</Title>

      <Address>{data.address}</Address>

      <DetailsWrapper>
        <Price>{formatter.format(minPrice)}</Price>
        <Rating disabled allowHalf defaultValue={data.rating || 0} />
      </DetailsWrapper>

      <Button className="btn-orange" block style={{ marginTop: 20, borderRadius: 4 }}>See Details</Button>
    </Wrapper>
  )

}


export default PropertyCard;



const Wrapper = styled.div`
  border-radius: 4px;
  // background: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  transition: all .3s ease;
  cursor: pointer;

  &:hover {
    background: #FFF;
  }

  button:hover, button:focus { color: #000; }
`;



const Rating = styled(Rate)`
  margin-top: 8px;
  font-size: 16px;
  color: #f2af1f;

  .ant-rate-star-zero {
    .ant-rate-star-first, .ant-rate-star-second {
      color: rgba(0, 0, 0, 0.45);
    }
  }
`;

const Title = styled.h3`
  margin-top: 15px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;

  a {
    color: #333;
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

const Address = styled.div`
  margin-top: 15px;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #666;

  height: 34px;
  overflow: hidden;

  margin-bottom: 15px;


`;


const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  // margin-top: 10px;

`;

const Price = styled.div`
  margin-top: 10px;
  font-size: 15px;
  line-height: 100%;
  color: #000;
  font-weight: 600;
`;



const Item = styled.div`
  margin-top: 10px;
  display: flex;

  .anticon {
    margin-right: 10px;
    font-size: 20px;
    color: #e2ba1b;

  }
  .text {
    max-width: 400px;
    font-size: 13px;
    line-height: 100%;
    align-self: center;
    color: gray;
  }
  .dectext{
    max-width: 700px;
    padding-left: 30px;
    color: #6f6969;
  }
  .servicestext{
    color: #5a5353;
  }

`;

const Image = styled.img`
  display: block;
  border-radius: 4px;
  width: 100%;
  height: 220px;
  object-fit: cover;
`;

const ImgIcon = styled.img`
  width: 120px;
  margin-top: 10px;
  margin-left: 20px;
`;


const RecommendedContainer = styled.div`
  position: absolute;
  bottom: 7px;
  left: 0;
  right: 0;

  font-size: 13px;
  font-weight: 600;
  color: #fff;
  margin: 0 20px;
  padding: 5px;
  text-align: center;
  border-radius: 15px;

  background: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    color: #21c747;
    margin-right: 10px;
    font-size: 16px;
  }
  div.text { text-transform: uppercase; }
`;
