import React from 'react';
import Loading from 'global/components/Loading';
import styled from 'styled-components';

const SuspenseLoading = (props) => {

  return (
    <LoadingWrapper>
      <Loading {...props} />
    </LoadingWrapper>
  )

}

export default SuspenseLoading;


const LoadingWrapper = styled.div`
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
