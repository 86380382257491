import React, { useState } from 'react';
import styled from 'styled-components';
import { DatePicker, Alert } from 'antd';
import moment from 'moment';
import { ArrowRightOutlined } from '@ant-design/icons';
import { propertyFilterState } from 'global/states';
import { useRecoilState } from 'recoil';


const BookingDatePicker = () => {
  const [ propertyFilter, setPropertyFilter ] = useRecoilState(propertyFilterState);
  // console.log('propertyFilter', propertyFilter)
  // const [ dates, setDates ] = useState({
  //   checkIn: (propertyFilter.query.date && propertyFilter.query.date[0].toString() || null),
  //   checkOut: (propertyFilter.query.date && propertyFilter.query.date[1].toString() || null),
  // });


  const handleDateSelection = ( data ) => {
    if ( data && Array.isArray(data) ) {
      // const [ checkIn, checkOut ] = data;
      // const checkInDate = checkIn.toDate();
      // const checkOutDate = checkOut.toDate();
      // setDates({
      //   checkIn: checkInDate.toString(),
      //   checkOut: checkOutDate.toString(),
      // });
      let filter = {...propertyFilter};
      if ( ! filter['query'] ) { filter['query'] = {}; }
      filter['query'] = { ...filter['query'], date: data }
      setPropertyFilter(filter);
    } else {
      let filter = {...propertyFilter};
      if ( ! filter['query'] ) { filter['query'] = {}; }
      filter['query'] = { ...filter['query'], date: data }
      setPropertyFilter(filter);
    }
  }

  return (
    <div>
      <DatePicker.RangePicker
        allowClear={true}
        format="DD/MM/YYYY"
        disabledDate={ (curr) => curr && curr < moment().subtract(1,'days').endOf('day') }
        separator={<ArrowRightOutlined />}
        placeholder={['Check-In', 'Check-Out']}
        defaultValue={propertyFilter?.query?.date}
        allowClear={true}
        // autoFocus
        size="large" // large middle small
        style={{ width: '100%' }}
        onChange={handleDateSelection}
        getPopupContainer={(triggerNode) => {
          return triggerNode.parentNode;
        }}
      />
    </div>
  )

}

export default BookingDatePicker;

