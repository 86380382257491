import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Button, Drawer } from "antd";
import BookingConnects from "global/components/Connects/BookingConnects";
import { Rate } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

import { connectsState } from "global/states";
import { useRecoilState } from "recoil";
import Api from "global/api";
import _ from "lodash";
import moment from "moment";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 2,
});

const NewBookingUI = (props) => {
  const [connects, setConnects] = useRecoilState(connectsState);
  const { bookings, properties, deleteBooking, modifyBooking, cancelBooking } =
    props;
  const [details, setDetails] = useState([]);
  const [showConnects, setShowConnects] = useState(null);
  const [currentBooking, setCurrentBooking] = useState(null);

  const currBooking = bookings.find((item) => item.id === currentBooking);
  let property = null;

  if (currBooking && currBooking.propertyId) {
    property = properties.find((item) => item.id === currBooking.propertyId);
  }
  // const property = properties.find( item => item.id === currBooking.propertyId );

  const toggleDetails = (id) => {
    let toggled = [...details];

    if (toggled.indexOf(id) !== -1) {
      const filtered = toggled.filter((bid) => bid !== id);
      setDetails(filtered);
    } else {
      toggled.push(id);
      setDetails(toggled);
    }
  };

  const displayConnects = (id) => {
    setCurrentBooking(id);
    setShowConnects(true);
  };

  const hideConnects = () => {
    setShowConnects(false);
  };

  return (
    <BookingsContainer>
      {bookings &&
        bookings.length > 0 &&
        bookings.map((booking) => {
          const property = properties.find(
            (item) => item.id === booking.propertyId
          );
          if (!property) {
            return;
          }
          return (
            <SingleBookingContainer key={booking.id}>
              <SingleBooking>
                <div className="property-image">
                  <Link to={`/properties/${property.slug}-${property.id}`}>
                    <Image src={property.images[0]} />
                  </Link>
                  <Rating
                    disabled
                    allowHalf
                    defaultValue={property.rating || 0}
                  />
                </div>

                <div className="details">
                  <div className="title">
                    <Link to={`/properties/${property.slug}-${property.id}`}>
                      {property.name}
                    </Link>
                  </div>
                  <p className="address">{property.address}</p>

                  <div className="bold" style={{ marginTop: 30 }}>
                    Booking ID: {booking.id}
                  </div>
                  <div
                    className="bold"
                    style={{ marginTop: 15, marginBottom: 30 }}
                  >
                    Guest Name: {booking.guest.firstName}{" "}
                    {booking.guest.lastName || ""}
                  </div>

                  <div className="dates">
                    <CalendarOutlined /> Check In:{" "}
                    {format(new Date(booking.dates.checkIn), "do MMM, yyyy")} at
                    12:00 PM
                  </div>
                  <div className="dates">
                    <CalendarOutlined /> Check Out:{" "}
                    {format(new Date(booking.dates.checkOut), "do MMM, yyyy")}{" "}
                    at 10:00 AM
                  </div>

                  <div className="booking-details">
                    <h3>Rooms Selected: </h3>
                    <div className="rooms">
                      {booking.rooms &&
                        booking.rooms.map((bookedRoom) => {
                          return (
                            <div
                              className="room-details"
                              key={bookedRoom.room.id}
                            >
                              <div className="rd-title">
                                {bookedRoom.room.title}
                              </div>
                              <div className="rd-beds">
                                Total Beds: {bookedRoom.booked} Bed(s)
                              </div>
                              <div className="rd-price">
                                Total Cost:{" "}
                                {formatter.format(
                                  bookedRoom.room.price * bookedRoom.booked
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                <div className="pay_status">
                  <div className="paymentstatus">Payment {booking.status}</div>
                  <div className="total">
                    <strong>Rs. {formatter.format(booking.total)}</strong>
                    <p>(includes all taxes and fees.)</p>
                  </div>
                </div>
              </SingleBooking>
              <BookingActions>
                <div className="left">
                  {booking.status == "successful" && (
                    <Button
                      type="primary"
                      onClick={() => {
                        modifyBooking(booking.id);
                      }}
                    >
                      Modify Booking
                    </Button>
                  )}
                  {booking.status == "successful" && (
                    <Button
                      type="primary"
                      className="btn-orange"
                      onClick={() => {
                        Api.analytics.logEvent('view_connects', { user: (Api.uid || null), bookingId: booking.id });
                        displayConnects(booking.id);
                      }}
                    >
                      View Connects
                    </Button>
                  )}
                </div>
                <div className="right">
                  {booking.status !== "successful" && (
                    <Button
                      type="primary"
                      danger
                      onClick={() => {
                        deleteBooking(booking.id);
                      }}
                    >
                      Delete Booking
                    </Button>
                  )}
                  {booking.status === "successful" && (
                    <Button
                      type="primary"
                      danger
                      onClick={() => {
                        cancelBooking(booking.id);
                      }}
                    >
                      Cancel Booking
                    </Button>
                  )}
                </div>
              </BookingActions>
            </SingleBookingContainer>
          );
        })}

      {showConnects && (
        <BookingConnects
          id={currentBooking}
          bookings={bookings}
          properties={properties}
          visible={showConnects}
          hideDrawer={hideConnects}
          connects={connects}
        />
      )}
    </BookingsContainer>
  );
};

export default NewBookingUI;

const Rating = styled(Rate)`
  margin-top: 20px;
  font-size: 24px;
  color: #f2af1f;
  text-align: center;

  @media only screen and (max-width: 1024px) {
    text-align: left;
  }

  .bo
`;

const BookingsContainer = styled.div`
  margin: 80px 0;
  margin-top: 40px;
`;

const SingleBookingContainer = styled.div`
  margin-bottom: 40px;
`;

const SingleBooking = styled.div`
  padding: 30px;
  background: #fff;
  border-radius: 5px 5px 0 0;
  display: flex;


  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }

  .bold {
    font-weight: 600;
  }

  .dates {
    margin-top: 10px;
    .anticon { margin-right: 10px; font-size: 20px; color: #F2AF1F; }
  }

  .pay_status {
    // background: #efefef;
    margin-top: -30px;
    margin-right: -30px;
    margin-bottom: -30px;
    padding: 30px;
    margin-left: 30px;
    font-size: 24px;
    font-weight: 500;
    border-left: 1px solid #dedede;

    display: flex;
    flex-direction: column;
    justify-content: center;


    @media only screen and (max-width: 1024px) {
      margin-top: 30px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      border: none;
      border-top: 1px solid #ccc;

      .total { text-align: center; }
    }


    .paymentstatus { color: #ffb100; font-weight: 700; margin-bottom: 20px; text-align: center }
    strong { font-weight: 700; }

}
    p { font-size: 16px; margin-top: 10px; text-align: center; }
  }

  .property-image {
    display: flex;
    flex-direction: column;
    a { display: inline-block; }
    img {
      @media only screen and (max-width: 1024px) { width: 100%; display: block; max-height: 100%; }
    }
  }

  .details {
    margin-left: 30px;
    flex: 1 auto;
    align-self: center;


    @media only screen and (max-width: 1024px) {
      margin-left: 0px;
      margin-top : 15px
    }

  }

  .title {
    font-size: 24px;
    font-weight: bold;
    a { color: #000; }
  }

  .address {
    font-size: 16px;
    color: #222;
    margin-top: 15px;
  }
  .other-details {
    margin-top: 20px;
    .item {
      margin-top: 5px;
      display: flex;
    }
    .key {
      flex: 1;
      background: #fff;
      border: 1px solid #ccc;
      padding: 5px;
      font-size: 15px;
      // font-weight: 700;
      border-radius: 5px;
    }
    .value {
      flex: 3;
      background: #fff;
      padding: 5px;
      font-size: 16px;
      color: #000;
      margin-left: 10px;
    }

    .payment-status {
      text-transform: capitalize;
      &.successful { background: #d2ffd4; border-color: #d2ffd4; }
      &.failed { background: #ffc6b4; border-color: #ffc6b4; }
      &.pending { background: #ffde9c; border-color: #ffde9c; }
    }
    .paymentstatus { text-transform: capitalize; }

  }


  .booking-details {
    margin-top: 30px;
    border-top: 1px dashed #ccc;
    padding-top: 30px;

    h3 {
      font-size: 20px;
      font-weight: 700;
      color: #000;
      margin-bottom: 20px;



    .rooms {
      background: #ffdd93;
      padding: 30px;
    }
    .total {
      font-weight: 700;
      margin-top: 15px;
    }



    .room-details {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      border-bottom: 1px solid #f5c357;
      margin-bottom: 20px;
      padding-bottom: 20px;
      &:last-child { border: none; margin-bottom: 0; padding-bottom: 0; }

      .rd-title { font-size: 22px; font-weight: 600; margin-bottom: 10px; }
      // .rd-beds { flex: 1; }
      // .rd-price { flex: 1; }

    }

  }


`;

const BookingActions = styled.div`
  // border-bottom: 1px solid #c3f6f9;
  // border-top: 3px solid #c3f6f9;
  border-bottom: 1px solid #d6d6d6;
  border-top: 3px solid #d6d6d6;
  padding: 20px 30px;
  // background: #ddfdff;
  background: #efefef;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: space-between;

  .left,
  .right {
    button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .left {
    flex: 1;
  }
  .right {
    flex: 1;
    text-align: right;
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    .left {
      flex-direction: column;
      display: flex;
    }
    .right {
      flex-direction: column;
      display: flex;
    }

    .left,
    .right {
      button {
        margin-right: 0px;
        &:last-child {
          margin-right: 0;
        }
        margin-bottom: 10px;
      }
    }
  }
`;

const Image = styled.img`
  display: block;
  // border-radius: 5px;
  // max-height: 120px;
  max-height: 200px;
`;

/*
      { showConnects &&
        <Drawer
          closable={ true }
          destroyOnClose={ true }
          maskClosable={ false }
          width={500}
          title="Connect"
          placement="right"
          onClose={hideConnects}
          visible={true}
        >
          <BookingConnects id={currentBooking} bookings={bookings} properties={properties} />
        </Drawer>
      }


*/
