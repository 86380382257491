import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import VoyobeeLogo from 'assets/logo.png';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
// google maps url ref
// https://stackoverflow.com/questions/15042283/current-location-google-maps-link-to-directions

const Marker = (props) => {
  const handleClick = (data) => {
    // const winClick = window.open(`http://maps.google.com/?ie=UTF8&hq=&ll=${props.lat},${props.lng}`, '_blank');
    // window.open(`https://www.google.com/maps?ie=UTF8&saddr=My+Location&ll=${props.lat},${props.lng}`, '_blank');
    window.open(`https://www.google.com/maps?saddr=My+Location&ll=${props.lat},${props.lng}&daddr=${props.text},${props.address}&amp;z=14`, '_blank');

    // https://www.google.com/maps?saddr=My+Location&daddr=760+West+Genesee+Street+Syracuse+NY+13204
  }

  //  onClick={ handleClick }
  return (
    <MarkerContainer logo={VoyobeeLogo} onClick={ handleClick }>
      {/* props.text */}
    </MarkerContainer>
  )
}

const MarkerContainer = styled.div`
  padding: 20px;
  /*border: 4px solid #f2af1f;*/
  cursor: pointer;
  min-width: 120px;
  max-width: 200px;
  border-radius: 10px;
  text-align: center;
  /*background: #000;*/
  color: #FFF;
  font-size: 14px;
  background: ${ (props) => props.logo ? `url('${props.logo}') no-repeat center center` : '#000' };
  background-color: #fff;
  background-size: contain;

  &:hover {
    border-color: #FFF;
  }

`;





class SimpleMap extends Component {

  render() {
    return (
      <MapContainer>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyB31cvhYElk7we4POGDq17NvpxX-6EE934' }}
          defaultCenter={{ lat: this.props.geo.u_, lng: this.props.geo.h_ }}
          defaultZoom={16}
        >
          <Marker
            lat={this.props.geo.u_}
            lng={this.props.geo.h_}
            text={ this.props.property.name }
            address={ this.props.property.address }
          />
        </GoogleMapReact>
      </MapContainer>
    );
  }
}

export default SimpleMap;



const MapContainer = styled.div`
  height: 400px;
  width: 100%;
  padding: 0 30px;
  margin-top: 20px;

  @media (max-width: 480px) {
    padding: 0 ;
  }


`;
