const firebaseConfig = {
  apiKey: "AIzaSyB9RUwmdSi6n54PeXwjfvdEkshjV45zzCo",
  authDomain: "voyobee-app.firebaseapp.com",
  databaseURL: "https://voyobee-app.firebaseio.com",
  projectId: "voyobee-app",
  storageBucket: "voyobee-app.appspot.com",
  messagingSenderId: "1068250178455",
  appId: "1:1068250178455:web:68a4e19e6cac4647c69ad2",
  measurementId: "G-KTDHGEYH0N"
};


export default firebaseConfig;
