import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import firebaseConfig from './config';
import { message } from 'antd';
import { differenceInDays, isWeekend } from 'date-fns';



const checkIfWeekend = (dates) => {
  const totalDays = differenceInDays(new Date(dates.checkOut), new Date(dates.checkIn));
  if ( totalDays >= 7 ) { return false; }
  const isWknd = isWeekend( new Date(dates.checkIn) );
  return isWknd;
}



class FireBase {


  constructor() {
    if (firebase.apps.length === 0) {
      const app = firebase.initializeApp(firebaseConfig);
      // this.analytics = getAnalytics(app);
      this.analytics = app.analytics()
    }
    this.firebase = firebase;
    this.firestore = this.firebase.firestore();
    this.auth = this.firebase.auth();
    // this.storage = this.firebase.storage();
  }

  analytics() {
    return this.analytics;
  }


  auth() {
    return this.auth;
  }


  // handle user login
  async login(email, password) {
    try {
      const res = await this.firebase.auth().signInWithEmailAndPassword(email, password);
      const data = await this.getRecord('users', res.user.uid );
      message.success(`You're successfully logged in.`);
      return { ...data, id: res.user.uid };
    } catch (err) {
      console.log("login::err",err);
      message.error(`Please enter a valid email address and password.`);
      return false;
    }
  }

  // handle user logout
  async logout() {
    await this.firebase.auth().signOut().then( data => {
      message.success( `You're successfully logged out.`);
    })
    .catch( err => {
      message.error(`Error Occurred: ${ err.message }`);
    });
  }

  // handle user password reset
  async resetPassword(password) {
    const user = this.firebase.auth().currentUser;

    await user.updatePassword(password).then( data => {
      message.success( `Your password has beens successfully updated.`);
    })
    .catch( err => {
      message.error(`Error Occurred: ${ err.message }`);
    });
  }


  // handle user registration
  async register( data ) {
    try {
      // create new user
      const res = await this.firebase.auth().createUserWithEmailAndPassword(data.email, data.password);
      const userId = res.user.uid;

      // create user profile and add additional details
      const profile = await this.saveRecordWithId( 'users', userId, {
        uid: userId,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        createdAt: new Date(),
      });

      // show success toast
      message.success(`Your account has been successfully created.`);
      return true;
    }
    catch (err) {
      message.error(`Error Occurred: ${ err.message }`);
      return false;
    }
  }


  async saveRecordWithId( collection, id, _data ) {
    const data = { ..._data, updatedAt: new Date() };
    const coll = this.firestore.collection(collection);
    const res = await coll.doc(id).set(data, { merge: true });
    return res;
  }

  async updateUserProfile( data ) {
    const userId = this.firebase.auth().currentUser.uid;
    const profile = await this.saveRecordWithId( 'users', userId, data);
    return true;
  }


  async addBooking( _data ) {
    const userId = this.firebase.auth().currentUser.uid;
    const roomsData = [];
    // let totalCost = 0;

    Object.keys(_data.selected).map( (key) => {
      const room = _data.rooms.find( item => item.id == key );
      roomsData.push({
        room: room,
        booked: _data.selected[key]
      });
      // const beds = _data.selected[key];
      // totalCost = totalCost + parseInt(_data.selected[key]) * parseInt(room.price);
    });


  let costPerDay = 0;
  Object.keys(_data.selected).map( key => {
    const room = _data.rooms.find( item => item.id == key );
    const beds = _data.selected[key];
    costPerDay = costPerDay + parseInt(_data.selected[key]) * parseInt( checkIfWeekend(_data.dates) ? room.prices.weekend_final : room.prices.weekday_final );
  });
  const totalNights = differenceInDays(new Date(_data.dates.checkOut), new Date(_data.dates.checkIn));
  const totalCost = parseInt(totalNights) * costPerDay;



    const data = {
      propertyId: _data.property.id,
      userId: userId,
      rooms: roomsData,
      guest: _data.guest,
      dates: _data.dates,
      createdAt: new Date(),
      status: 'pending', // successful, failed
      total: totalCost,
    };

    const profile = await this.saveRecordWithId( 'users', userId, _data.guest);

    const res = await this.firestore.collection('bookings').add(data);
    return res;
  }





  async createRecord( collection, _data ) {
    const data = { ..._data, createdAt: new Date() }
    const res = await this.firestore.collection(collection).add(data);
    return res;
  }


  async deleteRecord( collection, id ) {
    const res = await this.firestore.collection(collection).doc(id).delete();
    return res;
  }

  async updateRecord( collection, id, data ) {
    const response = await this.saveRecordWithId( collection, id, data);
    return data;
  }


  async getCurrentUser(setUser) {
    this.auth.onAuthStateChanged( async (user) => {
      if ( user && user.uid ) {
        const res = await this.getRecord('users', user.uid );
        setUser({ ...res, id: user.uid });
        return { ...res, id: user.uid };
      } else {
        setUser(null);
        return { loggedIn: false }
      }
    });
  }



 async getRecord( collection, id ) {
    const doc = this.firestore.collection(collection).doc(id);
    const res = await doc.get();
    if ( res.exists ) { return res.data(); }
    else { return {}; }
  }



  async findAllRecords(collection, options) {
    // console.log("collectioncollection",collection)
    const max = options.max || 50;
    const orderBy = {}
    const uid = this.uid;
    // console.log("options.currentUserOnly",options.currentUserOnly)

    if ( options.orderBy ) {
      orderBy.field = options.orderBy.field;
      orderBy.order = options.orderBy.order;
    } else {
      orderBy.field = 'createdAt';
      orderBy.order = 'asc';
    }

    // let query = this.firestore.collection(collection).orderBy(orderBy.field, orderBy.order).limit(max);
    // if ( options.currentUserOnly && uid ) {
    //   console.log("uid",uid)
    //   query.where('userId', '==', uid);
    // }

    // console.log("query",query)

    // const snapshot = await query.get();
    const snapshot = await this.firestore.collection(collection).orderBy(orderBy.field, orderBy.order).limit(max).get();

    if (snapshot.empty) {
      return [];
    }
    const records = [];
    snapshot.docs.forEach( doc => {
      records.push({ ...doc.data(), id: doc.id });
    });
    return records;

  }


  async fetchRealtimeRecords( collection, options, callback ) {
    const max = options.max || 50;
    const orderBy = {}

    if ( options.orderBy ) {
      orderBy.field = options.orderBy.field;
      orderBy.order = options.orderBy.order;
    } else {
      orderBy.field = 'createdAt';
      orderBy.order = 'asc';
    }

    this.firestore.collection(collection).orderBy(orderBy.field, orderBy.order).limit(max).onSnapshot((querySnapshot) => {
      let recs = [];
      querySnapshot.forEach((doc) => {
        recs.push({ ...doc.data(), id: doc.id });
      });
      callback(recs);
      // return recs;
    });

  }



  // async findAllConnects() {
  //   const snapshot = await this.firestore.collection('connect').doc('MpSA4XjftcyT1fDTuzx9').collection('users').limit(50).get();

  //   if (snapshot.empty) {
  //     return [];
  //   }
  //   const records = [];
  //   snapshot.docs.forEach( doc => {
  //     records.push({ ...doc.data() });
  //   });
  //   return records;

  // }


  async findAllMessages() {
    return [];
  }


  async findAllChannels( userId ) {
    const uid = userId || this.uid;
      let recs = [];
      await this.firestore.collection('channels').where('userId', '==', uid).onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          recs.push({ ...doc.data(), id: doc.id });
        });
      });
      console.log("findAllChannels",recs);
      return recs;

  }



  async sendMessage( message, user, options ) {
    let channelId = options.channelId || null;
    console.log("sendMessage::user",user)
    console.log("sendMessage::message",message)
    console.log("sendMessage::options",options);

    if ( ! options.channelId ) {
      const channelData = {
        from: user.uid,
        to: options.to,
        fromBookingId: options.fromBookingId,
        toBookingId: options.toBookingId,
        createdAt: new Date(),
      }
      const channelRes = await this.firestore.collection('channels').add(channelData);
      channelId = channelRes.id;
    }

    const messageData = {
      from: user.uid,
      to: options.to,
      message: message,
      channelId: channelId,
      timestamp: new Date(),
    };
    const snapshot = await this.firestore.collection('channels').doc(channelId).collection('messages').add(messageData);
    return snapshot;
  }




  async queryCollection(collection, options) {
    const max = options.max || 20;
    let snapshot = await this.firestore.collection(collection).orderBy('rating', 'desc').where('city', '==', options.state).limit(max).get();
    // .where('city', '==', options.state)

    if (snapshot.empty) {
      snapshot = await this.firestore.collection(collection).orderBy('rating', 'desc').where('state', '==', options.state).limit(max).get();
      if (snapshot.empty) {
        return [];
      }
    }
    const records = [];
    snapshot.docs.forEach( doc => {
      records.push({ ...doc.data(), id: doc.id });
    });
    return records;
  }


  get uid() {
    return ( this.firebase.auth().currentUser|| {} ).uid;
  }

  fb() {
    return this.firebase;
  }




}



const Api = new FireBase();
export default Api;




/*
    // if realtime updates are required
    if ( options.realtime ) {

      const collectionRef = await this.firestore.collection(collection).orderBy(orderBy.field, orderBy.order).limit(max);
      try {
        const allRecs = [];

        collectionRef.onSnapshot(snapshot => {
          if (snapshot.size) {
            snapshot.forEach( doc => {
              // records.push({ ...doc.data(), id: doc.id });
              const data = doc.data();
              allRecs.push(data);
            });
          } else {
            return [];
          }
        })
        return allRecs;

        // const allCollSnapshot = await collectionRef.onSnapshot();
        // allCollSnapshot.forEach( doc => {
        //   records.push({ ...doc.data(), id: doc.id });
        // });
        // return records;
      }
      catch (err) {
        console.log('Error getting documents', err);
      }

    }
    // if we don't need realtime updates
    else {
      const snapshot = await this.firestore.collection(collection).orderBy(orderBy.field, orderBy.order).limit(max).get();

      if (snapshot.empty) {
        return [];
      }
      const records = [];
      snapshot.docs.forEach( doc => {
        records.push({ ...doc.data(), id: doc.id });
      });
      return records;
    }

  }

*/
