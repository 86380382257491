import React, { useState, useEffect } from 'react';
import SidebarLayout from 'global/layouts/Sidebar';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { bookingsState, propertiesState, userState } from 'global/states';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Modal, message, Empty, Form, Input, Spin } from 'antd';
import Api from 'global/api';
import { ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import NewBookings from './NewBookings';

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}



const ModifyBookingContentContainer = (props) => {
  const { booking, property } = props;
  const [ loading, setLoading ] = useState(false);
  const [ response, setResponse ] = useState({});

  if ( ! booking ) {
    return <div>Invalid booking, Please refresh the page and try again.</div>
  }

  const alreadyRequested = booking?.modification?.id ? true : false;

  const handleSubmit = async (data) => {
    const modificationId = uuid();
    setLoading(true);
    const updateData = {
      modification: {
        id: modificationId,
        message: data.message,
      }
    }
    const res = await Api.updateRecord('bookings', booking.id, updateData);
    setLoading(false);
    setResponse(res);
  }

  return (
    <CostomModalInputContainer>
      { alreadyRequested &&
        <div>
          <p>We have already received your modification request with reference number <strong>{ booking.modification.id }</strong> and will contact you soon.</p>
        </div>
      }
      { !alreadyRequested && response?.modification?.id &&
        <div>
          <p>Thank you. We have received your booking modification request with reference number <strong>{ response.modification.id }</strong> and will contact you soon.</p>
        </div>
      }
      { !alreadyRequested && !response?.modification?.id &&
        <Spin tip="Processing..." spinning={ loading }>
        <p>Are you sure you want to modify the booking?</p>
        <Form
          layout="vertical"
          name="login"
          initialValues={{ remember: true }}
          onFinish={ handleSubmit }
        >
          <Form.Item
            label="Enter your message"
            name="message"
            rules={[{ required: true, message: 'Please enter your message here' }]}
          >
            <Input.TextArea placeholder="Enter your message" rows={2} />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" className="btn-orange" style={{ width: '100%' }}>Yes</Button>
          </Form.Item>
        </Form>
        </Spin>
      }
    </CostomModalInputContainer>
  )
}




const CancelBookingContentContainer = (props) => {
  const { booking, property } = props;
  const [ loading, setLoading ] = useState(false);
  const [ response, setResponse ] = useState({});

  if ( ! booking ) {
    return <div>Invalid booking, Please refresh the page and try again.</div>
  }

  const alreadyRequested = booking?.cancellation?.id ? true : false;

  const handleSubmit = async (data) => {
    const cancellationId = uuid();
    setLoading(true);
    const updateData = {
      cancellation: {
        id: cancellationId,
        message: data.message,
      }
    }
    const res = await Api.updateRecord('bookings', booking.id, updateData);
    setLoading(false);
    setResponse(res);
  }

  return (
    <CostomModalInputContainer>
      { alreadyRequested &&
        <div>
          <p>We have already received your cancellation request with reference number <strong>{ booking.cancellation.id }</strong> and will contact you soon.</p>
        </div>
      }
      { !alreadyRequested && response?.cancellation?.id &&
        <div>
          <p>Thank you. We have received your booking cancellation request with reference number <strong>{ response.cancellation.id }</strong> and will contact you soon.</p>
        </div>
      }
      { !alreadyRequested && !response?.cancellation?.id &&
        <Spin tip="Processing..." spinning={ loading }>
        <p>Are you sure you want to cancel the booking?</p>
        <Form
          layout="vertical"
          name="login"
          initialValues={{ remember: true }}
          onFinish={ handleSubmit }
        >
          <Form.Item
            label="Enter your message"
            name="message"
            rules={[{ required: true, message: 'Please enter your message here' }]}
          >
            <Input.TextArea placeholder="Enter your message" rows={2} />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" className="btn-orange" style={{ width: '100%' }}>Yes</Button>
          </Form.Item>
        </Form>
        </Spin>
      }
    </CostomModalInputContainer>
  )
}



const AllBookings = (props) => {
  const [ loading, setLoading ] = useState(false);
  const [ bookings, setBookings ] = useRecoilState(bookingsState);
  const properties = useRecoilValue(propertiesState);
  const user = useRecoilValue(userState);


  const deleteBooking = (id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete the booking?',
      icon: <ExclamationCircleOutlined />,
      content: 'Once deleted you cannot recover this booking details again.',
      okText: 'Yes',
      cancelText: 'No',
      okType: 'default',
      className: 'booking-modal-default',
      onOk() {
        return new Promise( async (resolve, reject) => {
          const booking = bookings && bookings.find( item => item.id === id );
          const deleted = await Api.deleteRecord('bookings', id);
          console.log("deleted",deleted);
          Api.analytics.logEvent('delete_booking', { bookingId: id, user: (Api.uid || null), data: JSON.stringify(booking) || {} });
          message.success('Your booking has been successfully deleted.');
          const _bookings = await Api.findAllRecords('bookings', {
            orderBy: { field: 'createdAt', order: 'desc' }
          });
          setBookings(_bookings);
          resolve()
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const modifyBooking = (id) => {
    const booking = bookings.find( item => item.id === id );
    const property = properties.find( item => item.id === booking.propertyId );
    Api.analytics.logEvent('modify_booking', { bookingId: id, user: (Api.uid || null) });
    Modal.info({
      title: 'Modify Booking',
      icon: <ExclamationCircleOutlined />,
      content: <ModifyBookingContentContainer booking={booking} property={property} />,
      // okText: 'No',
      okText: booking?.modification?.id ? 'Ok' : 'No',
      cancelText: 'Cancel',
      okType: 'default',
      onOk() {},
      onCancel() {},
      className: 'booking-modal-form',
    });
  }

  const cancelBooking = (id) => {
    const booking = bookings.find( item => item.id === id );
    const property = properties.find( item => item.id === booking.propertyId );
    Api.analytics.logEvent('cancel_booking', { bookingId: id, user: (Api.uid || null) });
    Modal.error({
      title: 'Cancel Booking',
      icon: <ExclamationCircleOutlined />,
      content: <CancelBookingContentContainer booking={booking} property={property} />,
      okText: booking?.cancellation?.id ? 'Ok' : 'No',
      okType: 'default',
      onOk() {},
      onCancel() {},
      className: 'booking-modal-form',
    });
  }

  const refreshBookings = async () => {
    return;
    setLoading(true);
    const bookings = await Api.findAllRecords('bookings', {
      orderBy: { field: 'createdAt', order: 'desc' },
      currentUserOnly: true,
    });
    setBookings(bookings);
    setLoading(false);
  }

  const filteredBookings = user ? bookings.filter( item => item.userId === user.uid ) : [];

  return (
    <SidebarLayout>

      <Heading>
        All Bookings
        {/* <Button type="primary" shape="round" icon={<ReloadOutlined />} onClick={ refreshBookings } loading={loading}>Refresh</Button> */}
      </Heading>

      { filteredBookings.length === 0 &&
        <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={
              <span>
                You haven't booked any property with us, As soon as you book any property it will appear here.
              </span>
            }
          >
            <Button type="primary"><Link to="/">Book New Property</Link></Button>
          </Empty>
      }


      { filteredBookings &&
        <NewBookings bookings={filteredBookings} properties={properties} deleteBooking={deleteBooking} modifyBooking={modifyBooking} cancelBooking={cancelBooking} />
      }

    </SidebarLayout>
  )

}


export default AllBookings;


const BookingActions = styled.div`
  margin-top: 20px;
`;

const BookingItem = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  transition: all .3s ease;
  border: 1px solid #CCC;

  &:hover {
    border: 1px solid rgba(0,0,0,0.2);
  }

  .property-image {
    flex: 1;
    padding: 10px;
    a { display: inline-block; }
  }

  .details {
    flex: 2.5;
    padding: 20px;
    align-self: center;
    p { margin-top: 10px; }
    .rooms {
      margin-top: 10px;
      background: #fff8b6;
      padding: 20px;
      border-radius: 4px;
      border: 1px solid #e0d88e;
      & > div {
        margin-bottom: 15px;
        &:last-child { margin-bottom: 0; }
      }
    }
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  strong { font-weight: 700; }

  .dates {
    flex: 1.5;
    margin-right: 30px;
    padding: 0 20px;
    align-self: center;
    .date { margin-bottom: 10px; }
  }

  .extra {
    flex: 1.3;
    background: #f1f1f1;
    border-left: 1px solid #e0e0e0;
    .payment-status {
      padding: 15px;
      display: flex; align-items: center; height: 100%;
      text-transform: capitalize;
      justify-content: center;
      &.successful { background: #d2ffd4; }
      &.failed { background: #ffc6b4; }
    }
  }


  .location {
    margin-top: 10px;
    text-transform: capitalize;
  }


`;


const Image = styled.img`
  display: block;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  max-height: 160px;
`;

const BookingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #CCC;
  margin-bottom: 40px;
  padding: 20px;
  background: #fff;
  border-radius: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CostomModalInputContainer = styled.div`
  p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  strong { font-weight: 700; }
  textarea { font-size: 14px; }
  .ant-form-item { margin-bottom: 10px; }
  .ant-modal-confirm-btns { display: none; }
`;

