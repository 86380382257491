import React, { useEffect, useState } from "react";
import SidebarLayout from "global/layouts/Sidebar";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Form,
  Button,
  AutoComplete,
  DatePicker,
  Select,
  InputNumber,
  message,
  Spin,
  Row,
  Col,
  Rate,
  Tag,
  Drawer,
} from "antd";

import { useRecoilState, useRecoilValue } from "recoil";
import { propertyFilterState, propertiesState } from "global/states";
import Api from "global/api";

import { Avatar, Tooltip } from "antd";
import {
  UserOutlined,
  AntDesignOutlined,
  ArrowRightOutlined,
  WifiOutlined,
  BulbOutlined,
  MessageOutlined,
} from "@ant-design/icons";

import { uniqueLocationsList } from "global/components/Utils/PredictiveInput";

import { avatars } from "global/data/avatar";
import SearchFilter from "./SearchFilter";
import moment from "moment";
import { useHistory } from "react-router-dom";

const randomNumber = (min = 1, max = 27) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 2,
});

// options for the autocomplete
const autoCompleteOptions = uniqueLocationsList.map((item) => {
  return { label: item, value: item };
});

const Property = (props) => {
  const { data } = props;
  const history = useHistory();

  const handleContainerClick = () => {
    history.push({
      pathname: `/properties/${data.slug}-${data.id}`,
    });
  };

  const avatar1 = avatars[randomNumber()];
  const avatar2 = avatars[randomNumber()];
  const avatar3 = avatars[randomNumber()];

  return (
    <PropertyContainer onClick={handleContainerClick}>
      <div className="property-image">
        <Link to={`/properties/${data.slug}-${data.id}`}>
          <Image src={data.images[0]} />
        </Link>
      </div>

      <div className="details">
        <Title>
          <Link to={`/properties/${data.slug}-${data.id}`}>{data.name}</Link>
          <Rating
            style={{ display: "flex" }}
            disabled
            allowHalf
            defaultValue={data.rating || 0}
          />
        </Title>

        <div className="location">{data.address}</div>

        <div className="icon-text">
          <div>
            <WifiOutlined />
          </div>
          <div>
            <BulbOutlined /> <span>Follows Covid Safety Guidelines</span>
          </div>
        </div>

        <div className="bookings">
          <Avatar.Group
            maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          >
            <Avatar src={`/avatars/${avatar1}.png`} />
            <Avatar src={`/avatars/${avatar2}.png`} />
            <Avatar src={`/avatars/${avatar3}.png`} />
            <Link
              to={`/properties/${data.slug}-${data.id}`}
              style={{ marginLeft: "-8px" }}
            >
              <Avatar style={{ color: "#fff", backgroundColor: "#da9d18" }}>
                +2
              </Avatar>
            </Link>
          </Avatar.Group>

          <div className="facilities-tags">
            {data.rules &&
              data.rules.toLowerCase().includes("couple friendly") && (
                <Tag color="#F2AF1F" style={{ fontSize: 15 }}>
                  Couple Friendly
                </Tag>
              )}
            {data.rules && data.rules.toLowerCase().includes("pet friendly") && (
              <Tag color="#F2AF1F" style={{ fontSize: 15 }}>
                Pet Friendly
              </Tag>
            )}
            {data.facilities_free &&
              data.facilities_free.includes("Free Parking Space") && (
                <Tag color="#F2AF1F" style={{ fontSize: 15 }}>
                  Free Parking
                </Tag>
              )}
          </div>
        </div>
      </div>

      <div className="extra">
        <UserReviews>
          <MessageOutlined />
          <p>{(data.reviews && data.reviews.length) || "0"} Reviews</p>
        </UserReviews>
        <Price>Rs. {formatter.format(data.price)} / Day</Price>
      </div>
    </PropertyContainer>
  );
};

const City = (props) => {
  const [loading, setLoading] = useState(false);
  const [propertyFilter, setPropertyFilter] =
    useRecoilState(propertyFilterState);
  const history = useHistory();

  const [ showFilterDrawer, setShowFilterDrawer ] = useState(false)

  const properties = useRecoilValue(propertiesState);
  const cityName = props.match.params.city.toLowerCase();

  const showPropertyFilterDrawer = () => { setShowFilterDrawer(true); }
  const hidePropertyFilterDrawer = () => { setShowFilterDrawer(false); }

  useEffect(() => {
    setPropertyFilter({
      ...propertyFilter,
      properties: [],
    });
  }, [cityName]);

  if (!cityName) {
    message.info(
      "Please picka  city first befoer viewing all of its properties!"
    );
    history.push({ pathname: "/" });
    return;
  }

  const cityProperties = properties.filter((item) => {
    const currentCityName = item.city.toLowerCase();
    return currentCityName.trim() === cityName;
  });

  const results =
    propertyFilter.properties && propertyFilter.properties.length > 0
      ? propertyFilter.properties
      : cityProperties;
  const query = propertyFilter.query;

  const handleSearch = async (fields) => {
    setLoading(true);
    const result = await Api.queryCollection("properties", {
      state: fields.state.toLowerCase(),
    });
    setLoading(false);
    if (result.length > 0) {
      setPropertyFilter({
        query: {
          state: fields.state,
          date: fields.date,
          guests: fields.guests,
        },
        properties: result,
      });
    } else {
      message.info("No properties found!");
    }
  };

  return (
    <SidebarLayout>
      <FlexContainer>
        <div className="search-filter-wrapper">
          <SearchFilter
            properties={results}
            strict={true}
            cityProperties={cityProperties}
          />
        </div>

        <Drawer
          closable={ true }
          destroyOnClose={ true }
          maskClosable={ false }
          width={320}
          title="Property Filter"
          placement="right"
          onClose={ hidePropertyFilterDrawer }
          visible={ showFilterDrawer }
        >
          <SearchFilter
            properties={results}
            strict={true}
            cityProperties={cityProperties}
          />
        </Drawer>

        <div>
          <SearchContainer>
            <Spin tip="Searching..." size="large" spinning={loading}>
              <Form onFinish={handleSearch} initialValues={{ ...query }}>
                <Row gutter={[16, 16]}>
                  <Col xl={8} xs={24}>
                    <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your travel destination",
                        },
                      ]}
                    >
                      <AutoComplete
                        options={autoCompleteOptions}
                        allowClear
                        placeholder="Where do you want to travel?"
                        autoFocus
                        style={{ width: "100%" }}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={8} xs={24}>
                    <Form.Item
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your travel destination",
                        },
                      ]}
                    >
                      <DatePicker.RangePicker
                        disabledDate={(curr) =>
                          curr &&
                          curr < moment().subtract(1, "days").endOf("day")
                        }
                        style={{ width: "100%" }}
                        format="DD/MM/YYYY"
                        separator={<ArrowRightOutlined />}
                        placeholder={["Check-In", "Check-Out"]}
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={4} xs={24}>
                    <Form.Item
                      name="guests"
                      rules={[
                        {
                          required: true,
                          message: "Please select total number of guests",
                        },
                      ]}
                    >
                      <InputNumber
                        min={1}
                        max={8}
                        placeholder="No. Of Guests"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={4} xs={24}>
                    <Button
                      type="primary"
                      block
                      htmlType="submit"
                      className="btn-orange"
                      style={{ height: 40 }}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </SearchContainer>

          <div className="mobile-ui">
            <Button type="primary" block style={{height: 40}} onClick={showPropertyFilterDrawer}>Property Filters</Button>
          </div>

          <SearchResultsContainer>
            {results &&
              results.map((result, index) => {
                return <Property key={index} data={result} />;
              })}
          </SearchResultsContainer>

          {results && results.length === 0 && (
            <EmptyList>
              <h3>
                No Properties Found. Please Try Again With Different Search
                Parameters & Different Filters.
              </h3>
            </EmptyList>
          )}
        </div>
      </FlexContainer>
    </SidebarLayout>
  );
};

export default City;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;

  .mobile-ui {
    display: none;
  }

  @media only screen and (max-width : 1024px) {
    flex-direction: column;
    .mobile-ui {
      display: block;
      margin-bottom: 20px;
    }
    .search-filter-wrapper { display: none; }
  }
`;

const Rating = styled(Rate)`
  // margin-top: 8px;
  font-size: 16px;
  color: #f2af1f;

  @media only screen and (max-width : 1024px) {
    margin-top: 10px;
  }
`;

const SearchContainer = styled.div`
  // padding-top: 50px;

  .ant-select {
    font-size: 16px;
    line-height: 40px;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    cursor: pointer;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 15px;
    height: 40px;
  }
  .ant-input-number,
  .ant-picker {
    font-size: 15px;
    line-height: 40px;
    height: 40px;
  }
  .ant-picker-input > input {
    font-size: 15px;
  }
  .ant-input-number-input {
    font-size: 15px;
  }

  @media only screen and (max-width : 1024px) {
    padding-top: 0;
    .ant-form-item { margin-bottom: 0; }
  }
`;

const SearchResultsContainer = styled.div`
  // margin-top: 50px;
  // margin-bottom: 50px;
  padding: 0;
  // padding-top: 0;
`;

const PropertyContainer = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  // padding: 10px;
  margin-bottom: 30px;
  transition: all 0.3s ease;
  background: #fff;
  cursor: pointer;

  @media only screen and (max-width : 1024px) {
    flex-direction: column;
  }

  // background: linear-gradient(to bottom, rgba(252,255,244,1) 0%,rgba(233,233,206,1) 100%);
  // background-image: linear-gradient( 89.9deg,  rgba(178,253,238,0.96) -8.1%, rgba(207,244,254,1) 26.3%, rgba(207,244,254,0.48) 47.5%, rgba(254,219,243,0.63) 61.5%, rgba(254,219,243,1) 78.7%, rgba(254,207,210,1) 109.2% );
  // background-image: linear-gradient( 89.9deg,  rgba(208,246,255,1) 0.1%, rgba(255,237,237,1) 47.9%, rgba(255,255,231,1) 100.2% );
  border: 1px solid #ccc;

  &:hover {
    // background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .property-image {
    // flex: 1.2;
    flex: 1.5;
    // padding: 10px;
  }

  .details {
    flex: 2.5;
    // margin-top: 15px;
    // padding: 0 20px;
    padding: 20px;
    align-self: center;
  }

  .icon-text {
    margin-top: 20px;
    display: flex;
    .anticon {
      font-size: 24px !important;
    }
    & > div {
      margin-right: 10px;
      align-items: center;
      display: flex;
    }
    & > div:last-child {
      margin-right: 0;
    }
    .anticon {
      color: #f2af1f;
      font-size: 36px;
    }
    span {
      margin-left: 5px;
      font-size: 16px;
    }
  }

  .address {
    flex: 1.5;
    margin-top: 15px;
    margin-right: 30px;
    padding: 0 20px;
    align-self: center;
  }

  .extra {
    flex: 1;
    padding: 15px;
    padding-top: 30px;
    background: #f1f1f1;
    border-left: 1px solid #e0e0e0;

    @media only screen and (max-width : 1024px) {
      padding-top: 15px;
      align-items: center;
      display: flex;
    }
  }

  .location {
    margin-top: 10px;
    text-transform: capitalize;
  }

  .bookings {
    margin-top: 25px;
    display: flex;
    justify-content: start;
    align-items: center;

    @media only screen and (max-width : 1024px) {
      flex-direction: column;
      align-items: start;
      margin-top: 20px;
    }
  }

  .facilities-tags {
    margin-left: 15px;
    span {
      margin-bottom: 5px;
    }

    @media only screen and (max-width : 1024px) {
      margin-left: 0px;
      padding-top: 10px;
    }
  }
`;

const Image = styled.img`
  display: block;
  // border-radius: 4px;
  border-radius: 4px 0 0 4px;
  width: 100%;
  max-width: 300px;
  min-height: 200px;

  @media only screen and (max-width : 1024px) {
    max-width: 100%;
  }
`;
const Title = styled.h3`
  font-size: 22px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  a {
    color: #333;
  }

  @media only screen and (max-width : 1024px) {
    flex-direction: column;
    align-items: start;
  }
`;
const Price = styled.div`
  margin-top: 30px;
  // font-size: 16px;
  line-height: 100%;
  font-weight: 600;
  color: #000;
  text-align: center;
  text-align: center;
  font-size: 20px;

  @media only screen and (max-width : 1024px) {
    margin: 0;
    margin-left: 10px;
    font-size: 18px;
    // text-align: left;
    // margin-top: 20px;
  }
`;

const UserReviews = styled.div`
  margin-top: 30px;
  text-align: center;

  .anticon {
    font-size: 36px;
    color: #f2af1f;
  }
  p {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 400;
  }

  @media only screen and (max-width : 1024px) {
    display: flex;
    margin: 0;
    p {
      margin: 0;
      margin-left: 10px;
      align-self: center;
    }
    .anticon {
      align-self: center;
    }
  }
`;

const EmptyList = styled.div`
  background: #ffdddb;
  padding: 100px;
  border-radius: 10px;

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
`;
