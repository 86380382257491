import React, { useState } from 'react';
import styled from 'styled-components';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import BookingDatePicker from 'global/components/Utils/BookingDatePicker';


const Guests = (props) => {
  const [ selected, setSelected ] = useState(null);
  // const { connects, booked } = props;
  const { connects } = props;
  const history = useHistory();
  const booked = false;

  const selectUser = (id) => {
    if ( selected === id ) { setSelected(null) }
    else { setSelected(id) }
  }

  const generateHiddenName = (firstName, lastName) => {
    const fullName = `${firstName} ${lastName}`;
    const output = fullName[0] + new Array(fullName.length - 2).join('*') + fullName[fullName.length - 1];

    if ( output ) { return output; }
    else { return "Name Hidden"; }
  }

  const chatWithGuest = () => {
    history.push({
      pathname: '/account/connects',
      // state: { propertyId: property.id }
    });

  }


  return (
    <ConnectsContainer>

      <div style={{ alignSelf: 'center', cursor: 'pointer', marginBottom: 30 }}>
        <BookingDatePicker />
      </div>

      { connects && connects.length === 0 &&
      <EmptyList>
        <p>No connects found for the selected check-in and check-out dates.</p>
      </EmptyList>
      }

      { connects && connects.map( user => {
        if ( selected === user.userId ) {
          return (
            <UserContainer key={user.userId} style={{ flexDirection: 'column' }} onClick={ () => { selectUser(user.userId) } }>
              <div style={{ display: 'flex' }}>
                { user.avatar && <div><Image src={`/avatars/${user.avatar}.png`} /></div> }
                { ! user.avatar && <Image src={`https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`} /> }
                <div className="user-data">
                  { booked && <h3>{ user.firstName } { user.lastName }</h3> }
                  { ! booked && <h3>{ generateHiddenName(user.firstName, user.lastName) }</h3> }
                  <p>{ user.country }</p>
                </div>
                <div className="arrow"><UpCircleOutlined /></div>
              </div>
              <div className="">
                <div className="info-container">
                  <div className="item">
                    <div className="key">Gender:</div>
                    <div className="value">{ user.gender }</div>
                  </div>
                  <div className="item">
                    <div className="key">Interests:</div>
                    <div className="value">{ user.interests && user.interests.map( (item, index) => <Tag key={index} color="#F2AF1F">{item}</Tag> ) }</div>
                  </div>
                  <div className="item">
                    <div className="key">Languages:</div>
                    <div className="value">{ user.language && user.language.map( (item, index) => <Tag key={index} color="#F2AF1F">{item}</Tag> ) }</div>
                  </div>
                </div>
                { ! booked &&
                  <div>Book hostel to connect with guests</div>
                }
                { booked &&
                  <Button type="primary" size="large" block className="btn-orange" onClick={ chatWithGuest }>Chat With This Guest</Button>
                }
              </div>
            </UserContainer>
          )
        } else {
          return (
            <UserContainer key={user.userId} onClick={ () => { selectUser(user.userId) } }>
              { user.avatar && <div><Image src={`/avatars/${user.avatar}.png`} /></div> }
              { ! user.avatar && <div><Image src={`https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`} /></div> }

              <div className="user-data">
                { booked && <h3>{ user.firstName } { user.lastName }</h3> }
                { ! booked && <h3>{ generateHiddenName(user.firstName, user.lastName) }</h3> }
                <p>{ user.country }</p>
              </div>
              <div className="arrow"><DownCircleOutlined /></div>
            </UserContainer>
          )
        }
      } ) }

    </ConnectsContainer>
  )

}


export default Guests;



const EmptyList = styled.div`
  padding: 20px;
  background: #F2AF1F;
  border-radius: 5px;
  border: 1px solid #d8980e;
  p {
    font-size: 16px;
  }
`;

// BookingConnects styles
const ConnectsContainer = styled.div`
`;

const UserContainer = styled.div`
  border: 1px solid #e0e0e0;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 20px;





  .user-data {
    margin-left: 20px;
    align-self: center;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      // margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      color: #999;
    }
  }

  .arrow {
    color: #c3c3c3;
    font-size: 24px;
    margin-left: auto;
    align-self: center;
  }



  .info-container {
    margin: 20px;
    margin-left: 0;

    .item {
      display: flex;
      margin-bottom: 10px;

      .key {
        color: #999;
        font-size: 15px;
        width: 120px;
      }

      .value {
        flex: 1;
        font-size: 15px;
        text-transform: capitalize;
      }

    }

  }



`;

const Image = styled.img`
  display: block;
  border-radius: 50%;
  max-height: 48px;
`;


