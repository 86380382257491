import React, { useState } from 'react';
import styled from 'styled-components';
import PageLayout from 'global/layouts/Page';


const CancellationPolicy = (props) => {

  return (
    <PageLayout heading="Cancellation Policy">

      <h2>What is the cancellation policy?</h2>
      <p>Cancellation policies vary from property to property and are available for review directly from the property page/ property description. Though hosts can set their own policies, typically they apply one of the following:</p>
      <p>Voyobee's cancellation period is 7 days ahead of your arrival date (unless otherwise stated by the property in their House Rules/ cancellation policy). This means you should cancel at least 7 days ahead of your arrival date to avoid a no-show or late cancellation charges.</p>

      <p><strong>To cancel your booking:</strong></p>

      <ul>
        <li>Log into your Voyobee account</li>
        <li>Find your booking.</li>
        <li>Click on "Cancel".</li>
      </ul>

      <p>As soon as you click "Cancel", the property will be automatically notified of your cancellation. It will not be possible to cancel within 7 days of your arrival date. In such circumstances please mail us to <a href="mailto:support@voyobee.com">support@voyobee.com</a>,  we will try our best to help you.</p>

      <div style={{ fontWeight: 700, fontSize: 20,}}>Note: In case, the property’s cancellation period is less than 7 days ahead of your arrival, then we will be deducting 3% as processing fee during the refund. The property’s cancellation policy prevails over our cancellation policy.</div>

    </PageLayout>
  )

}


export default CancellationPolicy;




