import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Input, Card, Typography, Row, Col, List, Tag, Collapse } from "antd";
import {
  MailOutlined,
  AimOutlined,
  PhoneOutlined,
  SearchOutlined,
  PlusOutlined,
  MoreOutlined,
  SnippetsOutlined,
  EditOutlined,
  DribbbleOutlined,
} from "@ant-design/icons";
import PageFullWidthLayout from "global/layouts/PageFullWidth";
import {
  ChatUI,
  MessageInput,
} from "global/components/Connects/BookingConnects";
import {
  userState,
  bookingsState,
  propertiesState,
  messagesState,
  connectsState,
  channelsState,
} from "global/states";
import { useRecoilValue, useRecoilState } from "recoil";
import Api from "global/api";
import _ from "lodash";
import moment from "moment";

const { Text } = Typography;
const { Meta } = Card;

const getLastMessageByUserId = (messages, user) => {
  const userMessages = messages.filter((msg) => msg.to === user.id);
  const sortedUserMessages = _.sortBy(userMessages, ["timestamp.seconds"]);

  let last5Messages = sortedUserMessages;
  if (sortedUserMessages.length === 0) {
    return null;
  }
  if (sortedUserMessages.length > 5) {
    last5Messages = sortedUserMessages.slice(
      Math.max(sortedUserMessages.length - 5, 0)
    );
  }
  const filteredUserMessages = _.orderBy(
    last5Messages,
    ["timestamp.seconds"],
    ["desc"]
  );
  return filteredUserMessages[0]?.from;
};

const ConnectPage = (props) => {
  const propertyId = props?.location?.state?.propertyId;
  // const [ bookings, setBookings ] = useRecoilState(bookingsState);
  // const [ messages, setMessages ] = useRecoilState(messagesState);
  // const [ connects, setConnects ] = useRecoilState(connectsState);

  const bookings = useRecoilValue(bookingsState);
  const messages = useRecoilValue(messagesState);
  const connects = useRecoilValue(connectsState);
  const [selectedProperty, setSelectedProperty] = useState(propertyId);
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedBooking, setSelectedBooking] = useState(null);

  const user = useRecoilValue(userState);
  const properties = useRecoilValue(propertiesState);
  const channels = useRecoilValue(channelsState);

  const [selectedUser, setSelectedUser] = useState();
  const [searchText, setSearchText] = useState("");
  const messagesEndRef = useRef(null);

  const selectUser = (userAndPropertyId, user) => {
    const splitted = userAndPropertyId.split("::");
    setSelectedUser(userAndPropertyId); // change this
    setSelectedProperty(splitted[1]);
    setSelectedUserId(splitted[0]);
    setSelectedBooking(user.bookingId);
    localStorage.setItem("selectedUserId", splitted[0]);
    localStorage.setItem("selectedPropertyId", splitted[1]);
  };

  const onSearchChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  let filteredConnects = [];
  if (searchText) {
    filteredConnects = connects.filter((item) => {
      if (
        item.firstName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      ) {
        return true;
      } else if (
        item.lastName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      ) {
        return true;
      }
    });
    if (filteredConnects.length === 0) {
      filteredConnects = connects;
    }
  } else {
    filteredConnects = connects;
  }

  // group connects by property and dates
  const groupByProperty = _.groupBy(filteredConnects, "propertyId");
  const groupConnectsByPropertyAndDate = { ...groupByProperty };

  if (
    groupConnectsByPropertyAndDate &&
    Object.keys(groupConnectsByPropertyAndDate).length > 0
  ) {
    Object.keys(groupConnectsByPropertyAndDate).map((propertyId) => {
      const allPropertyConnects = groupConnectsByPropertyAndDate[propertyId];
      const propertyConnects = _.sortBy(allPropertyConnects, ["checkIn"]);
      const property = _.find(properties, { id: propertyId });

      const allPropertyBookings = bookings.filter(
        (item) => item.propertyId === propertyId
      );
      const groupedByDate = {};

      allPropertyBookings.map((bkng) => {
        const checkInDate = moment(bkng.dates.checkIn).format("MMMM Do, YYYY");
        groupedByDate[checkInDate] = [];
        propertyConnects.filter((item) => {
          const bookingCheckIn = moment(bkng.dates.checkIn);
          const bookingCheckOut = moment(bkng.dates.checkOut);

          const currentCheckIn = moment(item.checkIn);
          const currentCheckOut = moment(item.checkOut);
          const isCheckInBetweenDatesSelected = bookingCheckIn.isBetween(
            currentCheckIn,
            currentCheckOut
          );
          const isCheckOutBetweenDatesSelected = bookingCheckOut.isBetween(
            currentCheckIn,
            currentCheckOut
          );
          const isCheckInBetweenDatesConnect = currentCheckIn.isBetween(
            bookingCheckIn,
            bookingCheckOut
          );
          const isCheckOutBetweenDatesConnect = currentCheckOut.isBetween(
            bookingCheckIn,
            bookingCheckOut
          );

          if (
            isCheckInBetweenDatesSelected ||
            isCheckOutBetweenDatesSelected ||
            isCheckInBetweenDatesConnect ||
            isCheckOutBetweenDatesConnect
          ) {
            groupedByDate[checkInDate].push(item);
          }
        });
      });

      groupConnectsByPropertyAndDate[propertyId] = groupedByDate;
    });
  }

  // CHANGE THE LOGIC HERE
  let sUser = {};
  let activeProeprty = "";
  if (filteredConnects && filteredConnects.length > 0) {
    if (selectedUser) {
      sUser = filteredConnects.find((item) => {
        const generatedId = `${item.userId}::${item.propertyId}`;
        return generatedId == selectedUser;
      });
    } else {
      if (selectedProperty) {
        const userIndex = _.findIndex(filteredConnects, function (item) {
          return item.propertyId == selectedProperty;
        });
        const selectedUserIndex = userIndex !== -1 ? userIndex : 0;
        sUser = filteredConnects[selectedUserIndex];
      } else {
        const selectedUserId = localStorage.getItem("selectedUserId");
        const selectedPropertyId = localStorage.getItem("selectedPropertyId");
        if (selectedUserId && selectedPropertyId) {
          sUser = filteredConnects.find(
            (user) =>
              user.userId === selectedUserId &&
              user.propertyId === selectedPropertyId
          );
          if (!sUser) {
            sUser = filteredConnects[0];
          }
        } else {
          const lastMessageByUserId = getLastMessageByUserId(messages, user);
          if (lastMessageByUserId) {
            sUser = filteredConnects.find(
              (user) => user.userId === lastMessageByUserId
            );
            if (!sUser) {
              sUser = filteredConnects[0];
            }
          } else {
            sUser = filteredConnects[0];
          }
        }
      }
    }
    activeProeprty = selectedProperty || sUser.propertyId;
  }

  // let's find the current channel user is communicating on
  let selectedChannel =
    channels.find(
      (item) =>
        item.fromBookingId === sUser.bookingId ||
        item.toBookingId === sUser.bookingId
    ) || null;
  // let selectedChannel = selectedUSer ? channels.find( item => (item.fromBookingId === props.id || item.toBookingId === props.id) && ( selectedUSer.bookingId === item.fromBookingId || selectedUSer.bookingId === item.toBookingId ) ) : null;

  let filteredMessages = [];
  if (messages.length > 0 && selectedChannel && selectedChannel.id) {
    filteredMessages = messages.filter(
      (item) => item.channelId == selectedChannel.id
    );
  } else {
    filteredMessages = [];
  }

  // console.group();
  // console.log('filteredConnects', filteredConnects)
  // console.log('groupConnectsByPropertyAndDate', groupConnectsByPropertyAndDate)
  // console.log('channels', channels)
  // console.log('bookings', bookings)

  // console.log('sUser', sUser)
  // console.log('selectedChannel', selectedChannel)
  // console.log('messages', messages)
  // console.log('filteredMessages', filteredMessages)
  // console.groupEnd();

  const scrollToBottom = () => {
    return (
      messagesEndRef.current &&
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    );
  };
  const sendMessage = async (message, channelId = null) => {
    if (!message) {
      return;
    }
    const fromBookingId =
      selectedChannel.filter === "to"
        ? selectedChannel.toBookingId
        : selectedChannel.fromBookingId;
    const toBookingId =
      selectedChannel.filter === "to"
        ? selectedChannel.fromBookingId
        : selectedChannel.toBookingId;
    const toUser =
      selectedChannel.filter === "to"
        ? selectedChannel.from
        : selectedChannel.to;

    const res = await Api.sendMessage(message, user, {
      channelId: (selectedChannel && selectedChannel.id) || null,
      fromBookingId: fromBookingId,
      toBookingId: selectedBooking || toBookingId,
      to: selectedUserId || toUser,
    });
    scrollToBottom();
  };

  return (
    <PageFullWidthLayout>
      <HeaderContainer>
        <Heading>My Connects</Heading>
      </HeaderContainer>

      {!groupConnectsByPropertyAndDate ||
        (Object.keys(groupConnectsByPropertyAndDate).length === 0 && (
          <NoActiveConnects>
            <div className="inner">
              <h3>Oops!</h3>
              <p>
                You don't have any active bookings or active connects. Please
                book a property to connect with other users.
              </p>
            </div>
          </NoActiveConnects>
        ))}

      {groupConnectsByPropertyAndDate &&
        Object.keys(groupConnectsByPropertyAndDate).length > 0 && (
          <PageWrapper>
            <Row>
              <Col xs={24} lg={6}>
                <ContactListContainer>
                  <Col xs={24}>
                    <div style={{ margin: 2, marginBottom: 20 }}>
                      <Input
                        placeholder="search contacts"
                        onChange={onSearchChange}
                        initialValue={searchText}
                        allowClear
                        suffix={
                          <SearchOutlined
                            style={{ color: "rgba(0,0,0,.45)" }}
                          />
                        }
                        style={{ display: "flex" }}
                      />
                    </div>
                  </Col>

                  {groupConnectsByPropertyAndDate &&
                    Object.keys(groupConnectsByPropertyAndDate).length > 0 &&
                    Object.keys(groupConnectsByPropertyAndDate).map(
                      (propertyId, pIndex) => {
                        const property = _.find(properties, { id: propertyId });
                        const groupedByDate =
                          groupConnectsByPropertyAndDate[propertyId];

                        return (
                          <PropertyConnectsContainer
                            key={`${propertyId}-${pIndex}`}
                            className={
                              property.id === activeProeprty
                                ? "selected-property"
                                : ""
                            }
                          >
                            <h2>{property.name}</h2>

                            <CustomCollapse
                              bordered={false}
                              expandIconPosition="right"
                              defaultActiveKey={Object.keys(groupedByDate)}
                              collapsible={true}
                            >
                              {groupedByDate &&
                                Object.keys(groupedByDate).length > 0 &&
                                Object.keys(groupedByDate).map((section) => {
                                  const groupConnects = groupedByDate[section];
                                  return (
                                    <Collapse.Panel
                                      header={section}
                                      key={section}
                                    >
                                      {groupConnects &&
                                        groupConnects.map((user, index) => {
                                          return (
                                            <List
                                              key={`${user.userId}-${user.checkIn}-${index}`}
                                              itemLayout="horizontal"
                                              onClick={() => {
                                                selectUser(
                                                  `${user.userId}::${user.propertyId}`,
                                                  user
                                                );
                                              }}
                                              className={
                                                user.userId === sUser.userId &&
                                                property.id === activeProeprty
                                                  ? "selected-user"
                                                  : "unselected-user"
                                              }
                                            >
                                              <List.Item
                                                style={{
                                                  margin: 0,
                                                  cursor: "pointer",
                                                  padding: 0,
                                                }}
                                              >
                                                <List.Item.Meta
                                                  avatar={
                                                    <div>
                                                      <Image
                                                        src={
                                                          user.avatar
                                                            ? `/avatars/${user.avatar}.png`
                                                            : `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`
                                                        }
                                                      />
                                                    </div>
                                                  }
                                                  title={
                                                    <div
                                                      style={{
                                                        maxWidth: "90%",
                                                      }}
                                                    >
                                                      <a
                                                        style={{
                                                          color:
                                                            "rgb(242, 175, 31)",
                                                          fontWeight: 400,
                                                        }}
                                                      >
                                                        {user.firstName}{" "}
                                                        {user.lastName}
                                                      </a>
                                                    </div>
                                                  }
                                                  description={
                                                    <p
                                                      style={{
                                                        maxWidth: "90%",
                                                      }}
                                                    >
                                                      {user.country}
                                                    </p>
                                                  }
                                                />
                                              </List.Item>
                                            </List>
                                          );
                                        })}
                                    </Collapse.Panel>
                                  );
                                })}
                            </CustomCollapse>
                          </PropertyConnectsContainer>
                        );
                      }
                    )}
                </ContactListContainer>
              </Col>
              <Col xs={24} lg={18} className="info-box">
                {sUser && (
                  <ContactInfo>
                    <Card>
                      <Card.Meta
                        avatar={
                          <Image
                            src={
                              sUser.avatar
                                ? `/avatars/${sUser.avatar}.png`
                                : `https://ui-avatars.com/api/?name=${sUser.firstName}+${sUser.lastName}`
                            }
                          />
                        }
                        title={
                          <div
                            style={{
                              fontSize: 15,
                              color: "rgb(242, 175, 31)",
                              fontWeight: 700,
                            }}
                          >
                            {sUser.firstName} {sUser.lastName}
                          </div>
                        }
                        description={
                          <Text
                            note
                            style={{ fontSize: 12, color: "rgb(146 146 146)" }}
                          >
                            {sUser.country}
                          </Text>
                        }
                      />

                      <InfoContainer>
                        {/* <div className="item">
                          <div className="key">Check-in Date:</div>
                          <div className="value">
                            {moment(sUser.checkIn).format("dddd, MMMM Do YYYY")}
                          </div>
                        </div>
                        <div className="item">
                          <div className="key">Check-out Date:</div>
                          <div className="value">
                            {moment(sUser.checkOut).format(
                              "dddd, MMMM Do YYYY"
                            )}
                          </div>
                        </div> */}
                        <div className="item">
                          <div className="key">Gender:</div>
                          <div className="value">{sUser.gender}</div>
                        </div>
                        <div className="item">
                          <div className="key">Interests:</div>
                          <div className="value">
                            {sUser.interests &&
                              sUser.interests.map((item, index) => (
                                <Tag key={index} color="#F2AF1F">
                                  {item}
                                </Tag>
                              ))}
                          </div>
                        </div>
                        <div className="item">
                          <div className="key">Languages:</div>
                          <div className="value">
                            {sUser.language &&
                              sUser.language.map((item, index) => (
                                <Tag key={index} color="#F2AF1F">
                                  {item}
                                </Tag>
                              ))}
                          </div>
                        </div>
                      </InfoContainer>
                    </Card>
                    <div style={{ padding: 20 }}>
                      {filteredMessages.length > 0 && (
                        <ChatUI user={user} messages={filteredMessages} />
                      )}
                      {/* <div ref={messagesEndRef} /> */}
                      {filteredMessages.length === 0 && (
                        <EmptyMessages>
                          <p>Send your first message!</p>
                        </EmptyMessages>
                      )}
                      <div ref={messagesEndRef}>
                        <MessageInput sendMessage={sendMessage} />
                      </div>
                    </div>
                  </ContactInfo>
                )}
              </Col>
            </Row>
          </PageWrapper>
        )}
    </PageFullWidthLayout>
  );
};

export default ConnectPage;

const PageWrapper = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;

  @media only screen and (max-width: 768px) {
    margin: 15px;
    padding: 15px;
  }

  .info-box {
    border: 0.1px solid rgb(255 245 245);
    padding-left: 20px;

    @media only screen and (max-width: 768px) {
      padding-left: 0px;
    }
  }
`;

const ContactListContainer = styled.div`
  max-height: 700px;
  overflow: auto;
  padding-right: 10px;

  @media only screen and (max-width: 768px) {
    padding-right: 0px;
  }

  .ant-input-affix-wrapper {
    width: auto;
    border-radius: 30px;
    border-color: #f2af1f;
  }

  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
    font-size: 12px;
  }
`;

const ContactInfo = styled.div`
  max-height: 700px;
  overflow: auto;

  display: "block";

  .ant-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-left: 20px;
  }
  .ant-card-bordered {
    border: 1px solid #f2af1f;
    margin: 0px;
  }
  .ant-card-body {
    padding: 15px;
  }
`;

const Image = styled.img`
  display: block;
  border-radius: 50%;
  max-height: 48px;
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin: 0 !important;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin: 0 15px;
    margin-bottom: 20px;
  }
`;

const EmptyMessages = styled.div`
  background: #ffefcb;
  padding: 40px;
  margin-bottom: 30px;
  border-radius: 20px;
  p {
    font-size: 16px;
    text-align: center;
    margin: 0;
  }
`;

const InfoContainer = styled.div`
  margin-left: 0;
  margin-top: 25px;

  .item {
    display: flex;
    margin-bottom: 10px;

    .key {
      color: #999;
      font-size: 15px;
      width: 120px;
    }

    .value {
      flex: 1;
      font-size: 15px;
      text-transform: capitalize;
    }
  }
`;

const PropertyConnectsContainer = styled.div`
  margin-bottom: 20px;
  border: 1px solid #f2af1f;
  padding: 10px;
  border-radius: 5px;

  h2 {
    margin: 0;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    font-size: 18px;
    margin-bottom: 15px;
  }

  &.selected-property {
    // border-color: #f2af1f;
    // background: #fffbd6;
    border-color: #d2d2d2;
    background: #f7f8fa;
    background: #FFF;

    .ant-collapse-content-box {
      // background: #fffbd6;
      background: #f7f8fa;
      background: #FFF;
    }
    .ant-collapse-header {
      background: #f2af1f;
      color: #fff !important;
    }
    .anticon {
      padding: 0 !important;
      margin: 5px 0;
    }
  }

  .selected-user {
    background: #e6e6e6;
    padding: 0 12px;
    margin: 0 -12px;
    padding-top: 6px;
  }
  .unselected-user {
    padding-top: 6px;
  }

  .selected-user h4 a,
  .selected-user p {
    // color: #008000 !important;
    font-weight: 700 !important;
    // color: #4caf50 !important;
  }
`;

const NoActiveConnects = styled.div`
  background: #ffd8d5;
  padding: 100px 50px;
  border-radius: 5px;

  .inner {
    max-width: 500px;
    margin: 0 auto;
  }

  h3 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 0;
  }
`;

const CustomCollapse = styled(Collapse)`
  .ant-collapse-content-box {
    background: #fff;
    padding: 2px;
    margin-top: 6px;
  }

  .ant-collapse-item {
    margin-bottom: 5px !important;
    border: none !important;
  }

  .ant-collapse-header {
    background: #f2af1f;
    color: #fff !important;
    padding: 5px 10px !important;
    border-radius: 5px !important;
    font-weight: 600 !important;
  }

  .anticon {
    padding: 0 !important;
    margin: 5px 0;
  }
`;
