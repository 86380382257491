import React, { useState } from "react";
import styled from "styled-components";
import AuthLayout from "global/layouts/Auth";
import { Form, Input, Button, message, Spin } from "antd";
import { Link } from "react-router-dom";
import Api from "global/api";

const RegisterPage = (props) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (data) => {
    setLoading(true);
    const res = await Api.register(data);
    if (!res) {
      setLoading(false);
    } else {
      // setUser(res.user);
      props.history.push("/");
    }
  };

  return (
    <AuthLayout>
      <HeadingContainer>
        <h1>Welcome to Voyobee!</h1>
        <h2>Create New Account</h2>
      </HeadingContainer>

      <FormContainer>
        <Spin tip="Creating new account..." spinning={loading}>
          <Form
            layout="vertical"
            name="register"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "Please enter your first name!" },
              ]}
            >
              <Input placeholder="First Name" maxLength="30" minLength="2" />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Please enter your last name!" },
              ]}
            >
              <Input placeholder="Last Name" minLength="1" />
            </Form.Item>

            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { required: true, message: "Please enter your email address!" },
              ]}
            >
              <Input placeholder="Email Address" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter your password here!" },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item style={{ marginBottom: 0, textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-orange"
                style={{ width: "50%" }}
              >
                Create Account
              </Button>
            </Form.Item>
          </Form>
        </Spin>

        <div className="register-content">
          Already have an account? <Link to="/auth/login">Login</Link>
        </div>
      </FormContainer>

      <Footer>
        By continuing, you agree to our{" "}
        <Link to="/company/terms-and-conditions">Terms and Conditions</Link> and{" "}
        <Link to="/company/privacy-policy">Privacy Policy</Link>.
      </Footer>
    </AuthLayout>
  );
};

export default RegisterPage;

const HeadingContainer = styled.div`
  h1,
  h2 {
    font-size: 28px;
    font-weight: 600;
    color: #000;
    text-align: center;
  }

  h2 {
    font-size: 22px;
    margin-top: 10px;
  }

  @media only screen and (max-width: 768px) {
    h1 {
      padding-top: 40px;
    }
  }
`;

const FormContainer = styled.div`
  max-width: 360px;
  margin: 0 auto;
  margin-top: 50px;

  .register-content {
    margin-top: 30px;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    .register-content {
      margin-top: 30px;
      margin-bottom: 60px;
    }
  }
`;

const Footer = styled.div`
  max-width: 360px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 0 10px;

  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;

  font-size: 14px;
  color: #999;
`;
