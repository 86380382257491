import React, { useState } from 'react';
import styled from 'styled-components';
import PageLayout from 'global/layouts/Page';


const AboutUs = (props) => {

  return (
    <PageLayout>
      <ContentContainer>

        <h2>About Us</h2>

        <p>We are a futuristic social networking platform providing travel booking services and allowing users to connect with people staying at the same place. By investing in digital technology, Voyobee’s vision is to enable interaction between like-minded travellers from different parts of the world</p>

        <div className="flex">
          <div className="image">
            <img src="https://www.voyobee.com/assets/img/details-1.png" />
          </div>
          <div className="content">
            <h3>We redefine the backpacking experience!</h3>
            <p>Voyobee is a start-up company based in Bangalore, India, focused on changing the way people meet and make plans when they stay in a new place</p>

            <p>We offer a comprehensive range of accommodation for domestic and international travellers who are looking for hostels, B&Bs and guesthouses. Our Mission is to facilitate networking during travel along with creating a seamless journey from known to the unknown destinations</p>
            <p>At Voyobee, we are powered by curiosity, experimentation and the desire for continuous learning. We are thoroughly responsive, transparent and friendly because we are aware of the real challenges faced by customers. Our diversity leads to consistent improvements and innovations in our practices</p>

            <h3>We strive to make travel truly flawless!</h3>
          </div>
        </div>

      </ContentContainer>
    </PageLayout>
  )

}


export default AboutUs;


const ContentContainer = styled.div`
  h2 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #F2AF1F;
    font-family: "Poppins", sans-serif;
  }

  h3 {
    font-size: 32px;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  p {
    font-family: "Open Sans", sans-serif;
    color: #585a61;
    margin: 20px 0;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    
  }

  .image {
    max-width: 100%;
    flex: 1.5;
    margin-right: 50px;
    img {
      max-width: 100%;
      display: block;
    }
  }
  .content {
    flex: 3;
  }

  @media (max-width: 480px) {
    .flex {
      flex-direction: column;
      
    }
  }



`;


