import React, { useState, useEffect } from 'react';
import DefaultLayout from 'global/layouts/Default';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tabs, Row, Col, Button, Drawer, Avatar, Rate, message } from 'antd';

import PropertyCarousel from './PropertyCarousel';
import {
  EnvironmentOutlined, CalendarOutlined, WifiOutlined, CheckCircleOutlined, TeamOutlined, HomeOutlined, SettingOutlined,
  InsertRowLeftOutlined, CoffeeOutlined, FileDoneOutlined, CloseOutlined, CommentOutlined,
  CheckSquareOutlined
} from '@ant-design/icons';

import { useRecoilValue, useRecoilState } from 'recoil';
import { userState, propertiesState, propertyFilterState, connectsState, bookingsState } from 'global/states';
import RoomSelection from './RoomSelection'
import ReactMarkdown from 'react-markdown';
import { useHistory } from "react-router-dom";
import ExpandCollapse from 'global/components/Utils/ExpandCollapse';

import PropertyGuests from './Guests';
import GoogleMap from 'global/components/Utils/GoogleMap';
import Loading from 'global/components/Loading/SuspenseLoading';

import Api from 'global/api';
import _ from 'lodash';
import moment from 'moment';
import BookingDatePicker from 'global/components/Utils/BookingDatePicker';


import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShower, faWifi, faHeadset, faSnowflake, faHandsWash, faFlask, faPlug,
  faTshirt, faToriiGate, faParking, faPaw, faUtensils, faLaptopHouse, faGuitar, faDoorClosed, faPumpSoap,
  faTv, faVideo, faHouseUser, faSuitcaseRolling, faCouch, faBook, faHotTub, faConciergeBell
} from '@fortawesome/free-solid-svg-icons'
import { differenceInDays, isWeekend } from 'date-fns'

library.add(faShower, faWifi, faHeadset, faSnowflake, faHandsWash, faFlask, faPlug, faTshirt, faToriiGate, faParking, faPaw, faUtensils,
  faLaptopHouse, faGuitar, faDoorClosed, faPumpSoap, faTv, faVideo, faHouseUser, faSuitcaseRolling, faCouch, faBook, faHotTub, faConciergeBell );



const iconMapping = {
  'Shower' : 'shower',
  'Wifi': 'wifi',
  '24 Hours Reception': 'headset',
  'Air Condition': 'snowflake',
  'Sanitizer': 'hands-wash',
  'Kettle' : 'flask',
  'Plug and socket' : 'plug',
  'Iron Box': 'tshirt',
  'Hanger': 'torii-gate',
  'Free Parking Space' : 'parking',
  'Pet Friendly' : 'paw',
  'Kitchen' : 'utensils',
  'Laundry' : 'tshirt',
  'Laptop Friendly' : 'laptop-house',
  'Instrument' : 'guitar',
  'Fridge' : 'door-closed',
  'Toilet Kit' : 'pump-soap',
  'Free Breakfast' : 'concierge-bell',
  'TV': 'tv',
  'CCTV Surveillance': 'video',
  'Housekeeping': 'house-user',
  'Luggage Storage': 'suitcase-rolling',
  'Common area ( lobby)': 'couch',
  'Library': 'book',
  'AC': 'snowflake',
  'Hot water': 'hot-tub',
  'Bed Light & Plug point': 'plug',
  // 'Laptop Friendly' : 'fas fa-laptop-house'
};



const defaultCovidGuidelines = `
All hosts and guests must agree to:

- Wear a mask or face covering when interacting in person
- Maintain a distance of 6 feet (2 meters) from each other at all times

## Additional guidance for travelling during COVID-19

### Don't travel if you’ve recently been exposed to or have symptoms of COVID-19

To protect the health and safety of our community, it is Voyobee’s policy to follow the following guidelines if;

- You are actively infected or have tested positive with COVID-19 in the past 30 days
- You suspect you are sick or have been exposed and are awaiting test results to confirm or deny a diagnosis of COVID-19
- You are showing symptoms or are concerned about possible infection with COVID-19
- You’ve had close, sustained contact with an individual confirmed or suspected to be infected with COVID-19 within the last 14 days

### Wash your hands regularly

Be sure to wash your hands often, especially if you’re in contact with people outside your reservation and are touching surfaces and utensils in a shared space or a common area.

- Wash your hands properly with soap and water for at least 20 seconds
- If soap and water are not readily available, use a hand sanitizer that contains at least 60% alcohol; cover your hands and rub them together until they feel dry

### Maintain distance and wear a mask in common areas and shared spaces

When you are in a common area or shared space (as a host or guest), wear a mask and maintain distance from anyone who's not part of your reservation at all times in accordance with the COVID-19 safety practices (at least 6 feet or 2 meters). Guest should avoid physical contact with Property managers and try to do Online Check-in wherever it is available

### Property will also follow local guidelines as mentioned / prescribed by every state. We request the guest/s to kindly oblige the same.
`;


const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 2,
});

// this function removes the extra comma at the end of string
const removeComma = (str) => {
  return str.replace(/,\s*$/, "");
}

const checkIfWeekend = (checkIn, checkOut) => {
  const totalDays = differenceInDays(checkIn, checkOut);
  if ( totalDays >= 7 ) { return false; }
  const isWknd = isWeekend( new Date(checkIn) );
  return isWknd;
}



const FooterBanner = (props) => {
  const [ visible, setVisible ] = useState( props.location.state && props.location.state.viewRooms ? true : false );
  const [ visibleGuestsDrawer, setVisibleGuestsDrawer ] = useState(false);
  // const [ datepickerDates, setDatepickerDates ] = useState(null);
  const user = useRecoilValue(userState);
  const history = useHistory();
  const propertyFilter = useRecoilValue(propertyFilterState);

  // useEffect( () => {
  //   console.log('useEffect--setDatepickerDates')
  //   setDatepickerDates(propertyFilter?.query?.date);
  // }, [propertyFilter] )


  const selectedCheckIn = propertyFilter.query && propertyFilter.query.date && propertyFilter.query.date[0] || null;
  const selectedCheckOut = propertyFilter.query && propertyFilter.query.date && propertyFilter.query.date[1] || null;

  const { property, propertyConnects } = props;
  const currentUserConnects = user ? propertyConnects.filter( item => item.userId == user.uid ) : [];

  const currentUserBooked = currentUserConnects && currentUserConnects.length > 0 ? true : false;

  const filteredConnects = propertyConnects && user && propertyConnects.filter( item => item.userId !== user.uid ) || [];
  const uniqueFilteredConnects = _.uniqBy( filteredConnects, 'userId' );

  let uniqueConnectsFilteredByDate = [];
  if ( selectedCheckIn && selectedCheckOut ) {
    uniqueConnectsFilteredByDate = filteredConnects.filter( item => {
      const connectCheckIn = moment(item.checkIn);
      const connectCheckOut = moment(item.checkOut);
      const isCheckInBetweenDatesSelected = selectedCheckIn.isBetween(connectCheckIn, connectCheckOut);
      const isCheckOutBetweenDatesSelected = selectedCheckOut.isBetween(connectCheckIn, connectCheckOut);
      const isCheckInBetweenDatesConnect = connectCheckIn.isBetween(selectedCheckIn, selectedCheckOut);
      const isCheckOutBetweenDatesConnect = connectCheckOut.isBetween(selectedCheckIn, selectedCheckOut);
      if ( isCheckInBetweenDatesSelected || isCheckOutBetweenDatesSelected || isCheckInBetweenDatesConnect || isCheckOutBetweenDatesConnect ) { return true; }
    });
  }
  uniqueConnectsFilteredByDate = _.uniqBy( uniqueConnectsFilteredByDate, 'userId' );

  const connectsToDisplay = selectedCheckIn ? uniqueConnectsFilteredByDate : uniqueFilteredConnects;

  const hideGuestsDrawer = () => { setVisibleGuestsDrawer(false); }
  const showGuestsDrawer = () => {
    if ( currentUserConnects.length > 1 ) {
      history.push({
        pathname: '/account/connects',
        state: { propertyId: property.id }
      });
    } else {
      setVisibleGuestsDrawer(true);
    }
  }

  const showDrawer = () => {
    // check if the user is NOT logged in?
    if ( ! user ) {
      message.info("You're not logged in, Please login or create an account first!");
      history.push({
        pathname: '/auth/login',
        state: { redirect: `/properties/${property.id}`, viewRooms: true }
      });
      return;
    }

    Api.analytics.logEvent('view_rooms', { user: (Api.uid || null) });

    // show the drawer
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const redirectToLoginPage = () => {
    history.push({
      pathname: '/auth/login',
      state: { redirect: `/properties/${property.id}`, viewRooms: true }
    });
  }


  const propertyPrice = checkIfWeekend(selectedCheckIn, selectedCheckOut) ? property.weekendPrice : property.price;


  return (

    <ViewRooms>
      <ViewRoomsInner>
        { user &&
        <div style={{ alignSelf: 'center', cursor: 'pointer', display: 'flex' }}>
          { connectsToDisplay && connectsToDisplay.length === 0 &&
            <div style={{ alignSelf: 'center', cursor: 'pointer', display: 'flex' }}>
              No other bookings yet for this property
            </div>
          }
          { connectsToDisplay && connectsToDisplay.length <= 3 &&
            <Avatar.Group maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
              { connectsToDisplay && connectsToDisplay.map( connectUser => {
                if ( connectUser.avatar ) {
                  return (<Avatar key={connectUser.userId} onClick={showGuestsDrawer} src={<img src={`/avatars/${connectUser.avatar}.png`} />} />)
                }
                return (<Avatar key={connectUser.userId} onClick={showGuestsDrawer} src={`https://ui-avatars.com/api/?name=${connectUser.firstName}+${connectUser.lastName}`} />)
              }) }
            </Avatar.Group>
          }
          { connectsToDisplay && connectsToDisplay.length > 3 &&
            <Avatar.Group maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
              { connectsToDisplay && connectsToDisplay.slice(0, 3).map( connectUser => {
                if ( connectUser.avatar ) {
                  return (<Avatar key={connectUser.userId} onClick={showGuestsDrawer} src={<img src={`/avatars/${connectUser.avatar}.png`} />} />)
                }
                return (
                  <Avatar key={connectUser.userId} onClick={showGuestsDrawer} src={`https://ui-avatars.com/api/?name=${connectUser.firstName}+${connectUser.lastName}`} />
                )
              } ) }
              <Avatar onClick={showGuestsDrawer} style={{ color: '#fff', backgroundColor: '#da9d18' }}>+{ connectsToDisplay.length - 3 }</Avatar>
            </Avatar.Group>
          }
        </div>
        }

        { ! user &&
          <BottomView  onClick={ redirectToLoginPage }>
            Please login to view guests
          </BottomView>
        }

        <BottomView >
          <BookingDatePicker dates={propertyFilter?.query?.date} />
        </BottomView>

        <div className="price-btn-details">
          <span>{formatter.format(propertyPrice)} / day</span>
          <Btn onClick={showDrawer} disabled={ property.rooms && property.rooms.length > 0 ? false : true }>View Rooms</Btn>
        </div>
      </ViewRoomsInner>

      <Drawer
        closable={ true }
        destroyOnClose={ true }
        maskClosable={ false }
        // width={750}
        className="property-details-drawer"
        title="Room Availability"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <RoomSelection property={property} />
      </Drawer>

      <Drawer
        closable={ true }
        destroyOnClose={ true }
        maskClosable={ false }
        width={500}
        title="Guests"
        className="property-guests-drawer"
        placement="right"
        onClose={ hideGuestsDrawer }
        visible={ visibleGuestsDrawer }
      >
        <PropertyGuests connects={connectsToDisplay} booked={currentUserBooked} />
      </Drawer>


    </ViewRooms>
  )
}


const ViewRooms = styled.div`
  border-top: 1px solid #ccc;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f7f8fa;
`;

const ViewRoomsInner = styled.div`
  max-width: 900px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  padding: 10px;

  .anticon {
    font-size: 20px;
    margin-right: 5px;
  }

  button {
    margin-left: 5px;
    @media only screen and (max-width : 1024px) {
      margin-left: 15px;
    }
  }

  @media only screen and (max-width : 1024px) {
    display: flex;
    flex-direction: column;
    margin: 5px;
    .price-btn-details {
      display: flex;
      align-items: center;
      margin-top: 10px;
      p { flex: 1; }
      button { flex: 1; }
    }
  }
}

`;

const BottomView = styled.div`
  align-self: center;
  cursor: pointer;

  @media only screen and (max-width : 1024px) {
    align-self: start;
    margin-top: 15px;
    width: 100%;
  }
`;




const Btn = styled.button`
  background: #F2AF1F;
  color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  border: 2px solid #d8980e;
  border-radius: 5px;

  &:disabled {
    background: #999;
    color: #fff;
    border: 2px solid #666;
  }
  @media only screen and (max-width : 1024px) {
    align-self: start;
    // margin-top: 15px;
    // padding: 10px 73px;
    width: 100%;

  }

`;




const PropertyDetailsTab = (props) => {
  const { property } = props;

  return (
    <TabContentContainer >
      <Title>{property.name}</Title>
      <PropertyRating disabled allowHalf defaultValue={ property.rating } />

      <Item>
        <EnvironmentOutlined />
        <div className="text">
          <p>{property.address}</p>
          {/*<Tag style={{ marginTop: 5, fontSize: 14 }} color="#108ee9">~ 12 KM away from your location.</Tag>*/}
        </div>
      </Item>


      <GoogleMap geo={property.geo} property={property} />


      <Title style={{ marginTop: 30 }}>Description</Title>


      { property.description &&
      <Item>
        <ExpandCollapse text={property.description}>
        <div className="dectext">
          <MarkdownContent>
            <ReactMarkdown source={ property.description || '## Description not specified!' } />
          </MarkdownContent>
        </div>
        </ExpandCollapse>
      </Item>
      }


      { property.facilities_general &&
      <div>
        {/* General Facilities */}
        <Title style={{ marginTop: 30 }}>Amenities</Title>
        <div className="services">

          { property.facilities_general && property.facilities_general.map( (item, index) => {
            const sanitizedItem = removeComma(item)
            return (
              <Item key={index}>
                { iconMapping[sanitizedItem] ? <FontAwesomeIcon icon={['fas', `${iconMapping[sanitizedItem]}` ]} fixedWidth style={{ marginRight: 10, fontSize: 20, color: '#e2ba1b' }} /> : <CheckSquareOutlined /> }
                <div className="servicestext">{ sanitizedItem }</div>
              </Item>
            )
          } ) }

        </div>
      </div>
      }


      { property.facilities_services &&
      <div>
        {/* Service Facilities */}
        <Title style={{ marginTop: 30 }}>Services</Title>
        <div className="services">

          { property.facilities_services && property.facilities_services.map( (item, index) => {
            const sanitizedItem = removeComma(item)
            return (
              <Item key={index}>
                { iconMapping[sanitizedItem] ? <FontAwesomeIcon icon={['fas', `${iconMapping[sanitizedItem]}` ]} fixedWidth style={{ marginRight: 10, fontSize: 20, color: '#e2ba1b' }} /> : <CheckSquareOutlined /> }
                <div className="servicestext">{ sanitizedItem }</div>
              </Item>
            )
          } ) }

        </div>
      </div>
      }

      { property.facilities_free &&
      <div>
        {/* Free Facilities */}
        <Title style={{ marginTop: 30 }}>Facilities</Title>
        <div className="services">

          { property.facilities_free && property.facilities_free.map( (item, index) => {
            const sanitizedItem = removeComma(item)
            return (
              <Item key={index}>
                { iconMapping[sanitizedItem] ? <FontAwesomeIcon icon={['fas', `${iconMapping[sanitizedItem]}` ]} fixedWidth style={{ marginRight: 10, fontSize: 20, color: '#e2ba1b' }} /> : <CheckSquareOutlined /> }
                <div className="servicestext">{ sanitizedItem }</div>
              </Item>
            )
          } ) }

        </div>
      </div>
      }

      { property.facilities_additional &&
      <div>
        {/* Additional Facilities */}
        <Title style={{ marginTop: 30 }}>Additional Services</Title>
        <Item>
        <div className="dectext additional_facilities">
          <MarkdownContent>
            <ReactMarkdown source={ property.facilities_additional } />
          </MarkdownContent>
        </div>
        </Item>
      </div>
      }



      <Title style={{ marginTop: 30 }}>COVID-19 GUIDELINES & HYGIENE POLICIES</Title>
      <Item>
        <ExpandCollapse text={property.covid_guidelines}>
        <div className="dectext">
          <MarkdownContent>
            <ReactMarkdown source={ defaultCovidGuidelines } />
          </MarkdownContent>
        </div>
        </ExpandCollapse>
      </Item>

    </TabContentContainer>
  )
}


const ReviewsTab = (props) => {

  const reviews = props.property.reviews;

  return (
    <TabContentContainer>


      <ReviewsContainer>
        {reviews && reviews.map((review, index) => {
          return (
            <Review key={index}>
              <div className="avatar">
                <Avatar size="large" style={{ color: '#fff', backgroundColor: '#8bc34a' }}>{review.name.charAt(0)}</Avatar>
              </div>
              <div className="review-body">
                <h3>{review.name} <span>{review.date}</span></h3>
                <Rating disabled defaultValue={review.rating} />
                <div className="review-text">{review.review}</div>
              </div>
            </Review>
          )
        })}
      </ReviewsContainer>



    </TabContentContainer>
  )
}


const PropertyRulesTab = (props) => {
  const { property } = props;

  return (
    <TabContentContainer>

      { property.rules &&
      <ResponsiveContainer>
      <MarkdownContent>
        <ReactMarkdown source={ property.rules || '## Description not specified!' } />
      </MarkdownContent>
      </ResponsiveContainer>
      }

    </TabContentContainer>
  )
}


const CancellationPolicyTab = (props) => {
  const { property } = props;

  return (
    <TabContentContainer>

      { property.cancellation &&
      <ResponsiveContainer>
      <MarkdownContent>
        <ReactMarkdown source={ property.cancellation || '## Description not specified!' } />
      </MarkdownContent>
      </ResponsiveContainer>
      }

      { ! property.cancellation &&
      <ResponsiveContainer>
      <MarkdownContent>
        <ReactMarkdown source={`
Free cancellation for 48 hours
After that, cancel before 1:00 PM on Checkin day and get a 25% refund, minus the Process fee.

**Reservation confirmed**

Full refund only if check-in is at least 14 days away

**48 hours later**

50% refund, minus the Process fee, if the check in day is less than 14 days

**3 days before Check in**

25% of refund, minus the process fee.
        `} />
      </MarkdownContent>
      </ResponsiveContainer>
      }

    </TabContentContainer>
  )
}




const PropertyDetails = (props) => {
  const properties = useRecoilValue(propertiesState);
  const propertyFilter = useRecoilValue(propertyFilterState);

  // const propertyId = props.match.params.id;
  const propertyURL = props.match.params.url;
  const propertyId = propertyURL.split('-').pop();

  const [ propertyConnects, setPropertyConnects ] = useState([]);
  const [ loadingConnects, setLoadingConnects ] = useState(false);

  useEffect( () => {
    async function fetchPropertyConnects() {
      setLoadingConnects(true);
      // const snapshot = await Api.fb().firestore().collection('connects').orderBy('createdAt', 'desc').where('propertyId', '==', propertyId).get();
      const snapshot = await Api.fb().firestore().collection('connect').doc(propertyId).collection('users').get();
      if (snapshot.empty) {
        setPropertyConnects([]);
      }
      const records = [];
      snapshot.docs.forEach( doc => {
        records.push({ ...doc.data(), id: doc.id, propertyId: propertyId });
      });
      setPropertyConnects(records);
      setLoadingConnects(false);
    }

    fetchPropertyConnects()
  }, [propertyId] );


  const filteredProperties = propertyFilter.properties;
  const allProperties = [...properties, ...filteredProperties];

  const property = allProperties.find(item => item.id === propertyId);
  if (!property || !property.id) {
    message.error('Property not found!');
    props.history.push('/');
    return null;
  }

  if ( loadingConnects ) {
    return <Loading />
  }

  return (
    <DefaultLayout
      extra={<PropertyCarousel property={property} />}
    >

      <TabsContainer>

        <Tabs type="card">
          <Tabs.TabPane key="property-details" tab={
            <span>
              <FileDoneOutlined />
              Property Details
            </span>
          }>
            <PropertyDetailsTab property={property} />
          </Tabs.TabPane>

          <Tabs.TabPane key="reviews" tab={
            <span>
              <CommentOutlined />
              Reviews
            </span>
          }>
            <ReviewsTab property={property} />
          </Tabs.TabPane>

          <Tabs.TabPane key="property-rules" tab={
            <span>
              <HomeOutlined />
              Property Rules
            </span>
          }>
            <PropertyRulesTab property={property} />
          </Tabs.TabPane>

          <Tabs.TabPane key="cancellation-policy" tab={
            <span>
              <CloseOutlined />
              Cancellation Policy
            </span>
          }>
            <CancellationPolicyTab property={property} />
          </Tabs.TabPane>
        </Tabs>

      </TabsContainer>

      <FooterBanner property={property} propertyConnects={propertyConnects} location={props.location} />

    </DefaultLayout>
  )

}


export default PropertyDetails;


const TabContentContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
`;


const TabsContainer = styled.div`

  .ant-tabs-nav {
    position: absolute;
    margin-top: -54px;
    width: 100%;
  }


  .ant-tabs-nav-wrap {
    // background: #d48f12;
    background: transparent;
  }
  .ant-tabs-nav-list {
    width: 900px;
    margin: 0 auto;

    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
  }

  .ant-tabs-tab {
    flex: 1;
  }

  .ant-tabs-nav { margin-bottom: 0; }
  .ant-tabs-content { margin-bottom: 50px; }


  .services {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    padding: 15px 30px;

    @media only screen and (max-width : 1024px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
    }

  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: 15px 16px;
    background: #fff;
    // border: 1px solid #f7f8fa;
    border: 1px solid transparent;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: #333;
    border-radius: 15px 15px 0 0;
    font-size: 16px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: #1890ff;
    background: #f2af20;
    span { color: #fff; }
    /* background-color: #e6f7ff !important; */
  }



  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .bmfJmH .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    margin-left: -12px;
    border: 1px solid #CCC;
    justify-content: center;
    &:first-child { margin-left: 0; }
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .fUaVQx .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    z-index: 20;
  }

  @media only screen and (max-width : 1024px) {
    padding-bottom: 100px;

    // .ant-tabs-nav-list {
    //   display: flex !important;
    //   flex-direction: column;
    //   border-radius: 0;
    // }
    // .ant-tabs-tab {
    //   border-radius: 0;
    // }

  }

`;



const Title = styled.h3`
  font-size: 22px;
  font-weight: 600;
  margin-left: 30px;
`;


const PropertyRating = styled(Rate)`
  margin-top: 2px;
  margin-left: 30px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #f2af1f;
  display: none;

  @media only screen and (max-width : 768px) {
    display: block;
  }
`;

const Item = styled.div`
  margin-top: 8px;
  display: flex;


  @media only screen and (max-width : 1024px) {
    padding-left: 30px;
    padding-right: 0;

  }

  .anticon {
    margin-right: 10px;
    font-size: 20px;
    color: #e2ba1b;

  }
  .text {
    max-width: 400px;
    font-size: 16px;
    line-height: 100%;
    align-self: center;
  }
  .dectext{
    // max-width: 700px;
    padding-left: 30px;
    color: #6f6969;

    @media only screen and (max-width : 1024px) {
      padding-left: 0px;

     }
  }
  .servicestext{
    color: #5a5353;
  }

`;




const ReviewsContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;

  @media only screen and (max-width : 1024px) {
    padding-left: 20px;
    padding-right: 20px;

   }

`;


const Review = styled.div`
  border: 1px solid #ccc;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 10px;

  display: flex;
  justify-content: flex-start;

  .avatar {
    margin-right: 20px;
  }

  .review-body {
    h3 {
      font-size: 18px;
      font-weight: 600;
      span { color: #999; font-size: 14px; }
    }
    .review-text {
      margin-top: 5px;
    }

  }



`;

const Rating = styled(Rate)`
  margin-top: 2px;
  font-size: 16px;
  color: #f2af1f;
`;















const ResponsiveContainer = styled.div`
@media only screen and (max-width : 1024px) {
    padding-left: 20px;
    padding-right: 20px;

  }
`;



const MarkdownContent = styled.div`
  // max-width: 600px;
  padding-right: 30px;
  // margin: 0 auto;

  p {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
  }
  em { font-style: italic; }
  strong { font-weight: 600; }

  h2 {
    font-size: 24px;
    font-weight: 600;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
  }

  ul {
    margin: 25px 0;
    list-style: disc;
    li {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 10px;
      margin-left: 25px;
      color: #666;
    }
  }


`;
