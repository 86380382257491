

const interests = [
  "Art",
  "Badminton",
  "Baseball",
  "Basketball",
  "Biking",
  "Blogging",
  "cooking",
  "Cricket ",
  "Dancing",
  "Fashion ",
  "Fitness",
  "Foodie ",
  "Football",
  "Gaming",
  "Hair Dresser",
  "Hiking",
  "Instruments",
  "Learning Languages",
  "Listening Music",
  "Mountaineering",
  "Party",
  "pets",
  "Photography",
  "Podcasting",
  "Politics",
  "Reading",
  "Rugby",
  "Sit-coms",
  "Tennis",
  "Travelling",
  "Vlogger",
  "Writing",
  "Yoga",
];


export default interests;
