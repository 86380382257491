import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userState, propertiesState, bookingsState, channelsState, messagesState, connectsState } from 'global/states';
import { message } from 'antd';
import Api from 'global/api';
import _ from 'lodash';
import moment from 'moment';



const RealtimeCommunication = (props) => {
  const user = useRecoilValue(userState);
  const properties = useRecoilValue(propertiesState);

  const [ bookings, setBookings ] = useRecoilState(bookingsState);
  const [ channels, setChannels ] = useRecoilState(channelsState);
  const [ messages, setMessages ] = useRecoilState(messagesState);
  const [ connects, setConnects ] = useRecoilState(connectsState);


  function getAllBookings() {
    if ( ! user ) { return; }

    const collRef = Api.fb().firestore().collection('bookings').orderBy('createdAt', 'desc').where('userId', '==', user.uid);
    // get all the bookings - We need all booking for displaying connects for each property.
    // const collRef = Api.fb().firestore().collection('bookings').orderBy('createdAt', 'desc'); // NOT NEEDED HERE
    collRef.onSnapshot( (querySnapshot) => {
      const _bookings = [];
      querySnapshot.forEach( (doc) => {
        _bookings.push({ ...doc.data(), id: doc.id });
      });
      setBookings(_bookings);
    });
  }


  const updateChannels = (records) => {
    setChannels( (prev) => {
      const uniqueData = _.uniqBy( [ ...records, ...prev ], 'id' );
      return uniqueData;
    });
  }


  const updateMessages = (records) => {
    setMessages( (prev) => {
      const uniqueData = _.uniqBy( [ ...records, ...prev ], 'id' );
      return uniqueData;
    });
  }

  function getChannels() {
    if ( ! user ) { return; }

    // get all channels created by the loggedin user
    const channelRef = Api.fb().firestore().collection('channels').where('from', '==', user.uid);
    channelRef.onSnapshot( (querySnapshot) => {
      const _channels = [];
      querySnapshot.forEach( (doc) => {
        if (doc) {
          _channels.push({ ...doc.data(), id: doc.id, filter: 'from' });
        }
      });
      updateChannels(_channels);
    });

    // OR query - get all the channels associated with loggedin user
    const toUserRef = Api.fb().firestore().collection('channels').where('to', '==', user.uid);
    toUserRef.onSnapshot( (querySnapshot) => {
      const _channels = [];
      querySnapshot.forEach( (doc) => {
        if ( doc ) {
          _channels.push({ ...doc.data(), id: doc.id, filter: 'to' });
        }
      });
      updateChannels(_channels);
    });
  }


  function getAllMessages() {
    if ( ! channels ) { return; }

    channels && channels.map( channel => {
      const messagesRef = Api.fb().firestore().collection('channels').doc(channel.id).collection('messages').orderBy('timestamp', 'asc');
      messagesRef.onSnapshot( (querySnapshot) => {
        const _messages = [];
        querySnapshot.forEach( (doc) => {
          if (doc) {
            _messages.push({ ...doc.data(), id: doc.id, channelId: channel.id });
          }
        });
        updateMessages(_messages);
      });

    });
  }

  const getAllConnects = () => {
    const userBookedProperties = [];
          bookings.map( item => {
            userBookedProperties.push({
              propertyId: item.propertyId,
              bookingId: item.id,
            })
          });

    if ( userBookedProperties && userBookedProperties.length > 0 ) {
      userBookedProperties.map( property => {
        const booking = _.find(bookings, { propertyId: property.propertyId, id: property.bookingId, status: 'successful' });
        if ( ! booking ) { return; }

        const collRef = Api.fb().firestore().collection('connect').doc(property.propertyId).collection('users').limit(100);
        collRef.onSnapshot( (querySnapshot) => {
          const _connects = [];
          querySnapshot.forEach( (doc) => {
            const data = doc.data();

            const bookingCheckIn = moment(booking.dates.checkIn);
            const bookingCheckOut = moment(booking.dates.checkOut);

            const currentCheckIn = moment(data.checkIn);
            const currentCheckOut = moment(data.checkOut);
            const isCheckInBetweenDatesSelected = bookingCheckIn.isBetween(currentCheckIn, currentCheckOut);
            const isCheckOutBetweenDatesSelected = bookingCheckOut.isBetween(currentCheckIn, currentCheckOut);
            const isCheckInBetweenDatesConnect = currentCheckIn.isBetween(bookingCheckIn, bookingCheckOut);
            const isCheckOutBetweenDatesConnect = currentCheckOut.isBetween(bookingCheckIn, bookingCheckOut);
            if ( isCheckInBetweenDatesSelected || isCheckOutBetweenDatesSelected || isCheckInBetweenDatesConnect || isCheckOutBetweenDatesConnect ) {
              // add all the logic here.
              if ( data.userId === user.uid ) { return; }
              _connects.push({
                ...data,
                id: doc.id,
              });
            }

          });
          setConnects( (prev) => {
            const uniqueData = _.uniqWith([ ..._connects, ...prev ], _.isEqual);
            return uniqueData;
          });
        });
      });
    }
  }



  useEffect( () => {
    getAllBookings();
    getChannels();
  }, [user] );

  useEffect( () => {
    getAllMessages();
  }, [channels] );

  useEffect( () => {
    getAllConnects();
  }, [bookings]);


  // console.log("RealtimeCommunication::user", user);
  // console.log("RealtimeCommunication::properties", properties);
  // console.log("RealtimeCommunication::bookings", bookings);
  // console.log("RealtimeCommunication::channels", channels);
  // console.log("RealtimeCommunication::messages", messages);
  // console.log("RealtimeCommunication::connects", connects);

  return (
    <div>
      { props.children }
    </div>
  );
}

export default RealtimeCommunication;


/*

  // works - but doesn't correct find unique based on proeprty id and user id
  // const data = [ ..._connects, ...prev ];
  // const uniqueData = _.uniqWith(data, (item1, item2) => {
  //   return item1.userId === item2.userId && item1.propertyId === item2.propertyId && item1.bookingId === item2.bookingId
  // });
  // return uniqueData;


*/
