import React, { useState } from 'react';
import styled from 'styled-components';
import AuthLayout from 'global/layouts/Auth';
import { Form, Input, Button, message, Spin } from 'antd';
import { Link } from 'react-router-dom';
import Api from 'global/api';



const LoginPage = (props) => {
  const [ loading, setLoading ] = useState(false);

  const handleSubmit = async (data) => {
    setLoading(true);
    const res = await Api.login(data.email, data.password);
    if ( ! res ) {
      setLoading(false);
    } else {
      if ( props.location.state && props.location.state.redirect ) {
        props.history.push({
          pathname: props.location.state.redirect,
          state: props.location.state,
        });
      } else {
        props.history.push('/');
      }
    }
  }

  return (
    <AuthLayout>
      <HeadingContainer>
        <h1>Welcome to Voyobee!</h1>
        <h2>Login to Continue</h2>
      </HeadingContainer>

      <FormContainer>
        <Spin tip="Logging you in..." spinning={ loading }>
        <Form
          layout="vertical"
          name="login"
          initialValues={{ remember: true }}
          onFinish={ handleSubmit }
          // onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email Address"
            name="email"
            rules={[{ required: true, message: 'Please enter your email address!' }]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter your password here!' }]}
          >
            <Input.Password placeholder="Password"/>
          </Form.Item>

          <Form.Item style={{ marginBottom: 0, textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" className="btn-orange" style={{ width: '50%' }}>Login</Button>
          </Form.Item>
        </Form>
        </Spin>

        <Link to="/auth/forgot-password"><Button type="link" block style={{ marginTop: 10 }}>Forgot Password?</Button></Link>

        <div className="register-content">
          Don't have an account? <br /> <Link to="/auth/register">Create new account today!</Link>
        </div>

      </FormContainer>

      <Footer>
        By continuing, you agree to our <Link to="/company/terms-and-conditions">Terms and Conditions</Link> and <Link to="/company/privacy-policy">Privacy Policy</Link>.
      </Footer>

    </AuthLayout>
  )
}


export default LoginPage;

const HeadingContainer = styled.div`
  h1, h2 {
    font-size: 28px;
    font-weight: 600;
    color: #000;
    text-align: center;
  }

  h2 {
    font-size: 22px;
    margin-top: 10px;
  }

`;

const FormContainer = styled.div`
  max-width: 360px;
  margin: 0 auto;
  margin-top: 50px;



  .register-content {
    margin-top: 30px;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
  }

  @media (max-width: 480px) {
    .register-content {
      margin-top: 30px;
     margin-bottom: 60px
    }
  }



`;

const Footer = styled.div`
  max-width: 360px;
  margin: 0 auto;
  margin-top: 50px;

  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  padding: 0 10px;

  font-size: 14px;
  color: #999;
`;
