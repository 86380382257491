import React from 'react';
import DefaultLayout from 'global/layouts/Default';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const DataContainer = (props) => {
  return (
    <DataContainerWrapper>
      { props.title && <h1>{ props.title }</h1> }
      { props.text && <p>{ props.text }</p> }
      { props.extra && <div>{ props.extra }</div> }
    </DataContainerWrapper>
  )
}


const PageNotFound = (props) => {

  return (
    <DefaultLayout>

      <div style={{ overflow: 'hidden' }}>
        <DataContainer
          title="Page Not Found!"
          text="The page you're looking for doesn't exist or you don't have permissions to access it."
          extra={ <Link to="/"><Button type="primary">&larr; Back to Home</Button></Link> }
        />
      </div>

    </DefaultLayout>
  )

}


export default PageNotFound;



const DataContainerWrapper = styled.div`
  width: 500px;
  padding: 40px 60px;
  margin: 0 auto;
  border-left: 5px solid #EEE;
  border-right: 5px solid #EEE;
  transition: border-color .5s ease-in;
  margin-top: 150px;

  h1 {
    font-size: 32px;
    line-height: 110%;
    font-weight: 700;
    color: #000;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
  }

`;

