import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Header from 'global/components/Header';
import Sidebar from 'global/components/Sidebar';

import { useRecoilValue } from 'recoil';
import { userState } from 'global/states';
import Footer from 'global/components/Footer';


// import { useRecoilState } from 'recoil';
// import { sidebarVisible } from 'global/states';


const SidebarLayout = (props) => {
  let sidebar = false;
  // const user = useRecoilValue(userState);
  // if ( user && user.id ) {
  //   sidebar = true;
  // }

  return (
    <div>

      <Header sidebar={sidebar} />


      { props.extra && <div>{ props.extra }</div> }

      <ContentContainer className={ sidebar ? 'has-sidebar' : '' }>
        <ContentContainerInner>{ props.children }</ContentContainerInner>
      </ContentContainer>

      <Footer sidebar={sidebar} />
    </div>
  )
}


export default SidebarLayout;


const ContentContainer = styled.div`
  padding-top: 90px;
  min-height: calc(100vh - 246px);

  &.has-sidebar {
    margin-left: 260px;
    width: calc( 100% - 260px );
  }
`;

const ContentContainerInner = styled.div`
  max-width: 1300px;
  padding: 50px;
  margin: 0 auto;
  @media (max-width: 480px) {
    padding: 20px;
  }

`;


/*
      <Sidebar visible={sidebar} />


*/
