import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography, Image, InputNumber, Tabs, Row, Col, Button, Divider, Rate, DatePicker, Alert, Form, Spin, Input, Select } from 'antd';
import { HeartOutlined, PlusCircleFilled, MinusCircleFilled, CheckCircleOutlined, TeamOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import BookingSummary from './BookingSummary'
import Api from 'global/api';
import { propertyFilterState, userState } from 'global/states';
import { useRecoilState } from 'recoil';
import { differenceInDays, eachWeekendOfInterval, isWeekend } from 'date-fns'
import SuspenseLoading from 'global/components/Loading/SuspenseLoading';
import moment from 'moment';


import countries from 'global/data/countries';
import interests from 'global/data/interests';
import languages from 'global/data/languages';

import { avatars } from 'global/data/avatar';

const { Title, Text } = Typography;


const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 2,
});


const RoomSelection = ( props ) => {
  const { property } = props;

  const [ summaryVisible, setSummaryVisible ] = useState(false);
  const [ step, setStep ] = useState(1);
  const [ propertyFilter, setPropertyFilter ] = useRecoilState(propertyFilterState);
  const [ user, setUser ] = useRecoilState(userState);


  const [ dates, setDates ] = useState({
    checkIn: (propertyFilter.query.date && propertyFilter.query.date[0].toString() || null),
    checkOut: (propertyFilter.query.date && propertyFilter.query.date[1].toString() || null),
  });
  const [ selected, setSelected ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ guestDetails, setGuestDetails ] = useState({});
  const [ bookingId, setBookingId ] = useState(null);
  const [ razorpayOrder, setRazorpayOrder ] = useState({});


  if ( ! user ) {
    return <SuspenseLoading message="Loading user details..." />
  }

  let guestInitialValues = { language: ['english'], currency: 'USD' };
  if ( user ) {
    // guestInitialValues = { ...user, dob: moment(user.dob), name: `${user.firstName} ${user.lastName}` }
    guestInitialValues = { ...user, dob: moment(user.dob) }
  }


  const checkIfWeekend = () => {
    const totalDays = differenceInDays(new Date(dates.checkOut), new Date(dates.checkIn));
    if ( totalDays >= 7 ) { return false; }
    const isWknd = isWeekend( new Date(dates.checkIn) );
    return isWknd;
    // const weekendDates = eachWeekendOfInterval({
    //   start: new Date(dates.checkIn),
    //   end: new Date(dates.checkOut)
    // });
    // if ( weekendDates.length > 0 ) { return true; }
    // return false;
  }



  console.log("propertyFilter",propertyFilter);


  const handleBookButtonClick = () => {
    // setSummaryVisible(true);
    setStep(2);
  }

  const handleDateSelection = ( data ) => {
    if ( data && Array.isArray(data) ) {
      const [ checkIn, checkOut ] = data;
      const checkInDate = checkIn.toDate();
      const checkOutDate = checkOut.toDate();
      setDates({
        checkIn: checkInDate.toString(),
        checkOut: checkOutDate.toString(),
      });
      let filter = {...propertyFilter};
      if ( ! filter['query'] ) { filter['query'] = {}; }
      filter['query'] = { ...filter['query'], date: data }
      // filter['query']['date'] = data;
      setPropertyFilter(filter);
    }
  }

  const handleChange = (value, item) => {
    let selectedRooms = { ...selected };
    if ( value === 0 ) {
      if ( selectedRooms[item.id] ) {
        delete selectedRooms[item.id];
        setSelected(selectedRooms);
      }
    } else {
      selectedRooms[item.id] = value;
      setSelected(selectedRooms);
    }
  }
  console.log("selectedselectedselected",selected);


  const handleGuestDetailsSubmit = async (values) => {
    let data = { ...values }
    data.dob = data.dob.toString();

    setGuestDetails(data);
    setLoading(true);

    Api.analytics.logEvent('guest_details', { user: (Api.uid || null), data: JSON.stringify(data), rooms: JSON.stringify(selected), dates: JSON.stringify(dates) });

    const res = await Api.addBooking({ guest: data, dates: dates, selected: selected, rooms: property.rooms, property: property });
    if ( ! res ) {
      setLoading(false);
    } else {
      console.log("success!!!!", res);
      setBookingId(res.id);
      setUser({ ...user, ...data }); // update the user profile
      // setLoading(false);
      // setStep(3);

      let totalCost = 0;
      Object.keys(selected).map( key => {
        const room = property.rooms.find( item => item.id == key );
        const beds = selected[key];
        totalCost = totalCost + parseInt(selected[key]) * parseInt(checkIfWeekend() ? room.prices.weekend_final : room.prices.weekday_final);
      });
      console.log("totalCost",totalCost);
      const totalNights = differenceInDays(new Date(dates.checkOut), new Date(dates.checkIn));
      console.log("totalNights",totalNights);
      totalCost = parseInt(totalNights) * totalCost;

      try {
        // const razorResponse = await fetch(`https://hook.io/dhruv-dhruvkumarjha-com/voyobee-razorpay/order`, {
        // const razorResponse = await fetch(`http://localhost:5001/voyobee-app/us-central1/createOrder`, {
        // const razorResponse = await fetch(`http://localhost:5001/voyobee-app/asia-south1/createOrder`, {
        const razorResponse = await fetch(`https://asia-south1-voyobee-app.cloudfunctions.net/createOrder`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            amount: parseInt(totalCost) * 100,
            order_id: res.id,
            property_id: property.id,
          }),
        });

        console.log("razorResponserazorResponserazorResponserazorResponse",razorResponse, res.id);
        const razorpayResponse = await razorResponse.json();
        console.log("razorpayResponse",razorpayResponse);
        console.log("razorpayResponse.order",razorpayResponse.order);
        setRazorpayOrder(razorpayResponse.order);

        setLoading(false);
        if ( razorpayResponse.success ) {
          setStep(3);
        }

      } catch (e) {
        console.log("error catch", e);
        setLoading(false);
        // setStep(3);
      }

    }

  }


  const avatarSelector = (data) => {

    const handleClick = (val) => {
      data.props.onSelect(val);
      data.props.onToggleOpen();
    }

    const avatarSet = data.props.values;
    const count = avatarSet.size;
    let current = null;
    if ( count > 0 ) {
      current = avatarSet.values().next().value;
    }

    return (
      <AvatarContainer>
        { avatars && avatars.map( (img, index) => {
          return (
            <AvatarImage key={img} className={ current == img ? 'selected' : '' }>
              <img src={`/avatars/${img}.png`} onClick={ () => { handleClick(img) } } />
            </AvatarImage>
          )
        }) }
      </AvatarContainer>
    )
  }


  return (
    <Container>

      { step === 1 &&
      <div>

        <Title level={4}>Please select check-in and check-out date.</Title>
        <DatePicker.RangePicker format="DD/MM/YYYY" disabledDate={ (curr) => curr && curr < moment().subtract(1,'days').endOf('day') } separator={<ArrowRightOutlined />} placeholder={['Check-In', 'Check-Out']} defaultValue={propertyFilter.query.date} allowClear={false} autoFocus size="large" style={{ width: '100%' }} onChange={handleDateSelection} />
        { (! dates.checkIn && ! dates.checkOut) &&
          <Alert message="Please select check-in and check-out date(s) first" type="info" style={{ marginTop: 10 }} />
        }

        <div style={ (dates.checkIn && dates.checkOut) ? {} : { pointerEvents: 'none', opacity: 0.2 } }>
          <RoomContainer>
            <Title level={4} style={{ marginTop: 30 }}>Room Availability</Title>
            { property.rooms && property.rooms.map( item => {

              // const weekendDates = eachWeekendOfInterval({
              //   start: new Date(dates.checkIn),
              //   end: new Date(dates.checkOut)
              // });
              // console.log("weekendDates",weekendDates)
              // console.log("selected",selected)
              // price_weekend

              if ( ! item.title ) { return null; }


              const amount = parseInt(selected[item.id]) * parseInt( checkIfWeekend() ? item.prices.weekend_final : item.prices.weekday_final ) || 0;
              console.log("checkIfWeekendcheckIfWeekend",checkIfWeekend() )

              return (
                <SingleRoomDetails key={item.id}>
                  <Title level={4}>{item.title}</Title>
                  <Room>
                    <div>
                      <Image className='img-width' style={{ borderRadius: 5 }} src={item.image} />
                    </div>
                    <div>
                      { item.description }
                    </div>
                    <Details>
                      <Text  className="drawer-details-text">{ item.type == "Bedroom" ? "No. of rooms" : "No. of beds" }</Text>
                      <InputNumber min={0} max={ parseInt(item.quantity || 0) } defaultValue={0} onChange={ (data) => { handleChange(data, item) } } style={{ marginLeft: 30, maxWidth: '100%' }} />
                      <Title level={5}  className="drawer-details-title" >Amount: { formatter.format(amount) }</Title>
                    </Details>
                  </Room>
                </SingleRoomDetails>
              )
            })}
          </RoomContainer>
          <Button disabled={ Object.keys(selected).length > 0 ? false : true } type="primary" className="btn-orange" block style={{ marginTop: 40, height: 40 }} onClick={ handleBookButtonClick }>Continue</Button>
        </div>

      </div>
      }



      { step === 2 &&
      <div>
        <h2 style={{ fontSize: 28 }}>Please enter guests details!</h2>

        <FormContainer>
          <Spin tip="Processing..." spinning={ loading }>
          <Form
            layout="vertical"
            name="register"
            initialValues={{ ...guestInitialValues, currency: 'INR' }}
            onFinish={ handleGuestDetailsSubmit }
          >

            <Row gutter={[16, 16]}>
              <Col xl={12} xs={24}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[{ required: true, message: 'Please enter your first name' }]}
                  style={{ marginBottom: 14 }}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>

              <Col  xl={12} xs={24}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[{ required: true, message: 'Please enter your last name' }]}
                  style={{ marginBottom: 14 }}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
              <Col  xl={12} xs={24}>
            <Form.Item
              label="Date of Birth"
              name="dob"
              rules={[
                { required: true, message: 'Please enter/select your date of birth' },
                ({ getFieldValue }) => ({
                  validator( _, value ) {
                    if ( value ) {
                      const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);
                      const age = getAge( value.toString() );
                      if ( age >= 18 ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('You must be 18+ years old.');
                      }
                    }
                    return Promise.reject();
                  },
                }),
             ]}
            >
              <DatePicker format="DD/MM/YYYY" placeholder="DD/MM/YYYY" allowClear={true} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
            <Col  xl={12} xs={24}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: 'Please enter your gender' }]}
            >
              <Select style={{ width: '100%' }} placeholder="Select your Gender">
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
                <Select.Option value="other">Other</Select.Option>
              </Select>
            </Form.Item>
            </Col>
            </Row>

            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Please enter your country' }]}
            >
              <Select style={{ width: '100%' }} showSearch allowClear placeholder="Country">
                { countries.map( value => <Select.Option key={value} value={value}>{value}</Select.Option> ) }
              </Select>
            </Form.Item>

            <Form.Item
              label="Contact Number"
              name="phone"
              rules={[{ required: true, message: 'Please enter your contact number' }]}
            >
              <Input placeholder="Ex: +91 123 4567 890" />
            </Form.Item>


            <Form.Item
              label="Please select your interests"
              name="interests"
              rules={[{ required: true, message: 'Please select your interests' }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                // defaultValue={[]}
              >
                { interests.map( value => <Select.Option key={value} value={value}>{value}</Select.Option> ) }
              </Select>
            </Form.Item>
            <Form.Item
              label="Spoken Language(s)"
              name="language"
              rules={[{ required: true, message: 'Please select your spoken languages' }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
              >
                { languages.map( value => <Select.Option key={value} value={value}>{value}</Select.Option> ) }
              </Select>
            </Form.Item>

            <Form.Item
              label="Currency"
              name="currency"
              rules={[{ required: true, message: 'Please select your currency' }]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                disabled
              >
                <Select.Option key="USD">USD</Select.Option>
                <Select.Option key="INR">INR</Select.Option>
                <Select.Option key="EUR">EUR</Select.Option>
                <Select.Option key="JPY">JPY</Select.Option>
              </Select>
            </Form.Item>

             <Form.Item
                label="Avatar"
                name="avatar"
                rules={[{ required: true, message: 'Please select your avatar' }]}
              >
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select avatar"
                  dropdownRender={ avatarSelector }
                />
              </Form.Item>

            <Form.Item style={{ marginBottom: 0, textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" className="btn-orange" block style={{ height: 40 }}>Continue</Button>
            </Form.Item>
          </Form>
          </Spin>

        </FormContainer>

      </div>
      }


      { step === 3 &&
      <div>
        <BookingSummary selected={selected} property={property} bookingId={bookingId} dates={dates} order={razorpayOrder} />
      </div>
      }




    </Container>
  )

}



export default RoomSelection;



const Container = styled.div``;

const RoomContainer = styled.div`
  .ant-image-img {
    width: 100%;
    height: 100%;
  }
`;

const SingleRoomDetails = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  margin-top: 10px;

.img-width {
  width: 250px;

  @media only screen and (max-width : 768px) {
    width: 100%;
  }


}



`;

const Room = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin-top: 15px;

  @media only screen and (max-width : 768px) {
    grid-template-columns: repeat(1, 1fr);
  }


`;

const Details = styled.div`


.drawer-details-title{
  margin-top: 70px;
  margin-left: 30px;
}

.drawer-details-text{
  margin-left: 30px;
}

@media only screen and (max-width : 768px) {

.drawer-details-title{
  margin-top: 10px;
  margin-left: 0px;
}
.drawer-details-text{
  margin-left: 0px;
}

}

`;




const RoomDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding: 15px 30px;
  border-width:1px;
  border-style:solid;
  border-color:#dadada;
  margin-top: 20px;

  .beds{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-gap: 30px; */
    padding: 15px 30px;
  }

`;


const DetailsComponent = styled.div`
  display: flex;
  flex-direction: column;

  .anticon svg {
    display: inline-block;
    font-size: 20px;
  }
`;

const FormContainer = styled.div`
  // max-width: 360px;
  margin: 0 auto;
  margin-top: 20px;

  input, .ant-input { font-size: 14px; }
  .ant-form-item-label > label { font-weight: bold; }
`;




const AvatarContainer = styled.div`
  max-width: 100%;
  padding: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: spance-between;
`;

const AvatarImage = styled.div`
  max-width: 48px;
  display: flex;

  img {
    max-width: 100%;
    cursor: pointer;
  }

  &.selected {
    border-radius: 50%;
    border: 3px solid #ff0000;
  }

`;










/*






const RoomSelection = ( props ) => {
  const { property } = props;

  const [ summaryVisible, setSummaryVisible ] = useState(false);
  const [ step, setStep ] = useState(1);
  const [ selected, setSelected ] = useState({});

  const handleBookButtonClick = () => {
    setSummaryVisible(true);
  }

  const handleChange = (value, item) => {
    let selectedRooms = { ...selected };
    selectedRooms[item.id] = value;
    setSelected(selectedRooms)
  }


  return (
    <Container>

      { ! summaryVisible &&
      <RoomContainer>
        <Title level={4} style={{ marginTop: 10 }}>Room Availability</Title>
        { property.rooms && property.rooms.map( item => {
          let amount = parseInt(selected[item.id]) * parseInt(item.price) || 0;

          return (
            <SingleRoomDetails>
              <Title level={4}>{item.title}</Title>
              <Room key={item.id}>
                <div>
                  <Image width={250} style={{ borderRadius: 5 }} src={item.image} />
                </div>
                <div>
                  { item.description }
                </div>
                <Details>
                  <Text style={{ marginLeft: 30 }}>No. Of Beds</Text>
                  <InputNumber min={0} max={10} defaultValue={0} onChange={ (data) => { handleChange(data, item) } } style={{ marginLeft: 30, maxWidth: '100%' }} />
                  <Title level={5} style={{ marginTop: 70, marginLeft: 30 }}>Amount: { formatter.format(amount) }</Title>
                </Details>
              </Room>
            </SingleRoomDetails>
          )
        })}
      </RoomContainer>
      }


      { ! summaryVisible &&
      <Button disabled={ Object.keys(selected).length > 0 ? false : true } type="primary" className="btn-orange" block style={{ marginTop: 40, height: 40 }} onClick={ handleBookButtonClick }>Book Now</Button>
      }


      { summaryVisible &&
        <BookingSummary selected={selected} property={property} />
      }

    </Container>
  )

}











*/
