import React, { useState } from 'react';
import styled from 'styled-components';
import AuthLayout from 'global/layouts/Auth';
import { Form, Input, Button, message, Spin } from 'antd';
import { Link } from 'react-router-dom';
import Api from 'global/api';



const ForgotPassword = (props) => {
  const [ loading, setLoading ] = useState(false);

  const onFinish = (data) => {
    setLoading(true);
    Api.auth.sendPasswordResetEmail(data.email).then( () => {
      setLoading(false);
      message.success(`We have sent you a Password reset email.`);
      props.history.push('/auth/login');
    }).catch( (err) => {
      setLoading(false);
      message.error(`Error Occurred: ${ err.message }`);
    });
  }

  return (
    <AuthLayout>
      <HeadingContainer>
        <h1>Welcome to Voyobee!</h1>
        <h2>Get Password Reset Link</h2>
      </HeadingContainer>

      <FormContainer>
        <Spin tip="Resetting password..." spinning={ loading }>
        <Form
          layout="vertical"
          name="login"
          initialValues={{ remember: true }}
          onFinish={ onFinish }
        >
          <Form.Item
            label="Email Address"
            name="email"
            rules={[{ required: true, message: 'Please enter your email address!' }]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0, textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" className="btn-orange" style={{ width: '50%' }}>Send Email</Button>
          </Form.Item>
        </Form>
        </Spin>

        <div style={{ marginTop: 30, fontSize: 20, lineHeight: '100%', textAlign: 'center' }}>
          <Link to="/auth/login">Back to Login Page</Link>
        </div>

      </FormContainer>

      <Footer>
        By continuing, you agree to our <Link to="/company/terms-and-conditions">Terms and Conditions</Link> and <Link to="/company/privacy-policy">Privacy Policy</Link>.
      </Footer>

    </AuthLayout>
  )
}


export default ForgotPassword;

const HeadingContainer = styled.div`
  h1, h2 {
    font-size: 28px;
    font-weight: 600;
    color: #000;
    text-align: center;
  }

  h2 {
    font-size: 22px;
    margin-top: 10px;
  }

`;

const FormContainer = styled.div`
  max-width: 360px;
  margin: 0 auto;
  margin-top: 50px;
`;

const Footer = styled.div`
  max-width: 360px;
  margin: 0 auto;
  margin-top: 50px;

  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  padding: 0 10px;

  font-size: 14px;
  color: #999;
`;
