import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Header from 'global/components/Header';


const DefaultLayout = (props) => {
  return (
    <div>
      <Header />
      { props.extra && <div>{ props.extra }</div> }

      <ContentContainer>
        { props.children }
      </ContentContainer>
    </div>
  )
}


export default DefaultLayout;


const ContentContainer = styled.div`
  // max-width: 900px;
  margin: 0 auto;
`;
